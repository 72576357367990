import { useState } from "react";
import { useMount } from "react-use";
import WorkerModel from "common/models/WorkerModel";
import workerRepository from "@/repositories/WorkerRepository";

type HookResult = {
  worker: WorkerModel | null;
};
const useWorker = (workerId: string): HookResult => {
  const [worker, setWorker] = useState<WorkerModel | null>(null);

  useMount(async () => {
    const worker = await workerRepository.findById(workerId);
    setWorker(worker);
  });

  return {
    worker,
  };
};

export default useWorker;
