import React from "react";
import Button from "@/components/Button";
import EstimateDrawer from "@/features/estimate/EstimateDrawer";
import useModal from "@/hooks/useModal";
import ProjectModel from "common/models/ProjectModel";
import EstimateTable from "./EstimateTable";
import TotalAmountTable from "./TotalAmountTable";
import { JobTypeTag } from "@/components/JobTypeTag";
import EstimateListModel from "common/models/EstimateListModel";
import { useMount } from "react-use";
import { CheckOutlined } from "@ant-design/icons";
import "./Estimates.scss";

type Props = {
  projectId: string;
  project: ProjectModel;
  estimates: EstimateListModel;
};

const Estimates: React.FC<Props> = ({ projectId, project, estimates }) => {
  const { open, handleOpen, handleClose } = useModal();
  const estimateButtonText = !estimates.isEmpty ? "見積を編集する" : "見積を作成する";

  useMount(() => {
    // 見積が作成されていない場合は見積ダイアログを開く
    if (project.canRecruit && estimates.isEmpty) {
      handleOpen();
    }
  });

  return (
    <section className="Estimates">
      <h2>見積内容</h2>
      <div className="Estimates__overviewContainer">
        <JobTypeTag jobType={project.jobType?.value || null} />
        {project.hasRequestEstimateProposal && (
          <div className="Estimates__overviewContainer__requestEstimateProposal">
            <CheckOutlined />
            <div className="Estimates__overviewContainer__requestEstimateProposal__text">
              職人からの金額提案を希望する
            </div>
          </div>
        )}
      </div>
      <EstimateTable estimates={estimates} />
      <div className="Estimates__totalAmountTableContainer">
        <TotalAmountTable estimates={estimates} />
      </div>
      <div style={{ marginTop: "16px" }}>
        <Button
          block
          ghost
          type="primary"
          disabled={!project.canEditEstimates || project.hasApplyingWorker}
          onClick={handleOpen}
        >
          {estimateButtonText}
        </Button>
      </div>
      <EstimateDrawer projectId={projectId} project={project} estimates={estimates} open={open} onClose={handleClose} />
    </section>
  );
};

export default Estimates;
