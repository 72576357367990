import ProjectModel from "common/models/ProjectModel";
import KeyInfoModel from "common/models/KeyInfoModel";
import { FormData } from "@/features/project/ProjectForm";
import { auth } from "@/lib/firebase";
import projectRepository from "@/repositories/ProjectRepository";
import keyInfoRepository from "@/repositories/KeyInfoRepository";
import { writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import ResidentModel from "common/models/ResidentModel";
import residentRepository from "@/repositories/ResidentRepository";

/**案件を更新します */
export async function updateProject(projectId: string, data: FormData): Promise<void> {
  const batch = writeBatch(db);

  const project = new ProjectModel({ ...data.project, ownerId: auth.currentUser?.uid || "" });
  await projectRepository.update({ id: projectId, data: project.toJSON(), writeBatch: batch });

  const keyInfo = new KeyInfoModel({ ...data.keyInfo });
  if (!keyInfo.isAllNull) {
    await keyInfoRepository.upsert({ projectId, data: keyInfo.toJSON(), writeBatch: batch });
  }

  if (project.hasResident) {
    const resident = new ResidentModel({ ...data.resident });
    await residentRepository.upsert({ projectId, data: resident.toJSON(), writeBatch: batch });
  }

  await batch.commit();
}
