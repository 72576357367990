import { DBModel } from "../@types/global";
import Address from "./shared/Address";
import JobTypes from "./shared/JobTypes";
import CustomDateTime from "./shared/CustomDateTime";

/**職人の公開可能な情報 */
export default class PublicWorkerDetailModel {
  public readonly id: string;
  public readonly accountName: string; // アカウント名
  public readonly address: Address; // 住所
  public readonly businessType: DBModel.WorkerPublicDetail["businessType"]; // 個人か企業かの区分
  public readonly companyName: string | null; // 会社名
  public readonly firstName: string; // 名
  public readonly invoiceNumber: string | null; // インボイス番号
  public readonly jobTypes: JobTypes; // 職種
  public readonly lastName: string; // 姓
  public readonly oldPROWorkCount: number; // 旧リモデラPROでの施工件数
  public readonly profileImageStoragePath: string | null; // プロフィール画像のstorageパス
  public readonly rating: number; // 評価

  public readonly createdAt: CustomDateTime; // 作成日時

  constructor(data: Partial<DBModel.WorkerPublicDetail>) {
    const defaultValues = {
      id: "",
      accountName: "",
      address: {
        postalCode: "",
        prefecture: "",
        city: "",
        streetNumber: "",
        extraAddress: "",
      },
      businessType: "",
      companyName: null,
      firstName: "",
      invoiceNumber: null,
      jobTypes: [],
      lastName: "",
      oldPROWorkCount: 0,
      phoneNumber: "",
      profileImageStoragePath: null,
      rating: 0,

      createdAt: null,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.workerId || mergedData.id;
    this.accountName = mergedData.accountName;
    this.address = new Address(mergedData.address);
    this.businessType = mergedData.businessType;
    this.companyName = mergedData.companyName;
    this.firstName = mergedData.firstName;
    this.invoiceNumber = mergedData.invoiceNumber;
    this.jobTypes = new JobTypes(mergedData.jobTypes);
    this.lastName = mergedData.lastName;
    this.oldPROWorkCount = mergedData.oldPROWorkCount || 0;
    this.profileImageStoragePath = mergedData.profileImageStoragePath;
    this.rating = mergedData.rating;

    this.createdAt = new CustomDateTime(mergedData.createdAt);
  }

  /**表示名（会社アカウントなら会社名、個人アカウントなら個人名（屋号があれば屋号）） */
  get displayName(): string {
    if (this.businessType === "company") {
      return this.companyName || "";
    }
    if (this.businessType === "person") {
      return this.companyName ? this.companyName : this.fullName;
    }
    return "";
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }

  get businessTypeLabel(): string {
    return this.businessType === "person" ? "個人" : "法人";
  }

  get invoiceLabel(): string {
    return this.invoiceNumber ? "適格請求書発行事業者" : "インボイス未登録者";
  }

  get ratingFormatted(): string {
    return this.rating.toFixed(1);
  }
}
