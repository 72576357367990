import workerRepository from "@/repositories/WorkerRepository";
import WorkerIdentityVerifyStatus from "common/models/worker/IdentityVerifyStatus";

/**本人確認書類の再送信を依頼する */
export async function requestResend(workerId: string): Promise<void> {
  const worker = {
    identityVerifyStatus: WorkerIdentityVerifyStatus.Resend.value,
  };
  await workerRepository.update({ id: workerId, data: worker });
}
