import { useParams } from "react-router-dom";
import useEstimateList from "@/hooks/useEstimateList";
import ConstructionCollapse from "@/features/inspection/ConstructionCollapse";

export default function ProjectIdConstructionImages(): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  if (!projectId) {
    throw new Error(`projectId is required.`);
  }
  const { estimates } = useEstimateList(projectId);
  if (!estimates) {
    return <></>;
  }

  return (
    <div style={{ padding: "24px" }}>
      <h1>工事写真</h1>
      {!estimates.isEmpty && <ConstructionCollapse projectId={projectId} estimates={estimates} />}
    </div>
  );
}
