export default class CompanyAddress {
  private readonly _value: DBModel.CompanyAddress;

  constructor(value: DBModel.CompanyAddress) {
    this._value = value;
  }

  get value(): DBModel.CompanyAddress {
    return this._value;
  }

  get formatted(): string {
    const { prefecture, city, streetNumberAndBuilding } = this._value;
    return `${prefecture}${city}${streetNumberAndBuilding || ""}`;
  }
}
