import React from "react";
import { Modal, Spin } from "antd";
import Carousel from "./Carousel";
import { useQuery } from "@tanstack/react-query";
import { fetchImageUrls } from "@/features/project/api/fetchImageUrls";
import useModal from "@/hooks/useModal";
import "./Carousel.scss";

type Props = {
  title: string;
  storagePaths: string[];
  modalHook: ReturnType<typeof useModal>;
};

const CarouselModal: React.FC<Props> = ({ title, storagePaths, modalHook }) => {
  const { data: imageUrls } = useQuery({
    queryKey: ["imageUrls", title],
    queryFn: () => fetchImageUrls(storagePaths),
    retry: 0,
    throwOnError: true,
    enabled: storagePaths.length > 0 && !!open, // モーダルが開かれたら取得
    initialData: [],
  });

  return (
    <>
      <Modal centered open={modalHook.open} onCancel={modalHook.handleClose} title={title} width={700} footer={null}>
        {storagePaths.length > 0 && imageUrls.length === 0 ? (
          <Spin spinning={true} tip="Loading...">
            <div style={{ height: "100px" }}></div>
          </Spin>
        ) : (
          <div style={{ margin: "24px auto" }}>
            <Carousel imageUrls={imageUrls} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CarouselModal;
