import { Image } from "antd";
import { Spin } from "antd";
import "./ConstructionImages.scss";

type Props = {
  imageUrls: string[] | null;
  isLoading: boolean;
};
const ConstructionImages = ({ imageUrls, isLoading }: Props): JSX.Element => {
  if (isLoading) {
    return (
      <div className="ConstructionImages">
        <Spin spinning={true}>Loading...</Spin>
      </div>
    );
  }

  return (
    <div className="ConstructionImages">
      {!imageUrls || imageUrls.length === 0 ? (
        <div className="ConstructionImages__noImages">工事写真が登録されていません。</div>
      ) : (
        imageUrls.map((url, index) => (
          <div key={index} className="ConstructionImages__imageContainer">
            <Image src={url} className="ConstructionImages__imagesContainer__image" />
          </div>
        ))
      )}
    </div>
  );
};

export default ConstructionImages;
