import { DBModel } from "../@types/global";

export default class ResidentModel {
  public readonly id: string;
  public readonly name: string; // 入居者の氏名
  public readonly note: string; // 入居者についての備考
  public readonly phoneNumber: string; // 入居者の電話番号

  constructor(data: Partial<DBModel.Resident>) {
    const defaultValues = {
      id: "detail", // 固定
      name: "",
      note: "",
      phoneNumber: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = defaultValues.id;
    this.name = mergedData.name;
    this.note = mergedData.note;
    this.phoneNumber = mergedData.phoneNumber;
  }

  toJSON(): DBModel.Resident {
    return {
      name: this.name,
      note: this.note,
      phoneNumber: this.phoneNumber,
    };
  }
}
