import { useState, useEffect } from "react";
import InvoiceModel from "common/models/InvoiceModel";
import { subscribeInvoices } from "../api/subscribeInvoices";
import { useMount } from "react-use";
import { subMonths, format } from "date-fns";

export function useInvoiceList() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceList, setInvoiceList] = useState<InvoiceModel[]>([]);
  const [filteredInvoiceList, setFilteredInvoiceList] = useState<InvoiceModel[]>([]);

  // 検索条件
  const [selectedStatus, setSelectedStatus] = useState<string>("uninvoiced");
  const [selectedInvoiceTo, setSelectedInvoiceTo] = useState<string>("");

  const lastMonth = subMonths(new Date(), 1);
  const [selectedSalesYearMonth, setSelectedSalesYearMonth] = useState<string>(format(lastMonth, "yyyy-MM"));

  useMount(() => {
    const unsubscribe = subscribeInvoices((invoices: InvoiceModel[]) => {
      setIsLoading(true);
      setInvoiceList(invoices);

      // デフォルトで先月の未請求の請求のみ表示
      const defultFiltered = invoices.filter((invoice) => {
        return invoice.status === selectedStatus && invoice.salesYearMonth === selectedSalesYearMonth;
      });
      setFilteredInvoiceList(defultFiltered);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  });

  useEffect(() => {
    const filtered = invoiceList.filter((invoice) => {
      return (
        (selectedStatus === "" || invoice.status === selectedStatus) &&
        invoice.salesYearMonth === selectedSalesYearMonth &&
        invoice.invoiceTo.includes(selectedInvoiceTo)
      );
    });

    setFilteredInvoiceList(filtered);
  }, [invoiceList, selectedStatus, selectedInvoiceTo, selectedSalesYearMonth]);

  return {
    isLoading,
    filteredInvoiceList,
    selectedStatus,
    setSelectedStatus,
    selectedInvoiceTo,
    setSelectedInvoiceTo,
    selectedSalesYearMonth,
    setSelectedSalesYearMonth,
  };
}
