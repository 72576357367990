import { Card, Table } from "antd";
import WorkerModel from "common/models/WorkerModel";
import { ColumnsType } from "antd/es/table";

type Props = {
  canceledWorkers: WorkerModel[];
};
/**見送られた職人 */
export function ProjectDetailCanceledWorkers({ canceledWorkers }: Props): JSX.Element {
  const columns: ColumnsType<WorkerModel> = [
    {
      title: "表示名",
      dataIndex: "displayName",
      width: 30,
      render: (_, canceledWorker) => <div>{canceledWorker.displayName}</div>,
    },
    {
      title: "氏名",
      dataIndex: "fullName",
      width: 30,
      render: (_, canceledWorker) => <div>{canceledWorker.fullName}</div>,
    },
  ];

  return (
    <Card title="見送られた職人" bordered={false} style={{ marginTop: "1rem" }}>
      {canceledWorkers.length > 0 && (
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={canceledWorkers}
          scroll={{ y: 540 }}
          rowKey={(record) => record.id}
        />
      )}
    </Card>
  );
}
