import { Firestore, doc, collection, getDoc, query, orderBy, getDocs } from "firebase/firestore";
import WorkerModel from "common/models/WorkerModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "workers";

export class WorkerRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef() {
    return collection(this.firestore, collectionName);
  }

  private getDocRef(id: string) {
    const colPath = this.getColRef().path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findById(id: string): Promise<WorkerModel | null> {
    const docRef = this.getDocRef(id);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const worker = { ...snapshot.data(), id: snapshot.id } as DBModel.Worker;
    return new WorkerModel(worker);
  }

  async findAll(): Promise<WorkerModel[]> {
    const colRef = this.getColRef();
    let q = query(colRef);
    q = query(q, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const workers: WorkerModel[] = [];
    querySnapshot.forEach((doc) => {
      const worker = { ...doc.data(), id: doc.id } as DBModel.Worker;
      workers.push(new WorkerModel(worker));
    });
    return workers;
  }

  async update({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.Worker>) {
    if (!id) {
      throw new Error("id is required");
    }
    const docRef = this.getDocRef(id);
    const updateWorker = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateWorker, writeBatch);
  }
}

const workerRepository = new WorkerRepository(db);
export default workerRepository;
