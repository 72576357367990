import React from "react";
import CommentModel from "common/models/CommentModel";
import Button from "@/components/Button";
import CommentDrawer, { FormData } from "@/features/comment/CommentDrawer";
import Comment from "@/features/comment/Comment";
import useModal from "@/hooks/useModal";
import useComments from "@/hooks/useComments";
import { message } from "antd";
import ProjectModel from "common/models/ProjectModel";
import { createComment } from "./api/createComment";
import "./Comments.scss";

type Props = {
  project: ProjectModel;
};

const Comments: React.FC<Props> = ({ project }) => {
  const { open, handleOpen, handleClose } = useModal();
  const { comments, refresh, softDelete } = useComments(project.id);

  const handleSubmit = async (data: FormData) => {
    await createComment({ projectId: project.id, body: data.body });
    refresh();
  };

  const handleDelete = async (comment: CommentModel) => {
    if (!window.confirm("削除しますか？")) {
      return;
    }

    try {
      const deleteComment = CommentModel.ofDelete({ id: comment.id, ...comment.toJSON() });
      await softDelete(deleteComment);
      message.success("削除しました。");
    } catch (error) {
      message.error("削除に失敗しました。");
    }
  };

  return (
    <section className="Comments">
      <h2>コメント ({comments.length})</h2>
      {/* 最後の3件だけ表示 */}
      {comments.slice(-3).map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
      <div className="Comments__do">
        <Button block ghost type="primary" onClick={handleOpen}>
          コメントを確認する
        </Button>
      </div>
      <CommentDrawer
        project={project}
        comments={comments}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </section>
  );
};

export default Comments;
