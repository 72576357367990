import { Table, Input, Popover } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import EstimateModel from "common/models/EstimateModel";
import { Controller, Control } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import EstimateListModel from "common/models/EstimateListModel";
import AmountReferenceContent from "./AmountReferenceContent";
import Button from "@/components/Button";
import { useState } from "react";
import ProjectModel from "common/models/ProjectModel";
import "./EstimateTable.scss";

type Props = {
  estimates: EstimateListModel;
  project?: ProjectModel;
  deleteRow?: (estimate: any) => void;
  control?: Control<any>;
};

const EstimateTable = ({ estimates, project, deleteRow, control }: Props) => {
  const [popoverStatuses, setPopoverStatuses] = useState<{ [key: string]: boolean }>({});
  // 各行のpopoverの開閉状態を切り替える関数
  const togglePopover = (key: string) => {
    setPopoverStatuses((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const itemColumns: ColumnsType<EstimateModel> = [
    {
      title: "項目",
      dataIndex: "itemName",
      className: "EstimateTable__col__itemName",
      render: (_, estimate, index) =>
        control ? (
          <Controller
            name={`estimates[${index}].itemName`}
            control={control}
            render={({ field }) => (
              <Input {...field} type="text" placeholder="洋室 / 壁クロス" defaultValue={estimate.itemName} />
            )}
          />
        ) : (
          <div>{estimate.itemName}</div>
        ),
    },
    {
      title: "数量",
      dataIndex: "quantity",
      className: "EstimateTable__col__quantity",
      render: (_, estimate, index) =>
        control ? (
          <Controller
            name={`estimates[${index}].quantity`}
            control={control}
            render={({ field }) => (
              <NumericFormat
                customInput={Input}
                placeholder="26.1"
                thousandSeparator={true}
                isAllowed={(values) => {
                  if (values.value.includes("-")) {
                    return false;
                  }
                  const value = values.floatValue || 0;
                  return value >= 0;
                }}
                value={field.value}
                onValueChange={(values) => {
                  if (values.value === "") {
                    field.onChange("");
                  } else {
                    field.onChange(values.floatValue || 0);
                  }
                }}
                onBlur={(event) => {
                  // フォーカスが外れた時に値が空文字列だった場合、0をセット
                  if (event.target.value === "") {
                    field.onChange(0);
                  }
                }}
              />
            )}
          />
        ) : (
          <div>{estimate.quantity}</div>
        ),
    },
    {
      title: "単位",
      dataIndex: "unit",
      className: "EstimateTable__col__unit",
      render: (_, estimate, index) =>
        control ? (
          <Controller
            name={`estimates[${index}].unit`}
            control={control}
            render={({ field }) => <Input {...field} type="text" placeholder="m" defaultValue={estimate.unit} />}
          />
        ) : (
          <div>{estimate.unit}</div>
        ),
    },
    {
      title: "単価",
      dataIndex: "unitPrice",
      className: "EstimateTable__col__unitPrice",
      render: (_, estimate, index) => (
        <>
          {control ? (
            <Popover
              content={
                <AmountReferenceContent
                  estimate={estimate}
                  prefecture={project?.address.value.prefecture || ""}
                  close={() => togglePopover(estimate.id)}
                />
              }
              open={popoverStatuses[estimate.id]}
              placement="bottom"
              overlayStyle={{ marginTop: 10 }}
            >
              <Controller
                name={`estimates[${index}].unitPrice`}
                control={control}
                render={({ field }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <NumericFormat
                      customInput={Input}
                      placeholder="1,000"
                      thousandSeparator={true}
                      value={field.value}
                      onValueChange={(values) => {
                        if (values.value === "") {
                          field.onChange(null);
                        } else {
                          field.onChange(values.floatValue || 0);
                        }
                      }}
                    />
                    <span style={{ marginLeft: "8px" }}>円</span>
                  </div>
                )}
              />
            </Popover>
          ) : (
            <div>{estimate.unitPriceFormatted}</div>
          )}
        </>
      ),
    },
    {
      title: "金額（税別）",
      dataIndex: "amount",
      className: "EstimateTable__col__amount",
      render: (_, estimate) => <div>{estimate.amountFormatted}</div>,
    },
    {
      title: "備考",
      dataIndex: "remarks",
      className: "EstimateTable__col__remarks",
      render: (_, estimate, index) =>
        control ? (
          <Controller
            name={`estimates[${index}].remarks`}
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                placeholder={project?.jobType?.value === "クロス・床" ? "例：資材支給あり" : "備考"}
                defaultValue={estimate.remarks}
              />
            )}
          />
        ) : (
          <div>{estimate.remarks}</div>
        ),
    },
  ];

  const columns = (): ColumnsType<any> => {
    const columns = itemColumns;
    if (control) {
      columns.unshift({
        title: (
          <>
            相場
            <br />
            参照
          </>
        ),
        dataIndex: "",
        className: "EstimateTable__col__amountReference",
        render: (_, estimate) => (
          <Button
            shape={"circle"}
            icon={<SearchOutlined />}
            onClick={() => togglePopover(estimate.id)}
            disabled={!estimate.itemName || popoverStatuses[estimate.id]}
          />
        ),
      });
    }

    if (deleteRow) {
      columns.push({
        title: "削除",
        dataIndex: "",
        className: "EstimateTable__col__delete",
        render: (_, estimate) => (
          <DeleteOutlined
            style={{ color: "red", fontSize: "20px", margin: "0 auto" }}
            onClick={() => deleteRow(estimate.rowNumber)}
          />
        ),
      });
    }
    return columns;
  };

  return (
    <Table className="EstimateTable" pagination={false} columns={columns()} dataSource={estimates.value} rowKey="id" />
  );
};

export default EstimateTable;
