import { Firestore, collection, doc, getDoc } from "firebase/firestore";
import AdminWorkerSettingModel from "common/models/AdminWorkerSettingModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "adminWorkerSettings";

export class AdminWorkerSettingRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef() {
    return collection(this.firestore, `admin/v1/${collectionName}`);
  }

  private getDocRef(id: string) {
    const colPath = this.getColRef().path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findById(id: string): Promise<AdminWorkerSettingModel | null> {
    const docRef = this.getDocRef(id);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const adminWorkerSetting = { ...snapshot.data(), id: snapshot.id } as DBModel.AdminWorkerSetting;
    return new AdminWorkerSettingModel(adminWorkerSetting);
  }

  async upsert({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.AdminWorkerSetting>) {
    if (!id) {
      throw new Error("id is required");
    }
    const docRef = this.getDocRef(id);

    // docが存在する場合は更新、存在しない場合は作成
    const snapshot = await getDoc(docRef);
    let upsertAdminWorkerSetting: DBModel.AdminWorkerSetting;
    if (snapshot.exists()) {
      upsertAdminWorkerSetting = setUpdateInfo({ data, uid });
    } else {
      upsertAdminWorkerSetting = setCreateInfo({ data, uid });
    }

    await setDocument(docRef, upsertAdminWorkerSetting, writeBatch);
  }
}

const adminWorkerSettingRepository = new AdminWorkerSettingRepository(db);
export default adminWorkerSettingRepository;
