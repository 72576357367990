import { Button } from "antd";
import WorkerList from "@/features/admin/worker/WorkerList";
import { useNavigate } from "react-router-dom";

/**職人一覧ページ（管理画面） */
export default function AdminWorkers(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div style={{ padding: "24px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <h1>職人一覧</h1>
        <Button onClick={() => navigate("/admin/projects")}>案件一覧へ</Button>
        <Button onClick={() => navigate("/admin/invoices")}>請求一覧へ</Button>
      </div>
      <WorkerList />
    </div>
  );
}
