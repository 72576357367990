import { DBModel } from "../@types/global";
import CustomDateTime from "./shared/CustomDateTime";
import CompanyAddress from "./shared/CompanyAddress";

/**請求 */
export default class InvoiceModel {
  public readonly id: string;
  public readonly adminNote: string; // 管理者用メモ
  public readonly assignee: string | null; // 担当者
  public readonly checker: string | null; // 確認者
  public readonly invoiceAddress: CompanyAddress; // 請求先住所
  public readonly invoiceDate: CustomDateTime | null; // 請求日
  public readonly invoiceNumber: string | null; // freeeの請求書番号
  public readonly invoiceTo: string; // 請求先名
  public readonly ownerId: string; // 依頼者のID
  public readonly salesYearMonth: string; // 取引発生年月（ISO8601形式 例. 2024-04）
  public readonly status: string; // ステータス

  constructor(data: Partial<DBModel.Invoice>) {
    const defaultValues = {
      id: "",
      adminNote: "",
      assignee: null,
      checker: null,
      invoiceAddress: {
        prefecture: "",
        city: "",
        streetNumberAndBuilding: "",
      },
      invoiceDate: null,
      invoiceNumber: null,
      invoiceTo: "",
      ownerId: "",
      salesYearMonth: "",
      status: "uninvoiced",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.adminNote = mergedData.adminNote;
    this.assignee = mergedData.assignee;
    this.checker = mergedData.checker;
    this.invoiceAddress = new CompanyAddress(mergedData.invoiceAddress);
    this.invoiceDate = mergedData.invoiceDate ? new CustomDateTime(mergedData.invoiceDate) : null;
    this.invoiceNumber = mergedData.invoiceNumber;
    this.invoiceTo = mergedData.invoiceTo;
    this.ownerId = mergedData.ownerId;
    this.salesYearMonth = mergedData.salesYearMonth;
    this.status = mergedData.status;
  }

  get statusLabel(): string {
    if (this.status === "uninvoiced") {
      return "未請求";
    } else if (this.status === "invoiced") {
      return "請求済み";
    } else {
      return "";
    }
  }

  /**ステータスを請求済みに変更できるかどうか */
  get canChangeStatusToInvoiced(): boolean {
    return !!this.invoiceNumber && !!this.assignee && !!this.checker;
  }

  toJSON(): DBModel.Invoice {
    return {
      adminNote: this.adminNote,
      assignee: this.assignee,
      checker: this.checker,
      invoiceAddress: this.invoiceAddress.value,
      invoiceDate: this.invoiceDate?.value || null,
      invoiceNumber: this.invoiceNumber,
      invoiceTo: this.invoiceTo,
      ownerId: this.ownerId,
      salesYearMonth: this.salesYearMonth,
      status: this.status,
    };
  }
}
