import { DBModel } from "../@types/global";

export default class AnalyticsModel {
  public readonly id: string;
  public readonly detailPageProUserViewCount: number; // PROユーザーの案件詳細ページ閲覧数（未登録ユーザー含む）
  public readonly detailPageViewProUids: string[] | null; // 案件詳細ページを閲覧したPROユーザーのID（未登録ユーザー含む）

  constructor(data: Partial<DBModel.Analytics>) {
    const defaultValues = {
      id: "pageView", // 固定
      detailPageProUserViewCount: 0,
      detailPageViewProUids: null,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.detailPageProUserViewCount = mergedData.detailPageProUserViewCount;
    this.detailPageViewProUids = mergedData.detailPageViewProUids;
  }

  /**案件詳細ページを閲覧した職人の人数 */
  get detailPageViewProUserCount(): number {
    return this.detailPageViewProUids?.length || 0;
  }

  toJSON(): DBModel.Analytics {
    return {
      detailPageProUserViewCount: this.detailPageProUserViewCount,
      detailPageViewProUids: this.detailPageViewProUids,
    };
  }
}
