import { useState, useEffect } from "react";
import MessageModel from "common/models/MessageModel";
import messageRepository from "@/repositories/MessageRepository";
import message from "antd/lib/message";
import { upsertOwnerLastReadDate } from "@/features/chat/api/upsertOwnerLastReadDate";

type HookResult = {
  messages: MessageModel[];
};

const useMessages = (
  projectId: string,
  chatRoomId: string,
  uid: string = "",
  scrollToBottom: () => void // 全メッセージを受信した後の処理
): HookResult => {
  const [messages, setMessages] = useState<MessageModel[]>([]);

  useEffect(() => {
    const unsubscribe = messageRepository.subscribe({
      projectId,
      chatRoomId,
      uid,
      callback: setMessages,
      onGetNewMessage: async (newMessage) => {
        const text = `新着メッセージがあります: ${newMessage.body}`;
        message.info(text);

        await upsertOwnerLastReadDate({ projectId, chatRoomId }); // subscribe中にメッセージを受信したら依頼者の最終既読日時を更新する
      },
      onSendNewMessage: scrollToBottom, // 自分がメッセージ送信したときはボトムにスクロールする
    });

    return () => {
      unsubscribe();
    };
  }, [projectId, chatRoomId]);

  return { messages };
};
export default useMessages;
