import projectRepository from "@/repositories/ProjectRepository";
import ProjectModel from "common/models/ProjectModel";
import Status from "common/models/project/Status";

/**応募者を断り、募集を再開します */
export async function cancel(project: ProjectModel, workerId: string) {
  const updateProject = {
    canceledWorkerIds: project.canceledWorkerIds ? [...project.canceledWorkerIds, workerId] : [workerId],
    status: Status.Recruiting.value,
  };
  await projectRepository.update({ id: project.id, data: updateProject });
}
