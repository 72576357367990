import { Document, Page, pdfjs, DocumentProps } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type Props = {
  file: DocumentProps["file"];
};

const PdfToThumbnail = ({ file }: Props) => {
  return (
    <Document file={file}>
      <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={200} />
    </Document>
  );
};

export default PdfToThumbnail;
