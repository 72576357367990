export default class JobType {
  private static _values = new Array<JobType>();

  public static readonly Carpenter = new JobType("大工", "大工");
  public static readonly Cleaning = new JobType("清掃", "清掃");
  public static readonly CrossFloor = new JobType("クロス・床", "クロス・床");
  public static readonly Demolition = new JobType("解体", "解体");
  public static readonly Disinfection = new JobType("消毒・防虫", "消毒・防虫");
  public static readonly Electrical = new JobType("電気設備", "電気設備");
  public static readonly SheetFilm = new JobType("シート・フィルム", "シート・フィルム");
  public static readonly FireSafety = new JobType("消防設備", "消防設備");
  public static readonly Glass = new JobType("ガラス", "ガラス");
  public static readonly Key = new JobType("鍵", "鍵");
  public static readonly Painting = new JobType("塗装", "塗装");
  public static readonly Repair = new JobType("リペア", "リペア");
  public static readonly RoofWaterproof = new JobType("屋根・防水", "屋根・防水");
  public static readonly TatamiFusuma = new JobType("たたみ・襖・障子", "たたみ・襖・障子");
  public static readonly TileBlock = new JobType("タイル・ブロック", "タイル・ブロック");
  public static readonly WasteCollection = new JobType("不用品回収・収集運搬", "不用品回収・収集運搬");
  public static readonly WaterFacility = new JobType("水道設備", "水道設備");
  public static readonly Sash = new JobType("サッシ", "サッシ");
  public static readonly GasFacility = new JobType("ガス設備", "ガス設備");
  public static readonly Nothing = new JobType("なし", "なし");

  private constructor(public readonly value: string, public readonly label: string) {
    if (value !== "" && value !== "なし") {
      JobType._values.push(this);
    }
  }

  static get values(): JobType[] {
    return this._values;
  }

  static fromValue(value: string): JobType {
    return this._values.find((v) => v.value === value) || JobType.Nothing;
  }
}
