import { Button } from "antd";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import "./RatingButtons.scss";

type Prop = {
  rating: number;
  setRating: (type: string, value: number) => void;
  type: string;
};

const RatingButtons = ({ rating, setRating, type }: Prop) => {
  return (
    <div className="RatingButtons">
      <div className="RatingButtons__buttonContainer">
        <Button
          icon={<LikeOutlined className="RatingButtons__buttonContainer__button__icon" />}
          shape="circle"
          type={rating === 5 ? "primary" : "default"}
          onClick={() => setRating(type, 5)}
          className="RatingButtons__buttonContainer__button"
        />
        良かった
      </div>
      <div className="RatingButtons__buttonContainer">
        <Button
          icon={<DislikeOutlined className="RatingButtons__buttonContainer__button__icon" />}
          shape="circle"
          type={rating === 1 ? "primary" : "default"}
          danger
          onClick={() => setRating(type, 1)}
          className="RatingButtons__buttonContainer__button"
        />
        悪かった
      </div>
    </div>
  );
};

export default RatingButtons;
