import { DBModel } from "../@types/global";

export default class WithdrawalQuestionnaireModel {
  public readonly id: string;
  public readonly opinion: string | null; // ご意見
  public readonly otherReason: string | null; // その他（自由記入）内容
  public readonly reasons: string[]; // 取り下げ理由

  constructor(data: Partial<DBModel.WithdrawalQuestionnaire>) {
    const defaultValues = {
      id: "detail", // 固定
      opinion: null,
      otherReason: null,
      reasons: [],
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.opinion = mergedData.opinion;
    this.otherReason = mergedData.otherReason;
    this.reasons = mergedData.reasons;
  }

  get reasonsText(): string {
    return this.reasons.join(" / ");
  }

  /**その他（自由記入）を選択している */
  get isSelectedOther(): boolean {
    return this.reasons.includes("その他（自由記入）");
  }

  toJSON(): DBModel.WithdrawalQuestionnaire {
    return {
      opinion: this.opinion,
      otherReason: this.otherReason,
      reasons: this.reasons,
    };
  }
}
