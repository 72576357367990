import { DBModel } from "../@types/global";
import UserType from "./shared/UserType";
import CustomDateTime from "./shared/CustomDateTime";

export default class CommentModel {
  public readonly id: string;
  public readonly body: string; // コメント内容
  public readonly deleted: boolean; // 削除フラグ
  public readonly displayName: string; // 表示名
  public readonly senderType: UserType; // 送信者種別
  public readonly senderUid: string; // 送信者のUID
  public readonly sentDate: CustomDateTime; // 送信日時

  constructor(data: Partial<DBModel.Comment>) {
    const defaultValues = {
      id: "",
      body: "",
      deleted: false,
      displayName: "",
      senderType: UserType.Worker.value,
      senderUid: "",
      sentDate: undefined,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.body = mergedData.body;
    this.deleted = mergedData.deleted;
    this.displayName = mergedData.displayName;
    this.senderType = UserType.fromValue(mergedData.senderType);
    this.senderUid = mergedData.senderUid;
    this.sentDate = new CustomDateTime(mergedData.sentDate);
  }

  static ofOwner(data: Partial<DBModel.Comment>): CommentModel {
    return new CommentModel({
      ...data,
      senderType: UserType.Owner.value,
    });
  }

  static ofDelete(data: Partial<DBModel.Comment>): CommentModel {
    return new CommentModel({
      ...data,
      deleted: true,
    });
  }

  /**メッセージ送信者と同じユーザーかどうか */
  isSameUser(uid: string): boolean {
    return this.senderUid === uid;
  }

  toJSON(): DBModel.Comment {
    return {
      body: this.body,
      deleted: this.deleted,
      displayName: this.displayName,
      senderType: this.senderType.value,
      senderUid: this.senderUid,
      sentDate: this.sentDate.value,
    };
  }
}
