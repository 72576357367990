import { ProjectList } from "@/features/admin/project/ProjectList";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

/**案件一覧ページ（管理画面） */
export default function AdminProjects(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div style={{ padding: "24px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <h1>案件一覧</h1>
        <Button onClick={() => navigate("/admin/workers")}>職人一覧へ</Button>
        <Button onClick={() => navigate("/admin/invoices")}>請求一覧へ</Button>
      </div>
      <ProjectList />
    </div>
  );
}
