import React from "react";
import useModal from "@/hooks/useModal";
import usePublicWorkerDetail from "@/hooks/usePublicWorkerDetail";
import { Modal, message } from "antd";
import ProjectModel from "common/models/ProjectModel";
import Button from "@/components/Button";
import WorkerCard from "./WorkerCard";
import { order } from "./api/order";
import { cancel } from "./api/cancel";
import { useQuery } from "@tanstack/react-query";
import * as proposedEstimateService from "@/services/proposedEstimateService";
import ProposedEstimates from "./ProposedEstimates";
import EstimateListModel from "common/models/EstimateListModel";
import * as applyService from "@/services/applyService";

type Props = {
  project: ProjectModel;
  estimates: EstimateListModel;
};
/**応募者or作業者 */
const Worker: React.FC<Props> = ({ project, estimates }) => {
  const cancelModal = useModal();
  const orderModal = useModal();
  const workerId = project.workerId; // 作業者のID
  const applyingWorkerId = project.applyingWorkerId!; // 応募中（キャンセルされていない）職人のID（※1人まで）
  const { worker } = usePublicWorkerDetail(workerId || applyingWorkerId);
  const { data: apply } = useQuery({
    queryKey: ["apply", applyingWorkerId],
    queryFn: () => applyService.fetchApply(project.id, applyingWorkerId),
    retry: 0,
    enabled: !!project && !!project.id,
    initialData: null,
  });
  const { data: proposedEstimates } = useQuery({
    queryKey: ["proposedEstimates", workerId],
    queryFn: () => proposedEstimateService.fetchProposedEstimateList(project.id, apply!.workerId),
    retry: 0,
    enabled: !!project.id && !!apply,
    initialData: null,
  });

  if (!apply || !worker) {
    return <></>;
  }

  /**応募者を断る */
  const handleCancel = async () => {
    try {
      await cancel(project, apply.workerId);
      message.info("見送りました。");
      cancelModal.handleClose();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  /**発注する */
  const handleOrder = async () => {
    try {
      await order(project.id, apply, proposedEstimates);
      message.info("発注しました。");
      orderModal.handleClose();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const actions = (project: ProjectModel) => {
    if (!project.canOrder) {
      return [];
    }

    return [
      <Button type="link" danger onClick={cancelModal.handleOpen} style={{ fontWeight: "700" }}>
        見送る
      </Button>,
      <Button type="link" onClick={orderModal.handleOpen} style={{ fontWeight: "700" }}>
        発注する
      </Button>,
    ];
  };

  return (
    <>
      {worker && (
        <WorkerCard project={project} worker={worker} actions={actions(project)}>
          <div className="WorkerCard__apply">
            <div className="WorkerCard__apply__workDateRange">
              <span className="WorkerCard__apply__workDateRange__label">工事期間</span> {apply.workDateRange}
            </div>
            {proposedEstimates && <ProposedEstimates estimates={estimates} proposedEstimates={proposedEstimates} />}
          </div>
        </WorkerCard>
      )}

      <Modal
        centered
        open={cancelModal.open}
        onCancel={cancelModal.handleClose}
        onOk={handleCancel}
        title="見送りますか？"
      >
        <p>{worker.displayName}さんをお見送りしてよろしいですか？</p>
        <p>お見送りした職人はこの案件に再度応募することはできません。</p>
      </Modal>
      <Modal
        centered
        open={orderModal.open}
        onCancel={orderModal.handleClose}
        onOk={handleOrder}
        title="発注しますか？"
      >
        {!proposedEstimates ? (
          <p>{worker.displayName}さんに案件を発注してよろしいですか？</p>
        ) : (
          <p>{worker.displayName}さんから提案された見積内容で案件を発注してよろしいですか？</p>
        )}
      </Modal>
    </>
  );
};

export default Worker;
