import { Firestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import ProposedEstimateModel from "common/models/ProposedEstimateModel";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";
import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";

const collectionName = "proposedEstimates";

export class ProposedEstimateRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  getColRef(projectId: string, applyId: string) {
    return collection(this.firestore, `projects/${projectId}/applies/${applyId}/${collectionName}`);
  }

  async findAll(projectId: string, applyId: string): Promise<ProposedEstimateListModel> {
    const colRef = this.getColRef(projectId, applyId);
    let q = query(colRef);
    q = query(q, orderBy("rowNumber", "asc"));
    const querySnapshot = await getDocs(q);
    const proposedEstimates: ProposedEstimateModel[] = [];
    querySnapshot.forEach((doc) => {
      const proposedEstimate = { ...doc.data(), id: doc.id } as DBModel.ProposedEstimate;
      proposedEstimates.push(new ProposedEstimateModel(proposedEstimate));
    });
    return new ProposedEstimateListModel(proposedEstimates);
  }
}

const proposedEstimateRepository = new ProposedEstimateRepository(db);
export default proposedEstimateRepository;
