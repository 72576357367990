import WorkingHistoryModel from "common/models/WorkingHistoryModel";
import PublicWorkerDetailModel from "common/models/PublicWorkerDetailModel";
import workingHistoryRepository from "@/repositories/WorkingHistoryRepository";
import Status from "common/models/project/Status";
import projectRepository from "@/repositories/ProjectRepository";
import { serverTimestamp, writeBatch, Timestamp } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { WriteBatch } from "firebase/firestore";

type Params = {
  projectId: string;
  worker: PublicWorkerDetailModel;
  workRating: DBModel.WorkRating;
  comment: string;
};
/** 作業の評価をし、検収を完了します */
export async function completeInspection({ projectId, worker, workRating, comment }: Params): Promise<void> {
  const batch = writeBatch(db);

  await createWorkingHistory({ workerId: worker.id, id: projectId, workRating, comment, writeBatch: batch });
  await finishProject(projectId, batch);

  await batch.commit();
}

type CreateWorkiingHistoryParams = {
  workerId: string;
  id: string;
  workRating: DBModel.WorkRating;
  comment: string;
  writeBatch: WriteBatch;
};
/**workingHistoryを作成します */
async function createWorkingHistory({ workerId, id, workRating, comment, writeBatch }: CreateWorkiingHistoryParams) {
  const newWorkingHistory = {
    workRating,
    comment,
    isReadByWorker: false,
  };
  await workingHistoryRepository.create({
    workerId,
    id,
    data: new WorkingHistoryModel(newWorkingHistory).toJSON(),
    writeBatch,
  });
}

/**案件のステータスを完了にします */
async function finishProject(projectId: string, writeBatch: WriteBatch) {
  const updateProject = {
    inspectedDate: serverTimestamp() as Timestamp,
    status: Status.Completed.value,
  };
  await projectRepository.update({ id: projectId, data: updateProject, writeBatch });
}
