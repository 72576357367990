import { Firestore, collection, getDoc, doc, Unsubscribe, onSnapshot } from "firebase/firestore";
import AnalyticsModel from "common/models/AnalyticsModel";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";

const collectionName = "analytics";

export class AnalyticsRepository {
  private firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/pageView`);
  }

  subscribeByProjectId(projectId: string, callback: (analytics: AnalyticsModel) => void): Unsubscribe {
    const docRef = this.getDocRef(projectId);
    return onSnapshot(docRef, (snapshot) => {
      const analytics = { ...snapshot.data(), id: snapshot.id } as DBModel.Analytics;
      callback(new AnalyticsModel(analytics));
    });
  }

  async findById(projectId: string): Promise<AnalyticsModel | null> {
    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);
    const analytics = { ...snapshot.data(), id: snapshot.id } as DBModel.Analytics;
    return new AnalyticsModel(analytics);
  }
}

const analyticsRepository = new AnalyticsRepository(db);
export default analyticsRepository;
