import { useState } from "react";
import { Document, Page, pdfjs, DocumentProps } from "react-pdf";
import "./PdfRenderer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type Props = {
  file: DocumentProps["file"];
};

const PdfRenderer = ({ file }: Props) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess: DocumentProps["onLoadSuccess"] = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} className="PdfRenderer">
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page-${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={800}
        />
      ))}
    </Document>
  );
};

export default PdfRenderer;
