import React, { useState, useEffect } from "react";
import { Tabs, Tooltip } from "antd";
import type { TabsProps } from "antd";
import ProjectModel from "common/models/ProjectModel";
import Worker from "./Worker";
import CanceledWorkers from "./CanceledWorker";
import EstimateListModel from "common/models/EstimateListModel";
import "./RelatedWorkers.scss";

type Props = {
  project: ProjectModel;
  estimates: EstimateListModel;
};
/**案件に関連する職人（応募者or作業者とお見送りした職人） */
const RelatedWorkers: React.FC<Props> = ({ project, estimates }) => {
  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    if (!project.hasApplyingWorker) {
      setActiveTabKey("2");
    } else {
      setActiveTabKey("1");
    }
  }, [project.workerId, project.hasApplyingWorker]);

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Tooltip title={!project.hasApplyingWorker && "応募者はいません"}>
          <h2>{project.workerTitle}</h2>
        </Tooltip>
      ),
      disabled: !project.hasApplyingWorker,
      children: <Worker project={project} estimates={estimates} />,
    },
    {
      key: "2",
      label: (
        <Tooltip title={!project.hasCanceledWorker && "お見送りした職人はいません"}>
          <h2>お見送りした職人</h2>
        </Tooltip>
      ),
      disabled: !project.hasCanceledWorker,
      children: <CanceledWorkers project={project} />,
    },
  ];

  return (
    <section className="RelatedWorkers">
      <Tabs activeKey={activeTabKey} items={tabItems} onTabClick={(key: string) => setActiveTabKey(key)} />
    </section>
  );
};

export default RelatedWorkers;
