import { Card, Row, Col } from "antd";
import ResidentModel from "common/models/ResidentModel";

type Props = {
  resident?: ResidentModel | null;
};
/**入居者情報 */
export function ProjectDetailResident({ resident }: Props): JSX.Element {
  return (
    <>
      {resident && (
        <Card title="入居者情報" size="small" type="inner" style={{ marginTop: 16 }}>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>入居者の氏名</Col>
            <Col span={14}>{resident.name}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>入居者の連絡先</Col>
            <Col span={14}>{resident.phoneNumber}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>入居者の備考</Col>
            <Col span={14}>{resident.note}</Col>
          </Row>
        </Card>
      )}
    </>
  );
}
