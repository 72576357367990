import { Firestore, collection, query, getDocs, orderBy, limit, Query } from "firebase/firestore";
import ReworkModel from "common/models/ReworkModel";
import { DBModel } from "common/@types/global";
import { db, auth } from "@/lib/firebase";
import { createDocument, CreateBaseParams, setCreateInfo } from "@/common/utils/firestoreUtil";

const collectionName = "reworks";

type CreateParams = {
  projectId: string;
} & CreateBaseParams<DBModel.Rework>;

export class ReworkRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  async findBy(q: Query): Promise<ReworkModel[]> {
    const querySnapshot = await getDocs(q);
    const reworks: ReworkModel[] = [];
    querySnapshot.forEach((doc) => {
      const rework = { ...doc.data(), id: doc.id } as DBModel.Rework;
      reworks.push(new ReworkModel(rework));
    });
    return reworks;
  }

  async findAll(projectId: string): Promise<ReworkModel[]> {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    const reworks = await this.findBy(q);
    return reworks;
  }

  /**最新の再施工1件を取得する */
  async findLatest(projectId: string): Promise<ReworkModel> {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    q = query(q, orderBy("createdAt", "desc"));
    q = query(q, limit(1));
    const reworks = await this.findBy(q);
    return reworks[0];
  }

  async create({ projectId, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams) {
    if (!projectId) {
      throw new Error("projectId is required");
    }
    const colRef = this.getColRef(projectId);
    const newRework: DBModel.Rework = setCreateInfo({ data, uid });
    return createDocument(colRef, newRework, writeBatch);
  }
}

const reworkRepository = new ReworkRepository(db);
export default reworkRepository;
