import { useState } from "react";
import { useMount } from "react-use";
import reworkRepository from "@/repositories/ReworkRepository";
import { fetchReworkImageUrlList } from "../api/fetchReworkImageUrlList";

type HookResult = {
  isLoading: boolean;
  reworkImageUrlList: FunctionParams.FetchReworkImageUrlList.ResponseParams;
};

export function useReworkImageUrlList(projectId: string): HookResult {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reworkImageUrlList, setReworkImageUrlList] = useState<FunctionParams.FetchReworkImageUrlList.ResponseParams>(
    []
  );

  useMount(async () => {
    try {
      // 再施工がない場合は何もしない
      const reworks = await reworkRepository.findAll(projectId);
      if (reworks.length === 0) {
        setIsLoading(false);
        return;
      }
      const reworkImageUrlList = await fetchReworkImageUrlList(projectId);
      setReworkImageUrlList(reworkImageUrlList);
      setIsLoading(false);
    } catch (error) {
      console.error("再施工写真のURL取得に失敗しました", error);
    }
  });

  return {
    isLoading,
    reworkImageUrlList,
  };
}
