import * as fileRepository from "@/repositories/fileRepository";
import { convertPdfBlobToPngBlob } from "@/utils/fileUtil";

/**storageから複数画像のURLを取得する */
export async function fetchImageUrls(storagePaths: string[]): Promise<string[]> {
  const blobs = await fileRepository.fetchBlobs(storagePaths);

  let urls: string[] = [];
  for (const blob of blobs) {
    if (blob.type === "application/pdf") {
      // PDFの場合はページ数分のimageのblobを取得する
      const convertedBlobs = await convertPdfBlobToPngBlob(blob);
      urls = urls.concat(convertedBlobs.map((blob: Blob) => URL.createObjectURL(blob)));
    } else {
      urls.push(URL.createObjectURL(blob));
    }
  }
  return urls;
}
