import Status from "common/models/project/Status";
import projectRepository from "@/repositories/ProjectRepository";
import reworkRepository from "@/repositories/ReworkRepository";
import { FormData } from "@/features/inspection/RequestReworkModal";
import { writeBatch, WriteBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import * as messageService from "@/services/messageService";
import ReworkModel from "common/models/ReworkModel";
import ReworkDetailModel from "common/models/ReworkDetailModel";
import reworkDetailRepository from "@/repositories/ReworkDetailRepository";

/**職人に再施工を依頼します */
export async function requestRework(projectId: string, workerId: string, data: FormData) {
  const batch = writeBatch(db);

  await changeStatusToUnderConstruction(projectId, batch);
  const reworkId = await createRework(projectId, batch);
  await createReworkDetails({ projectId, reworkId, reworkTargetEstimateIds: data.reworkTargetEstimateIds, batch });
  await sendReworkRequestMessage({ projectId, workerId, reworkReason: data.reworkReason, batch });

  await batch.commit();
}

/**ステータスを工事中に変更します */
async function changeStatusToUnderConstruction(projectId: string, batch: WriteBatch) {
  const updateProject = {
    status: Status.UnderConstruction.value,
  };
  await projectRepository.update({ id: projectId, data: updateProject, writeBatch: batch });
}

/**再施工ドキュメントを作成します */
async function createRework(projectId: string, batch: WriteBatch) {
  const newRework = new ReworkModel({ projectId }).toJSON();
  return await reworkRepository.create({ projectId, data: newRework, writeBatch: batch });
}

type CreateReworkDetailsParams = {
  projectId: string;
  reworkId: string;
  reworkTargetEstimateIds: string[];
  batch: WriteBatch;
};
/**再施工の詳細ドキュメントを作成します */
async function createReworkDetails({ projectId, reworkId, reworkTargetEstimateIds, batch }: CreateReworkDetailsParams) {
  for (const estimateId of reworkTargetEstimateIds) {
    const newReworkDetail = new ReworkDetailModel({ estimateId }).toJSON();
    await reworkDetailRepository.create({
      projectId,
      reworkId,
      id: estimateId,
      data: newReworkDetail,
      writeBatch: batch,
    });
  }
}

type SendReworkRequestMessageParams = {
  projectId: string;
  workerId: string;
  reworkReason: string;
  batch: WriteBatch;
};
/**再施工依頼メッセージを送信します */
export async function sendReworkRequestMessage({
  projectId,
  workerId,
  reworkReason,
  batch,
}: SendReworkRequestMessageParams) {
  await messageService.createMessage({
    projectId,
    chatRoomId: workerId,
    body: `再施工をお願いします。理由: ${reworkReason}`,
    writeBatch: batch,
  });
}
