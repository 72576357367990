import { Functions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { functions } from "@/lib/firebase";

export class FunctionsRepository {
  private functions: Functions;

  constructor(functions: Functions) {
    this.functions = functions;
  }

  async httpsCallable(functionName: string, data: any): Promise<HttpsCallableResult> {
    const func = httpsCallable(this.functions, functionName);
    const res = await func(data);
    return res;
  }
}

const functionsRepository = new FunctionsRepository(functions);
export default functionsRepository;
