import { Firestore, doc, collection, getDocs, query, orderBy } from "firebase/firestore";
import { db, auth } from "@/lib/firebase";
import WorkingHistoryModel from "common/models/WorkingHistoryModel";
import { CreateBaseParams, setCreateInfo, setDocument } from "@/common/utils/firestoreUtil";

const collectionName = "workingHistories";

type CreateParams = {
  workerId: string;
  id: string; // projectIdと同じ
} & CreateBaseParams<DBModel.WorkingHistory>;

export class WorkingHistoryRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(workerId: string) {
    return collection(this.firestore, `workers/${workerId}/${collectionName}`);
  }

  private getDocRef(workerId: string, id: string) {
    const colPath = this.getColRef(workerId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async create({ workerId, id, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams): Promise<void> {
    const docRef = this.getDocRef(workerId, id!);
    const workingHistory: DBModel.WorkingHistory = setCreateInfo({ data, uid });
    await setDocument(docRef, workingHistory, writeBatch);
  }

  async findAll(workerId: string): Promise<WorkingHistoryModel[]> {
    const colRef = this.getColRef(workerId);
    let q = query(colRef);
    q = query(q, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);

    let workingHistories: WorkingHistoryModel[] = [];
    querySnapshot.forEach((doc) => {
      const workingHistory = { ...doc.data(), id: doc.id } as DBModel.WorkingHistory;
      workingHistories.push(new WorkingHistoryModel(workingHistory));
    });
    return workingHistories;
  }
}

const workingHistoryRepository = new WorkingHistoryRepository(db);
export default workingHistoryRepository;
