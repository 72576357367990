import { DBModel } from "../@types/global";

export default class WorkingHistoryModel {
  public readonly id: string;
  public readonly comment: string; // コメント
  public readonly isReadByWorker: boolean; // 職人が評価を確認したか
  public readonly workRating: DBModel.WorkRating; // 職人の作業の評価（評価は1or5の2段階）

  constructor(data: Partial<DBModel.WorkingHistory>) {
    const defaultValues = {
      id: "",
      comment: "",
      isReadByWorker: false,
      workRating: {
        quality: 5,
        speed: 5,
        communication: 5,
      },
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.comment = mergedData.comment;
    this.isReadByWorker = mergedData.isReadByWorker;
    this.workRating = mergedData.workRating;
  }

  toJSON(): DBModel.WorkingHistory {
    return {
      comment: this.comment,
      isReadByWorker: this.isReadByWorker,
      workRating: this.workRating,
    };
  }
}
