import { Input, Select, Textarea } from "@/components/FormItems";
import Button from "@/components/Button";
import { Row, Col, message } from "antd";
import WorkerModel from "common/models/WorkerModel";
import AdminWorkerSettingModel from "common/models/AdminWorkerSettingModel";
import { FormProvider, SubmitHandler, useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useState, useEffect } from "react";
import WorkerStatus from "common/models/worker/Status";
import WorkerIdentityVerifyStatus from "common/models/worker/IdentityVerifyStatus";

export type FormData = {
  worker: DBModel.Worker;
  adminWorkerSetting: DBModel.AdminWorkerSetting;
};

type ShopFormProps = {
  onSubmit: SubmitHandler<FormData>;
  worker: WorkerModel;
  adminWorkerSetting: AdminWorkerSettingModel;
  handleOpen: () => void;
};
/**職人詳細（職人編集フォーム） */
const WorkerForm = ({ onSubmit, worker, adminWorkerSetting, handleOpen }: ShopFormProps) => {
  const [defaultValues, setDefaultValues] = useState({
    worker: worker.toJSON(),
    adminWorkerSetting: adminWorkerSetting.toJSON(),
  });
  const methods = useForm<FormData>({ defaultValues });
  const { handleSubmit, watch, control, setValue } = methods;
  const values = watch();
  const [isValueChanged, setIsValueChanged] = useState(false);

  const identityVerifyStatusOptions = WorkerIdentityVerifyStatus.values.filter(
    (status) => status.value !== WorkerIdentityVerifyStatus.Deny.value
  );

  // フォームの変更を検知して、更新ボタンの活性化を制御する
  useEffect(() => {
    const isChanged = JSON.stringify(defaultValues) !== JSON.stringify(values);
    setIsValueChanged(isChanged);
  }, [values]);

  const handleOnSubmit: SubmitHandler<FormData> = async (data) => {
    await onSubmit(data);
    setDefaultValues(data);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Select
                name="worker.businessType"
                label="タイプ"
                options={[
                  { value: "person", label: "個人" },
                  { value: "company", label: "法人" },
                ]}
                style={{ width: "20em" }}
                disabled
              />
            </Col>
            <Col span={24}>
              <Input name="worker.companyName" label="会社名" disabled />
            </Col>
            <Col span={24}>
              <Input name="worker.companyNumber" label="法人番号" disabled />
            </Col>
            <Col span={12}>
              <Input name="worker.lastName" label="性" disabled />
            </Col>
            <Col span={12}>
              <Input name="worker.firstName" label="名" disabled />
            </Col>
            <Col span={24}>
              <Select
                name="worker.gender"
                label="性別"
                options={[
                  { value: "male", label: "男性" },
                  { value: "female", label: "女性" },
                  { value: "unknown", label: "不明" },
                ]}
                style={{ width: "20em" }}
                disabled
              />
            </Col>
            <Col span={24}>
              <Input name="worker.birthDate" label="生年月日" disabled />
            </Col>
            <Col span={24}>
              {/* 住所変更するなら、フォームを分解する必要あり */}
              <Input name="worker.address" value={worker.address.formatted} label="住所" disabled />
            </Col>
            <Col span={24}>
              <Input name="worker.phoneNumber" label="電話番号" disabled />
            </Col>
            <Col span={24}>
              <Input name="worker.jobTypes" label="職種" disabled />
            </Col>
            <Col span={24}>
              <Controller
                name="worker.rating"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    name="worker.rating"
                    label="評価"
                    customInput={Input}
                    isAllowed={(values) => {
                      if (values.value.includes("-")) {
                        return false;
                      }
                      const value = values.floatValue || 0;
                      return value >= 0 && value <= 5; // 0-5のみを許可
                    }}
                    value={field.value}
                    onValueChange={(values) => {
                      if (values.value === "") {
                        field.onChange("");
                      } else {
                        field.onChange(values.floatValue || 0);
                      }
                    }}
                    onBlur={(event) => {
                      // フォーカスが外れた時に値が空文字列だった場合、0をセット
                      if (event.target.value === "") {
                        field.onChange(0);
                      }
                    }}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Input name="worker.invoiceNumber" label="インボイス番号" disabled />
            </Col>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Select
                  name="worker.identityVerifyStatus"
                  label="本人確認"
                  options={identityVerifyStatusOptions}
                  style={{ width: "20em", marginRight: "4em" }}
                  disabled
                />
                <Button type="primary" onClick={handleOpen} disabled={!worker.needIdentityVerify}>
                  本人確認を行う
                </Button>
              </div>
            </Col>
            <Col span={24}>
              <Select
                name="adminWorkerSetting.antiSocialCheckResult"
                label="反社チェック"
                options={[
                  { value: "none", label: "未チェック" },
                  { value: "verified", label: "確認済" },
                  { value: "deny", label: "否認" },
                ]}
                style={{ width: "20em" }}
                onChange={(value: string) => {
                  // 反社チェックの結果、反社とみなされた場合、ステータスをログイン不可にする
                  if (value === "deny") {
                    setValue("worker.status", WorkerStatus.Ban.value);
                  }
                  setValue("adminWorkerSetting.antiSocialCheckResult", value);
                }}
              />
            </Col>
            <Col span={24}>
              <Select
                name="worker.status"
                label="ステータス"
                options={WorkerStatus.values}
                style={{ width: "20em" }}
                onChange={(value: string) => {
                  // 本人確認済みかつ反社チェック済みの場合のみ、ステータスを登録承認待ちにできる
                  if (value === WorkerStatus.Active.value) {
                    if (values.worker.identityVerifyStatus !== WorkerIdentityVerifyStatus.Verified.value) {
                      message.error("本人確認が済んでいません。");
                      return;
                    }
                    if (values.adminWorkerSetting.antiSocialCheckResult !== "verified") {
                      message.error("反社チェックが済んでいません。");
                      return;
                    }
                  }
                  setValue("worker.status", value);
                }}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="worker.oldPROWorkCount"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    name="worker.oldPROWorkCount"
                    label="旧リモデラPROでの施工件数"
                    customInput={Input}
                    isAllowed={(values) => {
                      if (values.value.includes("-")) {
                        return false;
                      }
                      const value = values.floatValue || 0;
                      return value >= 0;
                    }}
                    value={field.value}
                    onValueChange={(values) => {
                      if (values.value === "") {
                        field.onChange("");
                      } else {
                        field.onChange(values.floatValue || 0);
                      }
                    }}
                    onBlur={(event) => {
                      // フォーカスが外れた時に値が空文字列だった場合、0をセット
                      if (event.target.value === "") {
                        field.onChange(0);
                      }
                    }}
                  />
                )}
              />
            </Col>

            <Col span={24} style={{ marginTop: "12px", paddingTop: "12px", borderTop: "1px solid black" }}>
              <Textarea name="adminWorkerSetting.note" label="管理者用メモ" rows={4} />
            </Col>
          </Row>

          <Row gutter={[16, 12]} style={{ marginTop: "36px" }}>
            <Col span={24} style={{ margin: "40px 0" }}>
              <Button type="primary" htmlType="submit" block disabled={!isValueChanged}>
                更新する
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </>
  );
};

export default WorkerForm;
