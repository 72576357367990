import { useState, useEffect } from "react";
import AdminWorkerSettingModel from "common/models/AdminWorkerSettingModel";
import adminWorkerSettingRepository from "@/repositories/AdminWorkerSettingRepository";

type HookResult = {
  adminWorkerSetting: AdminWorkerSettingModel | null;
};

const useAdminWorkerSetting = (workerId: string): HookResult => {
  const [adminWorkerSetting, setAdminWorkerSetting] = useState<AdminWorkerSettingModel | null>(null);
  const fetchAdminWorkerSetting = async (workerId: string) => {
    const adminWorkerSetting = await adminWorkerSettingRepository.findById(workerId);
    if (adminWorkerSetting) {
      setAdminWorkerSetting(adminWorkerSetting);
    } else {
      setAdminWorkerSetting(new AdminWorkerSettingModel({ id: workerId }));
    }
  };

  useEffect(() => {
    if (!workerId) {
      return;
    }
    fetchAdminWorkerSetting(workerId);
  }, [workerId]);

  return {
    adminWorkerSetting,
  };
};

export default useAdminWorkerSetting;
