import { RemopadBQModel } from "../@types/global";
import { formatCurrency } from "../utils/textUtil";
import CustomDateTime from "./shared/CustomDateTime";

export default class SearchEstimateModel {
  public readonly id: string;
  public readonly estimateCreatedAt: CustomDateTime; // 見積作成日
  public readonly majorItem: string; // 大項目名
  public readonly minorItem: string; // 中項目名
  public readonly prefecture: string; // 都道府県
  public readonly unit: string; // 単位
  public readonly unitPrice: number; // 単価

  constructor(data: Partial<RemopadBQModel.SearchEstimate>) {
    const defaultValues = {
      id: crypto.randomUUID(),
      estimateCreatedAt: undefined,
      majorItem: "",
      minorItem: "",
      prefecture: "",
      unit: "",
      unitPrice: 0,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.estimateCreatedAt = mergedData.estimateCreatedAt
      ? new CustomDateTime(new Date(mergedData.estimateCreatedAt))
      : new CustomDateTime();
    this.majorItem = mergedData.majorItem;
    this.minorItem = mergedData.minorItem;
    this.prefecture = mergedData.prefecture;
    this.unit = mergedData.unit;
    this.unitPrice = mergedData.unitPrice;
  }

  get unitPriceFormatted(): string {
    return formatCurrency(this.unitPrice);
  }
}
