import { useEffect, useState } from "react";
import ProjectModel from "common/models/ProjectModel";
import OwnerModel from "common/models/OwnerModel";
import { fetchProjectsByAdmin } from "../api/fetchProjects";
import { fetchOwner } from "../api/fetchOwner";

export type ProjectWithOwner = {
  project: ProjectModel;
  owner: OwnerModel;
};

export function useProjects() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectWithOwnerList, setProjectWithOwnerList] = useState<ProjectWithOwner[]>([]);
  const [filteredProjectWithOwnerList, setfilteredProjectWithOwnerList] = useState<ProjectWithOwner[]>([]);

  // 検索条件
  const [projectNameValue, setProjectNameValue] = useState<string>("");
  const [projectIdValue, setProjectIdValue] = useState<string>("");
  const [ownerCompanyNameValue, setOwnerCompanyNameValue] = useState<string>("");
  const [statuses, setStatuses] = useState<number[]>([]);

  useEffect(() => {
    const fetchProjects = async (): Promise<void> => {
      try {
        const projects = await fetchProjectsByAdmin();
        const projectWithOwnerPromises = projects.map(async (project): Promise<ProjectWithOwner> => {
          const owner = await fetchOwner(project.ownerId);
          return { project, owner };
        });
        const projectWithOwnerList = await Promise.all(projectWithOwnerPromises);
        setProjectWithOwnerList(projectWithOwnerList);
        setfilteredProjectWithOwnerList(projectWithOwnerList);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch projects", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const filtered = projectWithOwnerList.filter((projectWithOwner) => {
      return (
        projectWithOwner.project.projectName.includes(projectNameValue) &&
        projectWithOwner.project.id.includes(projectIdValue) &&
        projectWithOwner.owner.companyName.includes(ownerCompanyNameValue) &&
        (statuses.length === 0 || statuses.includes(projectWithOwner.project.status.value))
      );
    });

    setfilteredProjectWithOwnerList(filtered);
  }, [projectWithOwnerList, projectNameValue, projectIdValue, ownerCompanyNameValue, statuses]);

  return {
    isLoading,
    filteredProjectWithOwnerList,
    projectNameValue,
    setProjectNameValue,
    projectIdValue,
    setProjectIdValue,
    ownerCompanyNameValue,
    setOwnerCompanyNameValue,
    setStatuses,
  };
}
