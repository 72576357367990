import React from "react";
import { Carousel as AntdCarousel, Image } from "antd";
import "./Carousel.scss";

type Props = {
  imageUrls: string[];
};

const Carousel: React.FC<Props> = ({ imageUrls }) => {
  return (
    <AntdCarousel className="Carousel" dots={{ className: "Carousel__dots" }}>
      {imageUrls.map((imageUrl, index) => (
        <Image key={index} src={imageUrl} preview={false} className="Carousel__image" />
      ))}
    </AntdCarousel>
  );
};

export default Carousel;
