import ProposedEstimateModel from "./ProposedEstimateModel";
import { formatCurrency } from "../utils/textUtil";
import Big from "big.js";
import { TAX_RATE } from "../constant";
import EstimateListModel from "./EstimateListModel";

export default class ProposedEstimateListModel {
  private _value: ProposedEstimateModel[];

  constructor(data: ProposedEstimateModel[]) {
    this._value = data;
  }

  get value(): ProposedEstimateModel[] {
    return this._value;
  }

  get length(): number {
    return this.value.length;
  }

  get isEmpty(): boolean {
    return this.value.length === 0;
  }

  /**合計金額（税抜） */
  get totalAmount(): number {
    return this._value.reduce((acc: number, proposedEstimate: ProposedEstimateModel) => {
      return acc + proposedEstimate.amount;
    }, 0);
  }

  get totalAmountFormatted(): string {
    return formatCurrency(this.totalAmount);
  }

  /**消費税（小数点切り捨て） */
  get tax(): number {
    return Math.floor(Number(Big(this.totalAmount).times(TAX_RATE)));
  }

  get taxFormatted(): string {
    return formatCurrency(this.tax);
  }

  /**合計金額（税込） */
  get totalAmountIncludeTax(): number {
    return this.totalAmount + this.tax;
  }

  get totalAmountIncludeTaxFormatted(): string {
    return formatCurrency(this.totalAmountIncludeTax);
  }

  /**内容が変更されている見積IDを取得します */
  changedEstimateIds(estimates: EstimateListModel): string[] {
    let changedEstimateIds: string[] = [];
    for (const proposedEstimate of this.value) {
      const originEstimate = estimates.value.find((estimate) => estimate.id === proposedEstimate.id);
      // 元の見積がない場合（職人に追加された見積の場合）
      if (!originEstimate) {
        changedEstimateIds.push(proposedEstimate.id);
        continue;
      }
      // 単価が変更されている場合
      if (originEstimate.unitPrice !== proposedEstimate.unitPrice) {
        changedEstimateIds.push(proposedEstimate.id);
        continue;
      }
    }
    return changedEstimateIds;
  }

  toJSONArray(): DBModel.ProposedEstimate[] {
    return this._value.map((proposedEstimate: ProposedEstimateModel) => {
      return { id: proposedEstimate.id, ...proposedEstimate.toJSON() };
    });
  }
}
