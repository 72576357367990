export default class WorkerIdentityVerifyStatus {
  private static _values = new Array<WorkerIdentityVerifyStatus>();

  public static readonly None = new WorkerIdentityVerifyStatus("none", "未承認");
  public static readonly Verifying = new WorkerIdentityVerifyStatus("verifying", "承認待ち");
  public static readonly Resend = new WorkerIdentityVerifyStatus("resend", "再送信");
  public static readonly Verified = new WorkerIdentityVerifyStatus("verified", "確認済");
  public static readonly Deny = new WorkerIdentityVerifyStatus("deny", "否認");
  public static readonly Nothing = new WorkerIdentityVerifyStatus("", "なし");

  private constructor(public readonly value: string, public readonly label: string) {
    if (value !== "") {
      WorkerIdentityVerifyStatus._values.push(this);
    }
  }

  static fromValue(value: string): WorkerIdentityVerifyStatus {
    return this.values.find((v) => v.value === value) || WorkerIdentityVerifyStatus.Nothing;
  }

  static get values(): WorkerIdentityVerifyStatus[] {
    return this._values;
  }
}
