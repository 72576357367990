import proposedEstimateRepository from "@/repositories/ProposedEstimateRepository";
import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";

/**職人が提案した見積を取得します */
export async function fetchProposedEstimateList(
  projectId: string,
  applyId: string
): Promise<ProposedEstimateListModel> {
  return await proposedEstimateRepository.findAll(projectId, applyId);
}
