import { Modal, Button } from "antd";
import { CloseOutlined, DownloadOutlined, ExportOutlined } from "@ant-design/icons";
import PdfRenderer from "@/components/PdfRenderer";
import { ModalHook } from "@/hooks/useModal";
import "./PdfViewer.scss";

const downloadFile = (file: File) => {
  const blobUrl = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = file.name; // ダウンロードするファイル名を設定
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};

type Props = {
  file: File;
  modalHook: ModalHook;
  isHideNewTabButton?: boolean;
};

const PdfViewer = ({ file, modalHook, isHideNewTabButton = false }: Props) => {
  const handleDownload = () => {
    if (!navigator.share) {
      downloadFile(file);
    } else {
      navigator
        .share({
          files: [file],
          title: file.name,
          text: `${file.name}です。`,
        })
        .then(() => {
          console.log("ファイルが共有されました。");
        })
        .catch((error) => {
          console.error("共有中にエラーが発生しました:", error);
        });
    }
  };

  const handleNewTab = () => {
    const newWindow = window.open("", "_blank");
    const blobUrl = URL.createObjectURL(file);
    newWindow!.location.href = blobUrl;
  };

  return (
    <Modal
      className="PdfViewer"
      style={{ top: 0, pointerEvents: "auto" }}
      open={modalHook.open}
      onCancel={modalHook.handleClose}
      width={800}
      footer={null}
      maskClosable={false}
      modalRender={() => (
        <>
          <div className="PdfViewer__header">
            <div className="PdfViewer__header__left">
              <Button size="large" type="text" icon={<CloseOutlined />} onClick={modalHook.handleClose} />
            </div>
            <div className="PdfViewer__header__right">
              <Button size="large" type="text" icon={<DownloadOutlined />} onClick={handleDownload} />
              {!isHideNewTabButton && (
                <Button size="large" type="text" icon={<ExportOutlined />} onClick={handleNewTab} />
              )}
            </div>
          </div>
          <PdfRenderer file={file} />
        </>
      )}
    />
  );
};
export default PdfViewer;
