import React from "react";
import { MultiUpload } from "@/components/FormItems";
import ProjectModel from "common/models/ProjectModel";
import { uploadPropertyPhoto } from "./api/uploadPropertyPhoto";
import { deleteFile } from "./api/deleteFile";
import { message } from "antd";

type Props = {
  projectId: string;
  project?: ProjectModel;
  setIsUploadingFile: (isUploadingFile: boolean) => void;
};

const PropertyPhotoUpload: React.FC<Props> = ({ projectId, project, setIsUploadingFile }) => {
  async function handleUpload(file: File) {
    try {
      const storagePath = await uploadPropertyPhoto(projectId, file);
      return storagePath;
    } catch (error: any) {
      console.error(error);
      message.error("ファイルのアップロードに失敗しました");
      return "";
    }
  }

  async function handleDelete(file: any): Promise<void> {
    const storagePath = file.response || file.storagePath;
    if (!storagePath) {
      return;
    }

    try {
      await deleteFile(storagePath);
    } catch (error: any) {
      console.error(error);
      message.error("ファイルの削除に失敗しました");
    }
  }

  return (
    <MultiUpload
      name="project.propertyPhotoStoragePaths"
      label="工事箇所の写真"
      multiple={true}
      onUpload={handleUpload}
      onRemove={handleDelete}
      initFileStoragePaths={project?.propertyPhotoStoragePaths || []}
      setIsUploadingFile={setIsUploadingFile}
    />
  );
};
export default PropertyPhotoUpload;
