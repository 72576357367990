import EstimateModel from "./EstimateModel";
import { formatCurrency } from "../utils/textUtil";
import Big from "big.js";
import { TAX_RATE } from "../constant";

export default class EstimateListModel {
  private _value: EstimateModel[];

  constructor(data: EstimateModel[]) {
    this._value = data;
  }

  get value(): EstimateModel[] {
    return this._value;
  }

  get length(): number {
    return this.value.length;
  }

  get isEmpty(): boolean {
    return this.value.length === 0;
  }

  /**見積が不正である */
  get isInvalid(): boolean {
    return (
      this.isEmpty ||
      this._value.some((estimate) => !estimate.amount || !estimate.quantity || !estimate.unit) ||
      this.totalAmount === 0
    );
  }

  /**見積提案機能を使う場合、見積が不正である */
  get isInvalidForEstimateProposal(): boolean {
    return this.isEmpty || this._value.some((estimate) => !estimate.quantity || !estimate.unit);
  }

  /**合計金額（税抜） */
  get totalAmount(): number | null {
    if (this._value.some((estimate) => estimate.amount === null)) {
      return null;
    }
    return this._value.reduce((acc: number, estimate: EstimateModel) => {
      return acc + estimate.amount!;
    }, 0);
  }

  get totalAmountFormatted(): string {
    return formatCurrency(this.totalAmount);
  }

  /**消費税（小数点切り捨て） */
  get tax(): number | null {
    if (this.totalAmount === null) {
      return null;
    }
    return Math.floor(Number(Big(this.totalAmount).times(TAX_RATE)));
  }

  get taxFormatted(): string {
    return formatCurrency(this.tax);
  }

  /**合計金額（税込） */
  get totalAmountIncludeTax(): number | null {
    if (this.totalAmount === null || this.tax === null) {
      return null;
    }
    return this.totalAmount + this.tax;
  }

  get totalAmountIncludeTaxFormatted(): string {
    return formatCurrency(this.totalAmountIncludeTax);
  }

  toJSONArray(): DBModel.Estimate[] {
    return this._value.map((estimate: EstimateModel) => {
      return { id: estimate.id, ...estimate.toJSON() };
    });
  }
}
