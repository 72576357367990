import axios from "axios";

const ssoClient = axios.create({
  baseURL: process.env.REACT_APP_REMODELA_SSO_URL,
  withCredentials: true,
});

const remopadClient = axios.create({
  baseURL: process.env.REACT_APP_REMOPAD_FUNCTION_BASE_URL,
});

export { ssoClient, remopadClient };
