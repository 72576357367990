import { useState } from "react";
import { Input, Textarea, Select, Radio, DatePicker } from "@/components/FormItems";
import Button from "@/components/Button";
import { Row, Col } from "antd";
import ProjectModel from "common/models/ProjectModel";
import { createRules, radioRequired } from "@/common/utils/validationUtil";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import PropertyPhotoUpload from "@/features/project/PropertyPhotoUpload";
import FloorPlanUpload from "@/features/project/FloorPlanUpload";
import KeyInfoModel from "common/models/KeyInfoModel";
import KeyLocationUpload from "@/features/project/KeyLocationUpload";
import ResidentModel from "common/models/ResidentModel";

type Props = {
  projectId: string;
  onSubmit: SubmitHandler<FormData>;
  project: ProjectModel;
  keyInfo: KeyInfoModel | null;
  resident: ResidentModel | null;
};

export type FormData = {
  project: DBModel.Project;
  keyInfo: DBModel.KeyInfo;
  resident: DBModel.Resident;
};

const ProjectForm = ({ projectId, onSubmit, project, keyInfo, resident }: Props) => {
  const methods = useForm<FormData>({
    defaultValues: {
      project: project.toJSON(),
      keyInfo: keyInfo?.toJSON(),
      resident: resident?.toJSON(),
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const values = watch();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[16, 12]}>
            <Col span={24} flex="auto">
              <Input
                name="project.projectName"
                defaultValue={""}
                label="タイトル"
                placeholder="キッチン クロス張替え"
                rules={createRules("建物名", 5)}
              />
            </Col>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Input name="project.address.postalCode" label="郵便番号" style={{ width: "10em" }} disabled />
              </div>
            </Col>
            <Col span={24}>
              <Select name="project.address.prefecture" label="都道府県" disabled style={{ width: "10em" }} />
            </Col>
            <Col span={24}>
              <Input name="project.address.city" label="市区町村" disabled style={{ width: "20em" }} />
            </Col>
            <Col span={24}>
              <Input name="project.address.streetNumber" label="丁・番地" disabled />
            </Col>
            <Col span={24}>
              <Input name="project.address.extraAddress" label="建物名・部屋番号" disabled />
            </Col>
            <Col span={24}>
              <Radio
                name="project.hasElevator"
                label="エレベーターの有無"
                rules={radioRequired("エレベーターの有無")}
                options={[
                  { label: "あり", value: true },
                  { label: "なし", value: false },
                ]}
              />
            </Col>
            <Col span={24}>
              <Radio
                name="project.hasAutoLock"
                label="オートロックの有無"
                rules={radioRequired("オートロックの有無")}
                onChange={(e) => {
                  if (!e.target.value) {
                    setValue("keyInfo.autoLockNo", null);
                  }
                }}
                options={[
                  { label: "あり", value: true },
                  { label: "なし", value: false },
                ]}
              />
            </Col>
            {values.project.hasAutoLock && (
              <Col span={24}>
                <Input
                  name="keyInfo.autoLockNo"
                  label="オートロックの番号・解除方法"
                  rules={createRules("オートロックの番号・解除方法")}
                />
              </Col>
            )}
            <Col span={24}>
              <Radio
                name="project.hasResident"
                label="入居状況"
                rules={radioRequired("入居状況")}
                options={[
                  { label: "入居中", value: true },
                  { label: "空室", value: false },
                ]}
                onChange={(e) => {
                  if (e.target.value) {
                    setValue("keyInfo.keyLocation", null);
                    setValue("keyInfo.keyLocationImageStoragePaths", null);
                    setValue("project.isElectricityConnected", null);
                    setValue("project.isWaterConnected", null);
                    setValue("project.hasRequestElectricityConnection", null);
                    setValue("project.hasRequestElectricityConnection", null);
                  } else {
                    setValue("resident.name", "");
                    setValue("resident.note", "");
                    setValue("resident.phoneNumber", "");
                  }
                }}
              />
            </Col>
            {values.project.hasResident ? (
              <>
                <Col span={24}>
                  <Input name="resident.name" label="入居者の氏名" defaultValue={""} style={{ width: "20em" }} />
                </Col>
                <Col span={24}>
                  <Input
                    name="resident.phoneNumber"
                    label="入居者の連絡先"
                    defaultValue={""}
                    style={{ width: "20em" }}
                  />
                </Col>
                <Col span={24}>
                  <Input name="resident.note" label="入居者についての備考" defaultValue={""} />
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Input name="keyInfo.keyLocation" label="鍵の置き場所" rules={createRules("鍵の置き場所")} />
                </Col>
                <Col span={24}>
                  <KeyLocationUpload projectId={projectId} keyInfo={keyInfo} setIsUploadingFile={setIsUploadingFile} />
                </Col>
                <Col span={24}>
                  <Radio
                    name="project.isElectricityConnected"
                    label="電気の通電状況"
                    rules={radioRequired("電気の通電状況")}
                    options={[
                      { label: "通電あり", value: true },
                      { label: "通電なし", value: false },
                    ]}
                    onChange={(e) => {
                      if (e.target.value) {
                        setValue("project.hasRequestElectricityConnection", false);
                      } else {
                        // 通電なしの場合は職人への依頼をリセット（ラジオボタンはデフォルトで未選択の状態にするため）
                        setValue("project.hasRequestElectricityConnection", null);
                      }
                    }}
                  />
                </Col>
                {values.project.isElectricityConnected === false && (
                  <Col span={24}>
                    <Radio
                      name="project.hasRequestElectricityConnection"
                      label="職人への電気通電依頼"
                      rules={radioRequired("職人への電気通電依頼")}
                      options={[
                        { label: "依頼する", value: true },
                        { label: "依頼しない", value: false },
                      ]}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Radio
                    name="project.isWaterConnected"
                    label="水道の開通状況"
                    rules={radioRequired("水道の開通状況")}
                    options={[
                      { label: "開通あり", value: true },
                      { label: "開通なし", value: false },
                    ]}
                    onChange={(e) => {
                      if (e.target.value) {
                        setValue("project.hasRequestWaterConnection", false);
                      } else {
                        // 開通なしの場合は職人への依頼をリセット（ラジオボタンはデフォルトで未選択の状態にするため）
                        setValue("project.hasRequestWaterConnection", null);
                      }
                    }}
                  />
                </Col>
                {values.project.isWaterConnected === false && (
                  <Col span={24}>
                    <Radio
                      name="project.hasRequestWaterConnection"
                      label="職人への水道開通依頼"
                      rules={radioRequired("職人への水道開通依頼")}
                      options={[
                        { label: "依頼する", value: true },
                        { label: "依頼しない", value: false },
                      ]}
                    />
                  </Col>
                )}
              </>
            )}
            <Col span={24}>
              <Input name="project.projectManager" label="案件の担当者" defaultValue={""} style={{ width: "20em" }} />
            </Col>
            <Col span={24}>
              <Input name="project.freeParkingLocation" label="無償利用が可能な駐車場の場所" defaultValue={""} />
            </Col>
            <Col span={24}>
              <Textarea name="project.ownerRequest" label="依頼者から職人への要望" defaultValue={""} rows={4} />
            </Col>
            <Col span={24}>
              <DatePicker name="project.handoverDate" label="引渡日" placeholder="選択" />
            </Col>
            <Col span={24}>
              <FloorPlanUpload projectId={projectId} project={project} setIsUploadingFile={setIsUploadingFile} />
            </Col>
            <Col span={24}>
              <PropertyPhotoUpload projectId={projectId} project={project} setIsUploadingFile={setIsUploadingFile} />
            </Col>
          </Row>

          <Row gutter={[16, 12]} style={{ marginTop: "36px" }}>
            <Col span={24}>
              <Button type="primary" htmlType="submit" block disabled={isUploadingFile}>
                更新する
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </>
  );
};

export default ProjectForm;
