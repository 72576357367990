import { useState, useEffect } from "react";
import ChatRoomModel from "common/models/ChatRoomModel";
import * as chatRoomService from "@/services/chatRoomService";
import * as workerService from "@/services/workerService";
import messageRepository from "@/repositories/MessageRepository";
import { message } from "antd";

type HookResult = {
  chatRoomList: ChatRoomModel[];
};

const useChatRoomList = (projectId: string): HookResult => {
  const [chatRoomList, setChatRoomList] = useState<ChatRoomModel[]>([]);

  useEffect(() => {
    // チャットルームを取得する（※チャット相手と最新メッセージも取得）
    const getChatRoomList = async () => {
      const chatRoomList = await chatRoomService.fetchChatRoomsByProjectId(projectId);
      try {
        let newChatRoomList: ChatRoomModel[] = [];
        for (const chatRoom of chatRoomList) {
          const workerId = chatRoom.id;

          const [[worker], latestMessage] = await Promise.all([
            workerService.fetchWorkerPublicDetails([workerId]),
            messageRepository.findLatest(projectId, chatRoom.id),
          ]);
          const newChatRoomModel = chatRoom.withAdditionalParams({
            partnerName: worker.displayName,
            latestMessagePreviewText: latestMessage ? latestMessage.previewText : undefined,
            latestMessageSentDate: latestMessage ? latestMessage.sentDate : undefined,
          });
          newChatRoomList.push(newChatRoomModel);
        }
        setChatRoomList(newChatRoomList);
      } catch (error) {
        message.error("チャットデータの取得中にエラーが発生しました");
        console.error("チャットデータの取得中にエラーが発生しました", error);
        setChatRoomList([]);
      }
    };

    getChatRoomList();

    return () => {
      chatRoomList;
    };
  }, [projectId]);

  return { chatRoomList };
};
export default useChatRoomList;
