import React from "react";
import { Modal, message, Row, Col } from "antd";
import Button from "@/components/Button";
import InvoiceModel from "common/models/InvoiceModel";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Input, Textarea, Select } from "@/components/FormItems";
import { updateInvoice } from "./api/updateInvoice";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { DeepPartial } from "@/common/utils/firestoreUtil";

type Props = {
  invoice: InvoiceModel;
  open: boolean;
  handleClose: () => void;
};
type FormData = {
  invoiceNumber: string | null;
  assignee: string | null;
  checker: string | null;
  status: string;
  adminNote: string;
};
/**請求情報編集モーダル */
const InvoiceEditModal: React.FC<Props> = ({ invoice, open, handleClose }) => {
  const methods = useForm<FormData>({
    defaultValues: {
      invoiceNumber: invoice.toJSON().invoiceNumber,
      assignee: invoice.toJSON().assignee,
      checker: invoice.toJSON().checker,
      status: invoice.toJSON().status,
      adminNote: invoice.toJSON().adminNote,
    },
  });
  const { handleSubmit, watch } = methods;
  const values = watch();

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    const update: DeepPartial<DBModel.Invoice> = data;
    // 最初にステータスを「請求済み」に変更した場合のみ、請求日をセット
    if (data.status === "invoiced" && !invoice.invoiceDate) {
      update.invoiceDate = serverTimestamp() as Timestamp;
    }

    try {
      await updateInvoice(invoice.id, update);
      message.success("保存しました。");
      handleClose();
    } catch (err: any) {
      console.log(err.message);
      message.error("保存に失敗しました。");
    }
  };

  return (
    <>
      <Modal centered open={open} onCancel={handleClose} width={500} footer={null}>
        <FormProvider {...methods}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Input name="invoiceNumber" label="請求書番号" />
              <Input name="assignee" label="担当者" />
              <Input name="checker" label="確認者" />
              <Select
                name="status"
                label="ステータス"
                options={[
                  { label: "未請求", value: "uninvoiced" },
                  { label: "請求済み", value: "invoiced" },
                ]}
                style={{ width: "100%" }}
                disabled={!new InvoiceModel(values).canChangeStatusToInvoiced}
              />
              <Textarea name="adminNote" label="管理用メモ" rows={4} />
            </Col>
          </Row>
          <Button type="primary" onClick={handleSubmit(handleFormSubmit)} style={{ marginTop: "20px", width: "100%" }}>
            保存
          </Button>
        </FormProvider>
      </Modal>
    </>
  );
};

export default InvoiceEditModal;
