import React, { useEffect } from "react";
import { Button, Drawer, message, Checkbox } from "antd";
import { createRules } from "@/common/utils/validationUtil";
import Select from "@/components/FormItems/Select";
import { FormProvider, useForm } from "react-hook-form";
import EstimateTable from "./EstimateTable";
import TotalAmountTable from "./TotalAmountTable";
import EstimateListModel from "common/models/EstimateListModel";
import EstimateModel from "common/models/EstimateModel";
import JobType from "common/models/shared/JobType";
import ProjectModel from "common/models/ProjectModel";
import { saveEstimates } from "./api/saveEstimates";
import WorkableDateRangeModal from "../project/WorkableDateRangeModal";
import useModal from "@/hooks/useModal";
import "./EstimateDrawer.scss";

type Props = {
  projectId: string;
  project: ProjectModel;
  estimates: EstimateListModel;
  open: boolean;
  onClose: () => void;
};

export type FormData = {
  project: {
    jobType: DBModel.Project["jobType"];
    hasRequestEstimateProposal: DBModel.Project["hasRequestEstimateProposal"];
  };
  estimates: DBModel.Estimate[];
};

const EstimateDrawer: React.FC<Props> = ({ projectId, project, estimates, open, onClose }) => {
  const drawerTitle = !estimates.isEmpty ? "見積編集" : "見積作成";
  const saveButtonText = project.canRecruit ? "見積を一時保存する" : "見積を更新する";
  const methods = useForm<FormData>({
    defaultValues: {
      project: {
        jobType: project.toJSON().jobType,
        hasRequestEstimateProposal: project.toJSON().hasRequestEstimateProposal || false,
      },
      estimates: [new EstimateModel({ id: `${crypto.randomUUID()}` }).toJSON()],
    },
  });
  const { setValue, watch, control } = methods;
  const values = watch();
  const workableDateRangeModalHook = useModal();

  useEffect(() => {
    if (estimates.isEmpty) {
      return;
    }
    setValue("estimates", estimates.toJSONArray());
  }, [estimates]);

  /**編集中の案件 */
  const editingProject = new ProjectModel({ ...project.toJSON(), ...values.project });
  /**編集中の見積 */
  const editingEstimates = new EstimateListModel(values.estimates.map((estimate) => new EstimateModel(estimate)));

  /** 見積の行を追加する */
  const addRow = () => {
    const addEstimateRow = new EstimateModel({ id: `${crypto.randomUUID()}`, rowNumber: values.estimates.length + 1 });
    setValue("estimates", [...values.estimates, addEstimateRow]);
  };

  /**見積の行を削除する */
  const deleteRow = (deleteRowNumber: number) => {
    const newEstimates = values.estimates.filter((estimate) => estimate.rowNumber !== deleteRowNumber);
    // 行番号を振り直す
    const incrementedEstimates = newEstimates.map((estimate, index) => {
      return { ...estimate, rowNumber: index + 1 };
    });
    setValue("estimates", incrementedEstimates);
  };

  /**見積を保存する */
  const handleSaveEstimates = async (data: FormData) => {
    try {
      await saveEstimates({ projectId, data });
      message.success("見積を保存しました");
      onClose();
    } catch (error: any) {
      console.log(error.message);
      message.error("見積の保存に失敗しました");
    }
  };

  return (
    <>
      <Drawer
        className="EstimateDrawer"
        placement="bottom"
        onClose={onClose}
        open={open}
        height="95%"
        title={drawerTitle}
      >
        <FormProvider {...methods}>
          <div className="EstimateDrawer__form">
            <div
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}
            >
              <Select
                name="project.jobType"
                label="職種"
                options={JobType.values}
                rules={createRules("職種")}
                style={{ width: "20em" }}
              />
              <Button type="primary" ghost onClick={addRow} style={{ top: "16px", width: "16em" }}>
                見積を追加する
              </Button>
            </div>
            <EstimateTable
              estimates={editingEstimates}
              project={editingProject}
              control={control}
              deleteRow={deleteRow}
            />
            <div className="EstimateDrawer__totalAmountTable">
              <TotalAmountTable estimates={editingEstimates} />
            </div>
            <div className="EstimateDrawer__checkbox">
              <Checkbox
                defaultChecked={!!project.hasRequestEstimateProposal}
                onChange={(e) => {
                  setValue("project.hasRequestEstimateProposal", e.target.checked);
                }}
              />
              <div className="EstimateDrawer__checkbox__text">職人からの金額提案を希望する</div>
            </div>
            <div className="EstimateDrawer__buttonContainer">
              <Button
                block
                type="primary"
                ghost={project.canRecruit}
                onClick={async () => await handleSaveEstimates(values)}
                disabled={
                  project.canRecruit
                    ? !editingProject.jobType
                    : !editingProject.jobType || editingProject.hasInvalidEstimates(editingEstimates)
                }
              >
                {saveButtonText}
              </Button>
              {project.canRecruit && (
                <Button
                  block
                  type="primary"
                  onClick={async () => {
                    await handleSaveEstimates(values);
                    workableDateRangeModalHook.handleOpen();
                  }}
                  disabled={!editingProject.jobType || editingProject.hasInvalidEstimates(editingEstimates)}
                >
                  募集する
                </Button>
              )}
            </div>
          </div>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </FormProvider>
      </Drawer>

      <WorkableDateRangeModal project={project} modalHook={workableDateRangeModalHook} />
    </>
  );
};

export default EstimateDrawer;
