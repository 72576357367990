import React from "react";
import { Modal, Spin, message } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Image } from "antd";
import { fetchIdentityVerificationImgUrls } from "./api/fetchIdentityVerifications";
import { deleteIdentityVerifications } from "./api/deleteIdentityVerifications";
import Button from "@/components/Button";
import { verify } from "./api/verify";
import { requestResend } from "./api/requestResend";
import { deny } from "./api/deny";
import "./IdentityVerifyModal.scss";

type Props = {
  workerId: string;
  open: boolean;
  handleClose: () => void;
};
/**本人確認モーダル */
const IdentityVerifyModal: React.FC<Props> = ({ workerId, open, handleClose }) => {
  const { data: identityVerificationImgUrls, isFetching } = useQuery({
    queryKey: ["identityVerificationImgUrls", workerId],
    queryFn: () => fetchIdentityVerificationImgUrls(workerId),
    retry: 0,
    throwOnError: true,
    enabled: !!open, // モーダルが開かれたら取得
    initialData: [],
  });

  /**承認する */
  const handleVerify = async () => {
    if (!confirm("本人確認を承認しますか？\n※承認後、本人確認書類は削除されます。")) {
      return;
    }

    try {
      await verify(workerId);
      await deleteIdentityVerifications(workerId);
      message.success("承認しました。");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      console.log(error.message);
      message.error("承認に失敗しました。");
    }
  };

  /**再送信を依頼する */
  const handleRequestResend = async () => {
    if (!confirm("本人確認書類の再送信を依頼しますか？\n※依頼後、本人確認書類は削除されます。")) {
      return;
    }

    try {
      await requestResend(workerId);
      await deleteIdentityVerifications(workerId);
      message.success("再送信を依頼しました。");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      console.log(error.message);
      message.error("再送信の依頼に失敗しました。");
    }
  };

  /**審査NGにする（退会処分） */
  const handleDeny = async () => {
    if (
      !confirm(
        "審査NGにし、退会させますか？\n※審査NG後、ユーザーは強制退会となります。\n※審査NG後、本人確認書類は削除されます。"
      )
    ) {
      return;
    }

    try {
      await deny(workerId);
      await deleteIdentityVerifications(workerId);
      message.success("審査NGにしました。");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      console.log(error.message);
      message.error("審査NGに失敗しました。");
    }
  };

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={handleClose}
        title={"本人確認"}
        width={1000}
        footer={
          <>
            {identityVerificationImgUrls.length === 0 ? null : (
              <div className="IdentityVerifyModal__buttonContainer">
                <Button type="primary" style={{ width: "30%" }} onClick={handleVerify}>
                  承認
                </Button>
                <Button type="primary" ghost style={{ width: "30%" }} onClick={handleRequestResend}>
                  再送信依頼
                </Button>
                <Button type="primary" danger style={{ width: "30%" }} onClick={handleDeny}>
                  審査NG（退会処分）
                </Button>
              </div>
            )}
          </>
        }
      >
        <Spin spinning={isFetching} tip="Loading...">
          <div className="IdentityVerifyModal__imageContainer">
            {!isFetching && identityVerificationImgUrls.length === 0 ? (
              <div>本人確認書類が登録されていません。</div>
            ) : (
              <>
                {identityVerificationImgUrls.map((url, index) => {
                  return (
                    <div key={index} className="IdentityVerifyModal__imageContainer__image">
                      <Image src={url} height={500} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default IdentityVerifyModal;
