import WorkerModel from "common/models/WorkerModel";
import workerRepository from "@/repositories/WorkerRepository";
import adminWorkerSettingModel from "common/models/AdminWorkerSettingModel";
import AdminWorkerSettingRepository from "@/repositories/AdminWorkerSettingRepository";
import { writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { FormData } from "@/features/admin/worker/WorkerForm";

/**職人の情報を更新します */
export async function updateWorker(workerId: string, data: FormData): Promise<void> {
  const batch = writeBatch(db);

  const worker = new WorkerModel(data.worker);
  await workerRepository.update({ id: workerId, data: worker.toJSON(), writeBatch: batch });

  // 管理用の職人の情報も更新
  const adminWorkerSetting = new adminWorkerSettingModel(data.adminWorkerSetting);
  await AdminWorkerSettingRepository.upsert({ id: workerId, data: adminWorkerSetting.toJSON(), writeBatch: batch });

  await batch.commit();
}
