import { DBModel } from "../@types/global";
import { formatCurrency } from "../utils/textUtil";
import Big from "big.js";

export default class ProposedEstimateModel {
  public readonly id: string;
  public readonly itemName: string; // 項目名
  public readonly quantity: number; // 数量
  public readonly remarks: string; // 備考
  public readonly rowNumber: number; // 行番号
  public readonly unit: string; // 単位
  public readonly unitPrice: number; // 単価（税抜）

  constructor(data: Partial<DBModel.ProposedEstimate>) {
    const defaultValues = {
      id: "",
      itemName: "",
      quantity: 0,
      remarks: "",
      rowNumber: 1,
      unit: "",
      unitPrice: 0,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.itemName = mergedData.itemName;
    this.quantity = mergedData.quantity;
    this.remarks = mergedData.remarks;
    this.rowNumber = mergedData.rowNumber;
    this.unit = mergedData.unit;
    this.unitPrice = mergedData.unitPrice;
  }

  get unitPriceFormatted(): string {
    return formatCurrency(this.unitPrice);
  }

  /**金額（税抜）※小数点切り捨て */
  get amount(): number {
    return Math.floor(Number(Big(this.quantity).times(this.unitPrice)));
  }

  get amountFormatted(): string {
    return formatCurrency(this.amount);
  }

  toJSON(): DBModel.ProposedEstimate {
    return {
      itemName: this.itemName,
      quantity: this.quantity,
      remarks: this.remarks,
      rowNumber: this.rowNumber,
      unit: this.unit,
      unitPrice: this.unitPrice,
    };
  }
}
