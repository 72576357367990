import { Avatar, Card, Rate, Tooltip } from "antd";
import { UserOutlined, MessageOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import PublicWorkerDetailModel from "common/models/PublicWorkerDetailModel";
import { useQuery } from "@tanstack/react-query";
import { JobTypeTag } from "@/components/JobTypeTag";
import Button from "@/components/Button";
import { fetchWorkingHistories } from "./api/fetchWorkingHistories";
import * as workerService from "@/services/workerService";
import ChatRoomListDrawer from "@/features/chat/ChatDrawer";
import useModal from "@/hooks/useModal";
import ProjectModel from "common/models/ProjectModel";
import "./WorkerCard.scss";

type Props = {
  project: ProjectModel;
  worker: PublicWorkerDetailModel;
  actions?: JSX.Element[];
  children?: React.ReactNode;
};

/**職人カード */
const WorkerCard = ({ project, worker, actions, children }: Props) => {
  if (!project.id) {
    throw new Error(`project.id is required.`);
  }

  const { data: profileImgUrl } = useQuery({
    queryKey: ["profileImgUrl", worker.id],
    queryFn: () => workerService.fetchProfileImgUrl(worker.profileImageStoragePath!),
    retry: 0,
    enabled: !!worker.profileImageStoragePath,
    initialData: "",
  });
  const { data: workingHistories } = useQuery({
    queryKey: ["workingHistories", worker.id],
    queryFn: () => fetchWorkingHistories(worker.id),
    retry: 0,
    enabled: !!worker.id,
    initialData: [],
  });
  const { open, handleOpen, handleClose } = useModal();

  /**合計施行件数（旧リモデラPROでの施工件数 + 現リモデラPROでの施工件数） */
  const totalWorkCount = worker.oldPROWorkCount + workingHistories.length;

  return (
    <>
      <Card className="WorkerCard" actions={actions}>
        <Meta
          avatar={<Avatar src={profileImgUrl} icon={<UserOutlined />} />}
          title={
            <div className="WorkerCard__title">
              <div className="WorkerCard__title__name">{worker.displayName}</div>
              <Tooltip title="職人とのチャット">
                <Button type="link" onClick={handleOpen} className="WorkerCard__title__chat__button">
                  <MessageOutlined className="WorkerCard__title__chat__icon" />
                </Button>
              </Tooltip>
            </div>
          }
          description={
            <>
              <div className="WorkerCard__type">
                {worker.businessTypeLabel} / {worker.invoiceLabel}
              </div>
              <div className="WorkerCard__rate">
                <Rate allowHalf defaultValue={worker.rating} disabled className="WorkerCard__rate__star" />
                <span className="WorkerCard__rate__number">{worker.rating}</span>
                <span className="WorkerCard__rate__workCount">
                  {/* 施工件数5件未満の場合は「5件未満」で統一 */}
                  施工件数: {totalWorkCount < 5 ? "5 件未満" : `${totalWorkCount} 件`}
                </span>
              </div>
              <div className="WorkerCard__jobTypes">
                {worker.jobTypes.value.map((jobType: string) => (
                  <JobTypeTag key={jobType} jobType={jobType} />
                ))}
              </div>
            </>
          }
        />
        {children}
      </Card>

      <ChatRoomListDrawer project={project} open={open} onClose={handleClose} openChatRoomId={worker.id} />
    </>
  );
};

export default WorkerCard;
