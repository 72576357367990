import { Input, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import WorkerModel from "common/models/WorkerModel";
import { Link } from "react-router-dom";
import { useWorkers } from "./hooks/useWorkers";
import JobType from "common/models/shared/JobType";
import WorkerStatus from "common/models/worker/Status";
import WorkerIdentityVerifyStatus from "common/models/worker/IdentityVerifyStatus";
import { PREFECTURE_LIST } from "common/constant";
import { SelectProps } from "antd/lib";
import "./WorkerList.scss";

/**職人一覧 */
const WorkerList: React.FC = () => {
  const {
    isLoading,
    filteredWorkers,
    setInputNameOrCompanyName,
    setSelectedJobType,
    setSelectedStatuses,
    setSelectedIdentityVerifyStatuses,
    setSelectedPrefecture,
  } = useWorkers();

  const jobTypeOptions: SelectProps["options"] = JobType.values;
  const statusOptions: SelectProps["options"] = WorkerStatus.values;
  const identityVerifyStatusOptions: SelectProps["options"] = WorkerIdentityVerifyStatus.values;
  const prefecrureOptions: SelectProps["options"] = PREFECTURE_LIST.map((prefecture) => ({
    label: prefecture,
    value: prefecture,
  }));

  const columns: ColumnsType<WorkerModel> = [
    {
      title: "登録日",
      dataIndex: "createdAt",
      className: "WorkerList__col__createdAt",
      width: 90,
      render: (_, worker) => <div>{worker.createdAt.formatted("yyyy/MM/dd")}</div>,
    },
    {
      title: "タイプ",
      dataIndex: "businessType",
      className: "WorkerList__col__businessType",
      width: 50,
      render: (_, worker) => <div>{worker.businessTypeLabel}</div>,
    },
    {
      title: "会社名",
      dataIndex: "companyName",
      className: "WorkerList__col__companyName",
      width: 200,
      render: (_, worker) => <div>{worker.companyName}</div>,
    },
    {
      title: "氏名",
      dataIndex: "fullName",
      className: "WorkerList__col__fullName",
      width: 120,
      render: (_, worker) => <Link to={`/admin/workers/${worker.id}`}>{worker.fullName}</Link>,
    },
    {
      title: "職種",
      dataIndex: "jobTypes",
      className: "WorkerList__col__jobTypes",
      width: 200,
      render: (_, worker) => <div style={{ overflow: "auto", maxHeight: "40px" }}>{worker.jobTypes.toString()}</div>,
    },
    {
      title: "ステータス",
      dataIndex: "status",
      className: "WorkerList__col__status",
      width: 90,
      render: (_, worker) => <div>{worker.status.label}</div>,
    },
    {
      title: "本人確認",
      dataIndex: "identityVerifyStatus",
      className: "WorkerList__col__identityVerifyStatus",
      width: 70,
      render: (_, worker) => <div>{worker.identityVerifyStatus.label}</div>,
    },
    {
      title: "評価",
      dataIndex: "rating",
      className: "WorkerList__col__rating",
      width: 50,
      render: (_, worker) => <div>{worker.ratingFormatted}</div>,
    },
    {
      title: "電話番号",
      dataIndex: "phoneNumber",
      className: "WorkerList__col__phoneNumber",
      width: 100,
      render: (_, worker) => <div>{worker.phoneNumber}</div>,
    },
    {
      title: "住所",
      dataIndex: "address",
      className: "WorkerList__col__address",
      width: 200,
      render: (_, worker) => <div style={{ overflow: "auto", maxHeight: "40px" }}>{worker.address.formatted}</div>,
    },
  ];

  return (
    <>
      <div className="WorkerList__filterContainer">
        <Input
          placeholder="氏名 or 会社名"
          allowClear
          onChange={(e) => setInputNameOrCompanyName(e.target.value)}
          className="WorkerList__filterContainer__form"
        />
        <Select
          placeholder="職種"
          optionLabelProp="label"
          allowClear
          onChange={(v: string) => setSelectedJobType(v || "")}
          options={jobTypeOptions}
          className="WorkerList__filterContainer__form"
        />
        <Select
          mode="multiple"
          placeholder="ステータス"
          optionLabelProp="label"
          allowClear
          onChange={(v: string[]) => setSelectedStatuses(v || [])}
          options={statusOptions}
          optionRender={(option) => <Space>{option.data.label}</Space>}
          className="WorkerList__filterContainer__form"
        />
        <Select
          mode="multiple"
          placeholder="本人確認"
          optionLabelProp="label"
          allowClear
          onChange={(v: string[]) => setSelectedIdentityVerifyStatuses(v || [])}
          options={identityVerifyStatusOptions}
          optionRender={(option) => <Space>{option.data.label}</Space>}
          className="WorkerList__filterContainer__form"
        />
        <Select
          placeholder="都道府県"
          optionLabelProp="label"
          allowClear
          showSearch={true}
          onChange={(v: string) => setSelectedPrefecture(v || "")}
          options={prefecrureOptions}
          className="WorkerList__filterContainer__form"
        />
      </div>

      <Table
        className="WorkerList__table"
        size="small"
        loading={isLoading}
        pagination={false}
        columns={columns}
        dataSource={filteredWorkers}
        scroll={{ y: 540 }}
        footer={() => `合計: ${filteredWorkers?.length || 0}件`}
        rowKey="id"
      />
    </>
  );
};

export default WorkerList;
