import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useInvoiceDetailLineList } from "./hooks/useInvoiceDetailLineList";
import InvoiceDetailLineModel from "common/models/InvoiceDetailLineModel";
import "./InvoiceDetailLineList.scss";

type Props = {
  invoiceId: string;
};
/**明細一覧 */
const InvoiceDetailLineList: React.FC<Props> = ({ invoiceId }: Props) => {
  const { isLoading, invoiceDetailLineList } = useInvoiceDetailLineList(invoiceId);

  const columns: ColumnsType<InvoiceDetailLineModel> = [
    {
      title: "取引日",
      dataIndex: "salesDate",
      width: 25,
      render: (_, invoiceDetailLine) => <div>{invoiceDetailLine.salesDate.formatted("yyyy-MM-dd")}</div>,
    },
    {
      title: "摘要",
      dataIndex: "description",
      width: 100,
      render: (_, invoiceDetailLine) => <div>{invoiceDetailLine.description}</div>,
    },
    {
      title: "数量",
      dataIndex: "unitPrice",
      width: 20,
      render: (_, invoiceDetailLine) => (
        <div>
          {invoiceDetailLine.quantity} {invoiceDetailLine.unit}
        </div>
      ),
    },
    {
      title: "単価",
      dataIndex: "unitPrice",
      width: 20,
      render: (_, invoiceDetailLine) => <div>{invoiceDetailLine.unitPriceFormatted}</div>,
    },
    {
      title: "明細金額",
      dataIndex: "amount",
      width: 20,
      render: (_, invoiceDetailLine) => <div>{invoiceDetailLine.amountFormatted}</div>,
    },
    {
      title: "備考",
      dataIndex: "note",
      width: 100,
      render: (_, invoiceDetailLine) => <div>{invoiceDetailLine.note}</div>,
    },
  ];

  return (
    <>
      <div className="InvoiceDetailLineList">
        <div className="InvoiceDetailLineList__container">
          <h3 className="InvoiceDetailLineList__container__title">明細</h3>
          <Table
            size="small"
            className="InvoiceDetailLineList__container__table"
            pagination={false}
            loading={isLoading}
            columns={columns}
            dataSource={invoiceDetailLineList}
            scroll={{ y: 540 }}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceDetailLineList;
