import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Input, InputPassword } from "@/components/FormItems";
import Button from "@/components/Button";
import { Row, Col, message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/lib/firebase";
import { useNavigate } from "react-router-dom";

type SigninItem = {
  email: string;
  password: string;
};
/**サインインページ（管理画面） */
export default function AdminSignIn(): JSX.Element {
  const navigate = useNavigate();
  const methods = useForm<SigninItem>();
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<SigninItem> = async (data) => {
    const { email, password } = data;
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idTokenResult = await userCredential.user.getIdTokenResult();
      if (idTokenResult.claims.isAdmin) {
        message.info("サインインに成功しました。");
        navigate("/admin/workers");
      } else {
        message.error("アクセス権限がありません。");
        auth.signOut();
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <div style={{ padding: "24px" }}>
      <h1>Admin Signin</h1>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <Input name="email" type="email" label="メールアドレス" />
            </Col>
            <Col span={24}>
              <InputPassword name="password" label="パスワード" />
            </Col>
          </Row>
          <div style={{ marginTop: "36px" }}></div>
          <Button type="primary" htmlType="submit" block>
            サインイン
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
