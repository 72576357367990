import { useParams } from "react-router-dom";
import useWorker from "@/hooks/useWorker";
import WorkerHeader from "@/features/admin/worker/WorkerHeader";
import WorkerForm, { FormData } from "@/features/admin/worker/WorkerForm";
import { SubmitHandler } from "react-hook-form";
import { message } from "antd";
import { updateWorker } from "@/features/admin/worker/api/updateWorker";
import useModal from "@/hooks/useModal";
import IdentityVerifyModal from "@/features/admin/worker/IdentityVerifyModal";
import { useLoading } from "@/context/LoadingContext";
import useAdminWorkerSetting from "@/hooks/useAdminWorkerSetting";

/**職人詳細ページ（管理画面） */
export default function AdminWorkerId(): JSX.Element {
  const { open, handleOpen, handleClose } = useModal();
  const { startLoading, stopLoading } = useLoading();
  const { workerId } = useParams<{ workerId: string }>();
  if (!workerId) {
    throw new Error(`workerId is required.`);
  }
  const { worker } = useWorker(workerId);
  const { adminWorkerSetting } = useAdminWorkerSetting(workerId);

  if (!worker || !adminWorkerSetting) {
    return <></>;
  }

  const handleFormSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    try {
      startLoading("更新中");
      await updateWorker(workerId, data);
      stopLoading();
      message.success("更新しました。");
    } catch (err: any) {
      console.log(err.message);
      message.error("更新に失敗しました。");
    }
  };

  return (
    <div style={{ padding: "24px" }}>
      <WorkerHeader worker={worker} />
      <div style={{ width: "60%", padding: "24px 24px 0", margin: "0 auto" }}>
        <WorkerForm
          worker={worker}
          adminWorkerSetting={adminWorkerSetting}
          onSubmit={handleFormSubmit}
          handleOpen={handleOpen}
        />
      </div>

      <IdentityVerifyModal workerId={worker.id} open={open} handleClose={handleClose} />
    </div>
  );
}
