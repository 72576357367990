import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
  functionRegion: process.env.REACT_APP_functionRegion,
};

const firebase = initializeApp(config);
initializeFirestore(firebase, {
  ignoreUndefinedProperties: true,
});
const db = getFirestore(firebase);
const auth = getAuth(firebase);
setPersistence(auth, browserSessionPersistence);
const storage = getStorage(firebase);
const functions = getFunctions(firebase, config.functionRegion);
auth.languageCode = "ja";

export { firebase, db, auth, storage, functions };
