import { useEffect, useState } from "react";
import ProjectModel from "common/models/ProjectModel";
import projectRepository from "@/repositories/ProjectRepository";

type ProjectHookResult = {
  project: ProjectModel | null;
};

const useProject = (projectId: string): ProjectHookResult => {
  const [project, setProject] = useState<ProjectModel | null>(null);

  useEffect(() => {
    const unsubscribe = projectRepository.subscribeByProjectId(projectId, (project) => {
      setProject(project);
    });

    return () => {
      unsubscribe();
    };
  }, [projectId]);

  return { project };
};

export default useProject;
