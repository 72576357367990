import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ProposedEstimateModel from "common/models/ProposedEstimateModel";
import "./ProposedEstimateTable.scss";

type Props = {
  proposedEstimates: ProposedEstimateListModel;
  changedEstimateIds: string[];
};
/**提案された見積内容テーブル */
const ProposedEstimateTable: React.FC<Props> = ({ proposedEstimates, changedEstimateIds }) => {
  const columns: ColumnsType<ProposedEstimateModel> = [
    {
      title: "項目",
      dataIndex: "itemName",
      width: "50%",
      render: (_, proposedEstimate) => (
        <div className="ProposedEstimateTable__col__itemName">{proposedEstimate.itemName}</div>
      ),
    },
    {
      title: "数量",
      dataIndex: "quantity",
      width: "25%",
      render: (_, proposedEstimate) => (
        <div className="ProposedEstimateTable__col__quantity">
          {proposedEstimate.quantity}
          {proposedEstimate.unit}
        </div>
      ),
    },
    {
      title: "単価",
      dataIndex: "unitPrice",
      width: "25%",
      render: (_, proposedEstimate) => (
        <div className="ProposedEstimateTable__col__unitPrice">{proposedEstimate.unitPriceFormatted}</div>
      ),
    },
  ];

  return (
    <>
      <Table
        className="ProposedEstimateTable"
        pagination={false}
        columns={columns}
        dataSource={proposedEstimates.value}
        rowKey="id"
        rowClassName={(proposedEstimate) => {
          if (changedEstimateIds.includes(proposedEstimate.id)) {
            return "ProposedEstimateTable__row__changed";
          }
          return "";
        }}
      />
    </>
  );
};

export default ProposedEstimateTable;
