import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";
import "./FormItem.scss";

type FormItemProps = {
  name: string;
  label?: string;
  defaultValue?: any;
  rules?: any;
  component: React.ComponentType<any>;
};

const getNestedError = (errors: any, path: any) => {
  return path.split(".").reduce((obj: any, key: string) => obj && obj[key], errors);
};

const FormItem: React.FC<FormItemProps> = ({
  name,
  defaultValue = null,
  label,
  rules,
  component: Component,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = getNestedError(errors, name);

  return (
    <div
      className={classNames("FormItem", {
        "FormItem--required": !!rules?.required || !!rules?.validate,
        "FormItem--labelless": !label,
      })}
    >
      {label && (
        <label htmlFor={name} className="FormItem__label">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => <Component field={field} {...field} {...props} status={error ? "error" : ""} />}
      />
      {error?.message && (
        <>
          <p className="errorMessage">{String(error.message)}</p>
          <div className="errorMessage__offset" />
        </>
      )}
    </div>
  );
};

export default FormItem;
