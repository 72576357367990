import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import EstimateListModel from "common/models/EstimateListModel";
import "./TotalAmountTable.scss";

type Props = {
  estimates: EstimateListModel;
};

const totalAmountTable = ({ estimates }: Props) => {
  const columns: ColumnsType<any> = [
    {
      dataIndex: "header",
      width: 160,
      rowScope: "row",
      align: "center",
      className: "TotalAmountTable__header",
    },
    {
      dataIndex: "value",
      width: 160,
      render: (v) => <div style={{ textAlign: "right", paddingRight: 8 }}>{v}</div>,
    },
  ];

  const dataSource = [
    {
      key: "1",
      header: "合計金額（税別）",
      value: estimates.totalAmountFormatted,
    },
    {
      key: "2",
      header: "合計金額",
      value: estimates.totalAmountIncludeTaxFormatted,
    },
  ];

  return (
    <div style={{ width: "360px" }}>
      <Table
        className="TotalAmountTable"
        showHeader={false}
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default totalAmountTable;
