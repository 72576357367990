const createRules = (label: string, minLength?: number, maxLength?: number) => ({
  required: `${label}の入力は必須です。`,
  minLength: minLength
    ? {
        value: minLength,
        message: `${label}は${minLength}文字以上で入力してください。`,
      }
    : undefined,
  maxLength: maxLength
    ? {
        value: maxLength,
        message: `${label}は${maxLength}文字以下で入力してください。`,
      }
    : undefined,
});

const radioRequired = (label: string) => ({
  validate: (value: string) => {
    if (typeof value !== "boolean") {
      return `${label}の選択は必須です。`;
    }
  },
});

export { createRules, radioRequired };
