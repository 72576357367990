import { List } from "antd";
import useChatRoomList from "@/hooks/useChatRoomList";
import "./ChatRoomList.scss";

type Props = {
  projectId: string;
  setChatRoomId: (chatRoomId: string) => void;
};

const ChatRoomList: React.FC<Props> = ({ projectId, setChatRoomId }) => {
  const { chatRoomList } = useChatRoomList(projectId);

  return (
    <List
      dataSource={chatRoomList}
      className="ChatRoomList"
      loading={!chatRoomList}
      size="large"
      locale={{ emptyText: "チャットデータがありません" }}
      renderItem={(chatRoom) => (
        <List.Item
          key={chatRoom.id}
          className="ChatRoomList__item"
          onClick={() => {
            setChatRoomId(chatRoom.id);
          }}
        >
          <List.Item.Meta title={chatRoom.partnerName} description={chatRoom.latestMessagePreviewText || ""} />
          {!!chatRoom.latestMessagePreviewText && <div>{chatRoom.latestMessageSentDate?.formatted()}</div>}
        </List.Item>
      )}
    />
  );
};

export default ChatRoomList;
