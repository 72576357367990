import { DBModel } from "../@types/global";
import Address from "./shared/Address";
import JobTypes from "./shared/JobTypes";
import CustomDateTime from "./shared/CustomDateTime";
import PublicWorkerDetailModel from "./PublicWorkerDetailModel";
import WorkerStatus from "./worker/Status";
import WorkerIdentityVerifyStatus from "./worker/IdentityVerifyStatus";

export default class WorkerModel extends PublicWorkerDetailModel {
  public readonly id: string;
  public readonly accountName: string; // アカウント名
  public readonly address: Address; // 住所
  public readonly badgeCount: number; // 未読チャット数＋未読お知らせ数
  public readonly bankAccountIds: string[] | null; // 銀行口座IDリスト
  public readonly birthDate: string; // 生年月日
  public readonly businessType: DBModel.Worker["businessType"]; // 個人か企業かの区分
  public readonly companyName: string | null; // 会社名
  public readonly companyNumber: string | null; // 法人番号
  public readonly fcmToken: string; // FCMトークン
  public readonly firstName: string; // 名
  public readonly gender: string; // 性別
  public readonly identityVerifyStatus: WorkerIdentityVerifyStatus; // 本人確認ステータス
  public readonly invoiceNumber: string | null; // インボイス番号
  public readonly jobTypes: JobTypes; // 職種
  public readonly lastName: string; // 姓
  public readonly oldPROWorkCount: number; // 旧リモデラPROでの施工件数
  public readonly phoneNumber: string; // 電話番号
  public readonly profileImageStoragePath: string | null; // プロフィール画像のstorageパス
  public readonly rating: number; // 評価
  public readonly status: WorkerStatus; // ステータス

  public readonly createdAt: CustomDateTime; // 作成日時

  constructor(data: Partial<DBModel.Worker>) {
    super(data);
    const defaultValues = {
      id: "",
      accountName: "",
      address: {
        postalCode: "",
        prefecture: "",
        city: "",
        streetNumber: "",
        extraAddress: "",
      },
      badgeCount: 0,
      bankAccountIds: null,
      birthDate: "",
      businessType: "",
      companyName: null,
      companyNumber: null,
      fcmToken: "",
      firstName: "",
      gender: "",
      identityVerifyStatus: WorkerIdentityVerifyStatus.Nothing.value,
      invoiceNumber: null,
      jobTypes: [],
      lastName: "",
      oldPROWorkCount: 0,
      phoneNumber: "",
      profileImageStoragePath: null,
      rating: 0,
      status: WorkerStatus.Nothing.value,

      createdAt: undefined,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.accountName = mergedData.accountName;
    this.address = new Address(mergedData.address);
    this.badgeCount = mergedData.badgeCount;
    this.bankAccountIds = mergedData.bankAccountIds;
    this.birthDate = mergedData.birthDate;
    this.businessType = mergedData.businessType;
    this.companyName = mergedData.companyName;
    this.companyNumber = mergedData.companyNumber;
    this.fcmToken = mergedData.fcmToken;
    this.firstName = mergedData.firstName;
    this.gender = mergedData.gender;
    this.identityVerifyStatus = WorkerIdentityVerifyStatus.fromValue(mergedData.identityVerifyStatus);
    this.invoiceNumber = mergedData.invoiceNumber;
    this.jobTypes = new JobTypes(mergedData.jobTypes);
    this.lastName = mergedData.lastName;
    this.oldPROWorkCount = mergedData.oldPROWorkCount || 0;
    this.phoneNumber = mergedData.phoneNumber;
    this.profileImageStoragePath = mergedData.profileImageStoragePath;
    this.rating = mergedData.rating;
    this.status = WorkerStatus.fromValue(mergedData.status);

    this.createdAt = new CustomDateTime(mergedData.createdAt);
  }

  /**本人確認が必要である */
  get needIdentityVerify(): boolean {
    return this.identityVerifyStatus.value === WorkerIdentityVerifyStatus.Verifying.value;
  }

  toJSON(): DBModel.Worker {
    return {
      accountName: this.accountName,
      address: this.address.value,
      badgeCount: this.badgeCount,
      bankAccountIds: this.bankAccountIds,
      birthDate: this.birthDate,
      businessType: this.businessType,
      companyName: this.companyName,
      companyNumber: this.companyNumber,
      fcmToken: this.fcmToken,
      firstName: this.firstName,
      gender: this.gender,
      identityVerifyStatus: this.identityVerifyStatus.value,
      invoiceNumber: this.invoiceNumber,
      jobTypes: this.jobTypes.value,
      lastName: this.lastName,
      oldPROWorkCount: this.oldPROWorkCount,
      phoneNumber: this.phoneNumber,
      profileImageStoragePath: this.profileImageStoragePath,
      rating: this.rating,
      status: this.status.value,
    };
  }
}
