import * as fileRepository from "@/repositories/fileRepository";
import workerRepository from "@/repositories/WorkerRepository";
import WorkerModel from "common/models/WorkerModel";
import PublicWorkerDetailModel from "common/models/PublicWorkerDetailModel";
import functionsRepository from "@/repositories/functionsRepository";

/**職人のプロフィール画像のURLを取得する */
export async function fetchProfileImgUrl(storagePath: string): Promise<string> {
  const blobs = await fileRepository.fetchBlobs([storagePath]);
  return URL.createObjectURL(blobs[0]);
}

/**職人を取得します */
export async function fetchWorker(workerId: string): Promise<WorkerModel | null> {
  const worker = workerRepository.findById(workerId);
  return worker;
}

/**職人の公開可能情報を取得します */
export async function fetchWorkerPublicDetails(workerIds: string[]): Promise<PublicWorkerDetailModel[]> {
  const res = await functionsRepository.httpsCallable("fetchWorkerPublicDetails", { workerIds });
  const data = res.data as DBModel.WorkerPublicDetail[];
  if (!data || data.length === 0) {
    return [];
  }
  const workers = data.map((worker) => new PublicWorkerDetailModel(worker));
  return workers;
}
