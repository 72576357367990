import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { ModalHook } from "@/hooks/useModal";
import { fetchWithdrawalQuestionnaire } from "./api/fetchWithdrawalQuestionnaire";
import WithdrawalQuestionnaireModel from "common/models/WithdrawalQuestionnaireModel";

type Props = {
  projectId: string;
  modalHook: ModalHook;
};
/**取り下げアンケート結果モーダル */
const WithdrawalQuestionnaireResultModal: React.FC<Props> = ({ projectId, modalHook }) => {
  const [withdrawalQuestionnaire, setWithdrawalQuestionnaire] = useState<WithdrawalQuestionnaireModel | null>();

  useEffect(() => {
    const fetchData = async () => {
      const withdrawalQuestionnaire = await fetchWithdrawalQuestionnaire(projectId);
      setWithdrawalQuestionnaire(withdrawalQuestionnaire);
    };

    if (modalHook.open) {
      fetchData();
    }
  }, [modalHook.open]);

  return (
    <Modal
      centered
      open={modalHook.open}
      onCancel={modalHook.handleClose}
      title={"取り下げアンケート結果"}
      width={800}
      footer={null}
    >
      {withdrawalQuestionnaire && (
        <>
          <div style={{ marginBottom: "12px" }}>
            <h4 style={{ margin: " 0" }}>取り下げ理由</h4>
            <div>{withdrawalQuestionnaire.reasonsText}</div>
          </div>
          {withdrawalQuestionnaire.isSelectedOther && (
            <div style={{ marginBottom: "12px" }}>
              <h4 style={{ margin: "0" }}>その他（自由記入）の内容</h4>
              <div>{withdrawalQuestionnaire.otherReason}</div>
            </div>
          )}
          <div style={{ marginBottom: "12px" }}>
            <h4 style={{ margin: "0" }}>ご意見</h4>
            <div>{withdrawalQuestionnaire.opinion || ""}</div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default WithdrawalQuestionnaireResultModal;
