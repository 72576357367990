import { useNavigate } from "react-router-dom";
import ProjectModel from "common/models/ProjectModel";
import { StatusTag } from "@/components/StatusTag";
import Button from "@/components/Button";
import { message, Tooltip } from "antd";
import EstimateListModel from "common/models/EstimateListModel";
import { DownloadOutlined, LeftOutlined, EyeOutlined } from "@ant-design/icons";
import { fetchImageUrl } from "./api/fetchImageUrl";
import useModal from "@/hooks/useModal";
import "dayjs/locale/ja";
import WorkableDateRangeModal from "./WorkableDateRangeModal";
import useAnalytics from "@/hooks/useAnalytics";
import WithdrawProjectModal from "./WithdrawProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import "./ProjectHeader.scss";

type Props = {
  project: ProjectModel;
  estimates: EstimateListModel;
};

export default function ProjectHeader({ project, estimates }: Props) {
  const navigate = useNavigate();
  const workableDateRangeModalHook = useModal();
  const withdrawProjectModalHook = useModal();
  const deleteProjectModalHook = useModal();
  const { analytics } = useAnalytics(project.id);

  /**発注書・発注請書をダウンロードします */
  const downloadOrderDocument = async () => {
    if (!project.orderDocumentStoragePath) {
      message.error("発注書がありません。");
      return;
    }

    try {
      const downloadUrl = await fetchImageUrl(project.orderDocumentStoragePath);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${project.projectName}発注書・発注請書.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  /**リモデラの工事一覧に遷移します */
  const toRemodelaProjectsPage = () => {
    const url = `${process.env.REACT_APP_REMODELA_BASE_URL}/projects`;
    window.location.href = url;
  };

  return (
    <>
      <header className="ProjectHeader">
        <div className="ProjectHeader__leftContainer">
          <Button type="link" onClick={toRemodelaProjectsPage} className="ProjectHeader__leftContainer__icon">
            <LeftOutlined />
          </Button>
          <div className="ProjectHeader__leftContainer__title">
            <h1 className="ProjectHeader__leftContainer__title__projectName">{project.projectName}</h1>
            <StatusTag status={project.status} className="ProjectHeader__leftContainer__title__status" />
          </div>
          {analytics && (
            <Tooltip title="職人の閲覧数">
              <div className="ProjectHeader__leftContainer__workerViewCount">
                <EyeOutlined className="ProjectHeader__leftContainer__workerViewCount__icon" />
                <span className="ProjectHeader__leftContainer__workerViewCount__number">
                  {analytics.detailPageProUserViewCount}
                </span>
              </div>
            </Tooltip>
          )}
          <div className="ProjectHeader__leftContainer__messageContainer">
            {project.publicationStatusText && <div>{project.publicationStatusText}</div>}
            {project.workerId && project.canOrder && <div>※ 応募者の情報を確認してください</div>}
          </div>
        </div>

        <div className="ProjectHeader__action">
          {project.hasOrderDocument && (
            <Button type="primary" ghost onClick={downloadOrderDocument} className="ProjectHeader__action__downloadBtn">
              発注書・発注請書
              <DownloadOutlined />
            </Button>
          )}
          {project.canDelete && (
            <Button type="primary" danger ghost onClick={deleteProjectModalHook.handleOpen}>
              削除する
            </Button>
          )}
          {project.canWithdraw && (
            <Button type="primary" danger ghost onClick={withdrawProjectModalHook.handleOpen}>
              取り下げる
            </Button>
          )}
          {project.canEdit && (
            <Button type="primary" ghost onClick={() => navigate(`/projects/${project.id}/edit`)}>
              編集する
            </Button>
          )}
          {project.canRecruit && (
            <Tooltip title={estimates.isEmpty && "見積が作成されていません"}>
              <Button
                type="primary"
                onClick={workableDateRangeModalHook.handleOpen}
                disabled={project.hasInvalidEstimates(estimates)}
              >
                募集する
              </Button>
            </Tooltip>
          )}
          {project.canInspection && (
            <Button type="primary" onClick={() => navigate(`/projects/${project.id}/inspection`)}>
              検収する
            </Button>
          )}
          {project.isCompleted && (
            <Button type="primary" onClick={() => navigate(`/projects/${project.id}/constructionImages`)}>
              工事写真を確認する
            </Button>
          )}
        </div>
      </header>

      <WorkableDateRangeModal project={project} modalHook={workableDateRangeModalHook} />
      <DeleteProjectModal project={project} modalHook={deleteProjectModalHook} />
      <WithdrawProjectModal project={project} modalHook={withdrawProjectModalHook} />
    </>
  );
}
