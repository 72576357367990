import { Firestore, doc, collection, query, onSnapshot, Unsubscribe, getDocs, Query } from "firebase/firestore";
import ChatRoomModel from "common/models/ChatRoomModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "chatRooms";

type UpdateParams = {
  projectId: string;
} & UpdateBaseParams<DBModel.ChatRoom>;

export class ChatRoomRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string, id: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findAll(projectId: string): Promise<ChatRoomModel[]> {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    return this.findBy(q);
  }

  private async findBy(q: Query): Promise<ChatRoomModel[]> {
    const querySnapshot = await getDocs(q);
    const chatRooms: ChatRoomModel[] = [];
    querySnapshot.forEach((doc) => {
      const chatRoom = { ...doc.data(), id: doc.id } as DBModel.ChatRoom;
      chatRooms.push(new ChatRoomModel(chatRoom));
    });
    return chatRooms;
  }

  subscribeDocumentByChatRoomId(
    projectId: string,
    id: string,
    callback: (chatRoom: ChatRoomModel) => void
  ): Unsubscribe {
    const docRef = this.getDocRef(projectId, id);
    return onSnapshot(docRef, (doc) => {
      const chatRoom = { ...doc.data(), id: doc.id } as DBModel.ChatRoom;
      callback(new ChatRoomModel(chatRoom));
    });
  }

  async update({ projectId, id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateParams) {
    if (!projectId) {
      throw new Error("ProjectId is required");
    }
    if (!id) {
      throw new Error("ChatRoom id is required");
    }
    const docRef = this.getDocRef(projectId, id);
    const updateChatRoom = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateChatRoom, writeBatch);
  }
}

const chatRoomRepository = new ChatRoomRepository(db);
export default chatRoomRepository;
