import projectRepository from "@/repositories/ProjectRepository";
import Status from "common/models/project/Status";
import { FormData } from "@/features/project/WithdrawalQuestionnaireModal";
import withdrawalQuestionnaireRepository from "@/repositories/WithdrawalQuestionnaireRepository";
import { writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";

/**取り下げアンケートを送信し、案件を取り下げます */
export async function withdrawProject(projectId: string, data: FormData): Promise<void> {
  const batch = writeBatch(db);

  const updateProject = {
    status: Status.Withdrawn.value,
  };
  await projectRepository.update({ id: projectId, data: updateProject, writeBatch: batch });

  await withdrawalQuestionnaireRepository.create({
    projectId,
    data: data.withdrawalQuestionnaire,
    writeBatch: batch,
  });

  await batch.commit();
}
