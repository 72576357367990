import { Firestore, doc, getDoc } from "firebase/firestore";
import KeyInfoModel from "common/models/KeyInfoModel";
import { DBModel } from "common/@types/global";
import { setDocument, CreateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "keyInfos";

type UpsertParams = {
  projectId: string;
} & CreateBaseParams<DBModel.KeyInfo>;

export class KeyInfoRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getDocRef(projectId: string) {
    return doc(this.firestore, `projects/${projectId}/${collectionName}/detail`);
  }

  async findByProjectId(projectId: string): Promise<KeyInfoModel | null> {
    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const data = { ...snapshot.data(), id: snapshot.id } as DBModel.KeyInfo;
    return new KeyInfoModel(data);
  }

  async upsert({ projectId, data, uid = auth.currentUser?.uid, writeBatch }: UpsertParams) {
    if (!projectId) {
      throw new Error("projectId is required");
    }

    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
      const newKeyInfo: DBModel.KeyInfo = setCreateInfo({ data, uid });
      await setDocument(docRef, newKeyInfo, writeBatch);
      return;
    }

    const updateKeyInfo: DBModel.KeyInfo = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateKeyInfo, writeBatch);
  }
}

const keyInfoRepository = new KeyInfoRepository(db);
export default keyInfoRepository;
