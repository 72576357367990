import WorkerCard from "./WorkerCard";
import { useQuery } from "@tanstack/react-query";
import { fetchCanceledWorkers } from "./api/fetchCanceledWorkers";
import ProjectModel from "common/models/ProjectModel";

type Props = {
  project: ProjectModel;
};
/**お見送りした職人 */
const CanceledWorkers = ({ project }: Props) => {
  const { data: canceledWorkers } = useQuery({
    queryKey: ["canceledWorkers"],
    queryFn: () => fetchCanceledWorkers(project.canceledWorkerIds!),
    retry: 0,
    throwOnError: true,
    enabled: !!project.hasCanceledWorker,
    initialData: [],
  });

  return (
    <section>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
        {canceledWorkers.map((canceledWorker) => (
          <WorkerCard project={project} worker={canceledWorker} key={canceledWorker.id} />
        ))}
      </div>
    </section>
  );
};

export default CanceledWorkers;
