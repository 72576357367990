import { createBrowserRouter, Outlet } from "react-router-dom";
import { useAuthUserState } from "@/context/AuthUserContext";
import BaseLayout from "@/routes/BaseLayout";
import SignIn from "@/routes/SignIn";
import ProjectId from "@/routes/ProjectId";
import ProjectIdEdit from "@/routes/ProjectIdEdit";
import ProjectIdInspection from "@/routes/ProjectIdInspection";
import ProjectIdConstructionImages from "./ProjectIdConstructionImages";
import AdminBaseLayout from "@/routes/admin/AdminBaseLayout";
import AdminSignIn from "@/routes/admin/AdminSignIn";
import AdminWorkers from "@/routes/admin/AdminWorkers";
import AdminWorkerId from "@/routes/admin/AdminWorkerId";
import AdminProjects from "./admin/AdminProjects";
import AdminProject from "./admin/AdminProject";
import AdminInvoices from "./admin/AdminInvoices";

const AuthGuard = (): JSX.Element => {
  const { authUser, loading } = useAuthUserState();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authUser.isLoggedIn) {
    return <SignIn />;
  }

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

const routes = [
  {
    path: "/",
    element: <AuthGuard />,
    children: [
      { path: "/projects/:projectId", element: <ProjectId /> },
      { path: "/projects/:projectId/edit", element: <ProjectIdEdit /> },
      { path: "/projects/:projectId/inspection", element: <ProjectIdInspection /> },
      { path: "/projects/:projectId/constructionImages", element: <ProjectIdConstructionImages /> },
    ],
  },
];

const AdminAuthGuard = (): JSX.Element => {
  const { authUser, loading } = useAuthUserState();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authUser.isLoggedIn || !authUser.isAdmin) {
    return (
      <AdminBaseLayout>
        <AdminSignIn />
      </AdminBaseLayout>
    );
  }

  return (
    <AdminBaseLayout>
      <Outlet />
    </AdminBaseLayout>
  );
};

// 管理画面用routes
const adminRoutes = [
  {
    path: "/",
    element: <AdminAuthGuard />,
    children: [
      { path: "admin/signin", element: <AdminSignIn /> },
      { path: "admin/workers", element: <AdminWorkers /> },
      { path: "admin/workers/:workerId", element: <AdminWorkerId /> },
      { path: "admin/projects", element: <AdminProjects /> },
      { path: "admin/projects/:projectId", element: <AdminProject /> },
      { path: "admin/invoices", element: <AdminInvoices /> },
    ],
  },
];

export const router = createBrowserRouter([...routes, ...adminRoutes]);
