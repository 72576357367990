import { Input, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ProjectWithOwner, useProjects } from "./hooks/useProjects";
import { StatusTag } from "@/components/StatusTag";
import { SelectProps } from "antd/lib";
import { useNavigate } from "react-router-dom";
import Status from "common/models/project/Status";

const options: SelectProps["options"] = Status.values;

const columns: ColumnsType<ProjectWithOwner> = [
  {
    title: "ステータス",
    dataIndex: "status",
    width: 40,
    render: (_, projectWithOwner) => <StatusTag status={projectWithOwner.project.status} />,
  },
  {
    title: "案件名",
    dataIndex: "projectName",
    width: 100,
    render: (_, projectWithOwner) => <div>{projectWithOwner.project.projectName}</div>,
  },
  {
    title: "施主名",
    dataIndex: "companyName",
    width: 100,
    render: (_, projectWithOwner) => <div>{projectWithOwner.owner.companyName}</div>,
  },
  {
    title: "案件ID",
    dataIndex: "projectId",
    width: 80,
    render: (_, projectWithOwner) => <div>{projectWithOwner.project.id}</div>,
  },
  {
    title: "案件作成日",
    dataIndex: "createdAt",
    width: 30,
    render: (_, projectWithOwner) => <div>{projectWithOwner.project.createdAt.formatted("yyyy/MM/dd")}</div>,
  },
];

/**案件一覧 */
export function ProjectList(): JSX.Element {
  const navigate = useNavigate();
  const {
    isLoading,
    filteredProjectWithOwnerList,
    projectNameValue,
    setProjectNameValue,
    projectIdValue,
    setProjectIdValue,
    ownerCompanyNameValue,
    setOwnerCompanyNameValue,
    setStatuses,
  } = useProjects();

  return (
    <>
      <div style={{ display: "flex", padding: "10px", gap: "10px" }}>
        <Input
          placeholder="案件名"
          value={projectNameValue}
          allowClear
          onChange={(e) => setProjectNameValue(e.target.value || "")}
        />
        <Input
          placeholder="案件ID"
          value={projectIdValue}
          allowClear
          onChange={(e) => setProjectIdValue(e.target.value || "")}
        />
        <Input
          placeholder="施主名"
          value={ownerCompanyNameValue}
          allowClear
          onChange={(e) => setOwnerCompanyNameValue(e.target.value || "")}
        />

        <Select
          mode="multiple"
          placeholder="ステータス"
          optionLabelProp="label"
          onChange={(v: number[]) => setStatuses(v || [])}
          options={options}
          allowClear
          optionRender={(option) => <Space>{option.data.label}</Space>}
          style={{ width: "100%" }}
        />
      </div>

      <Table
        size="small"
        loading={isLoading}
        pagination={false}
        columns={columns}
        dataSource={filteredProjectWithOwnerList}
        scroll={{ y: 540 }}
        rowKey={(record) => record.project.id}
        onRow={(record, _) => {
          return {
            onClick: (event) => {
              navigate(`/admin/projects/${record.project.id}`);
            },
            style: { cursor: "pointer" },
          };
        }}
      />
    </>
  );
}
