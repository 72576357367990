// ファイル選択ごとにアップロードするためのコンポーネント
import React, { ForwardedRef } from "react";
import { useState } from "react";
import { Upload as AntdUpload } from "antd";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { UploadProps as AntdUploadProps } from "antd/es/upload";
import FormItem from "./FormItem";
import { ControllerRenderProps } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";

type UploadResponse = string;

type CustomUploadFile = UploadFile<UploadResponse> & Partial<{ storagePath: string }>;

type UploadProps = {
  name: string;
  label?: string;
  rules?: any;
  onUpload: (file: File) => Promise<UploadResponse>;
  onRemove?: (file: CustomUploadFile) => Promise<void>;
  initFileStoragePaths: string[];
  setIsUploadingFile: (isUploadingFile: boolean) => void;
} & AntdUploadProps<CustomUploadFile>;

type ButtonUploadProps = {
  field: ControllerRenderProps<string[]>;
} & UploadProps;

const CustomMultiUpload: React.FC<ButtonUploadProps> = React.forwardRef(
  (
    { field, onUpload, onRemove, initFileStoragePaths, setIsUploadingFile, maxCount = 8, ...props },
    ref: ForwardedRef<any>
  ) => {
    const [fileList, setFileList] = useState<CustomUploadFile[]>(
      initFileStoragePaths.map((storagePath) => {
        const fileName = storagePath.split("/")[storagePath.split("/").length - 1];
        return {
          uid: storagePath,
          name: fileName,
          storagePath,
        };
      })
    );

    async function customRequest({ file, onSuccess, onError }: RcCustomRequestOptions) {
      setIsUploadingFile(true);
      try {
        if (file instanceof File) {
          const response = await onUpload(file);
          onSuccess?.(response);
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (e: any) {
        onError?.(e);
      }
    }

    const handleChange = ({ fileList: newFileList }: UploadChangeParam<CustomUploadFile>) => {
      setFileList(newFileList);

      if (newFileList.length === 0) {
        field.onChange(null);
        return;
      }

      const myFileStoragePaths: string[] = newFileList.map((file) => {
        return file.response || file.storagePath || "";
      });
      field.onChange(myFileStoragePaths);

      // 全ファイルのアップロードを完了したか確認
      const isAllUploaded = newFileList.every((file) => file.status === "done" || !file.status);
      if (isAllUploaded) {
        setIsUploadingFile(false);
      }
    };

    return (
      <AntdUpload
        {...props}
        ref={ref}
        accept="image/*"
        customRequest={customRequest}
        onChange={handleChange}
        listType="picture-card"
        showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
        fileList={fileList}
        onRemove={onRemove}
      >
        {fileList.length >= maxCount ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </AntdUpload>
    );
  }
);

const MultiUpload: React.FC<UploadProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} label={label} rules={rules} component={CustomMultiUpload} {...props} />;
};

export default MultiUpload;
