import { DBModel } from "../@types/global";
import UserType from "./shared/UserType";
import CustomDateTime from "./shared/CustomDateTime";
import { isBefore } from "date-fns";

export default class MessageModel {
  public readonly id: string;
  public readonly body: string | null; // メッセージ内容
  public readonly deleted: boolean; // 削除フラグ
  public readonly fileStoragePaths: string[] | null; // 添付ファイルのパス
  public readonly senderType: string; // 送信者種別
  public readonly senderUid: string; // 送信者のUID
  public readonly sentDate: CustomDateTime; // 送信日時

  constructor(data: Partial<DBModel.Message>) {
    const defaultValues = {
      id: "",
      body: null,
      deleted: false,
      fileStoragePaths: null,
      senderType: "",
      senderUid: "",
      sentDate: undefined,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.body = mergedData.body;
    this.deleted = mergedData.deleted;
    this.fileStoragePaths = mergedData.fileStoragePaths;
    this.senderType = mergedData.senderType;
    this.senderUid = mergedData.senderUid;
    this.sentDate = new CustomDateTime(mergedData.sentDate);
  }

  /**ファイルのパスを持っている */
  get hasFilePath(): boolean {
    return !!this.fileStoragePaths && this.fileStoragePaths.length > 0;
  }

  /**メッセージ内容のテキスト */
  get bodyText(): string {
    return this.body || "";
  }

  /**プレビュー用テキスト */
  get previewText(): string {
    if (this.hasFilePath) {
      return "ファイル";
    }
    return this.bodyText;
  }

  static ofOwner(data: Partial<DBModel.Message>): MessageModel {
    return new MessageModel({
      ...data,
      senderType: UserType.Owner.value,
    });
  }

  static ofWorker(data: Partial<DBModel.Message>): MessageModel {
    return new MessageModel({
      ...data,
      senderType: UserType.Worker.value,
    });
  }

  static ofDelete(data: Partial<DBModel.Message>): MessageModel {
    return new MessageModel({
      ...data,
      deleted: true,
    });
  }

  /**メッセージ送信者と同じユーザーかどうか */
  isSameUser(uid: string): boolean {
    return this.senderUid === uid;
  }

  /**職人が既読済みかどうか（送信日時が最終既読日時より前の場合は既読） */
  isWorkerRead(currentUserId: string, workerLastReadDate?: Date): boolean {
    if (!this.isSameUser(currentUserId)) {
      return false; // 自分が送信したメッセージは既読済みにしない
    }
    if (!workerLastReadDate) {
      return false; // 職人の最終既読日時がない場合は既読済みにしない
    }

    return isBefore(this.sentDate.value, workerLastReadDate);
  }

  toJSON(): DBModel.Message {
    return {
      body: this.body,
      deleted: this.deleted,
      fileStoragePaths: this.fileStoragePaths,
      senderType: this.senderType,
      senderUid: this.senderUid,
      sentDate: this.sentDate?.value,
    };
  }
}
