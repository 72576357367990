import React from "react";
import ChatDrawer from "@/features/chat/ChatDrawer";
import useModal from "@/hooks/useModal";
import { FloatButton } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import * as chatRoomService from "@/services/chatRoomService";
import ProjectModel from "common/models/ProjectModel";
import { useMount } from "react-use";
import "./Chat.scss";

type Props = {
  project: ProjectModel;
};
const Chat: React.FC<Props> = ({ project }) => {
  const { open, handleOpen, handleClose } = useModal();
  const { data: chatRoomList } = useQuery({
    queryKey: ["chatRoomList"],
    queryFn: () => chatRoomService.fetchChatRoomsByProjectId(project.id),
    retry: 0,
    throwOnError: true,
    initialData: [],
  });

  const canChatWithRelatedWorker = project.canChat && (project.hasWorker || project.hasApplyingWorker);

  useMount(() => {
    if (canChatWithRelatedWorker) {
      handleOpen();
    }
  });

  return (
    <div className="Chat">
      {chatRoomList.length > 0 && (
        <FloatButton
          type="primary"
          description={
            <div className="Chat__button__description">
              <span className="Chat__button__description__text">
                職人との
                <br />
                チャット
              </span>
              <MessageOutlined className="Chat__button__description__icon" />
            </div>
          }
          onClick={handleOpen}
          className="Chat__button"
        />
      )}
      <ChatDrawer
        project={project}
        open={open}
        onClose={handleClose}
        defaultOpenChatRoomId={canChatWithRelatedWorker ? project.workerId || project.applyingWorkerId : null}
      />
    </div>
  );
};

export default Chat;
