import { Firestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import BankAccountModel from "common/models/BankAccountModel";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";

const collectionName = "bankAccounts";

export class BankAccountRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  async findAll(uid: string): Promise<BankAccountModel[]> {
    const colRef = collection(this.firestore, collectionName);
    let q = query(colRef);
    q = query(q, where("uid", "==", uid));
    q = query(q, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const bankAccounts: BankAccountModel[] = [];
    querySnapshot.forEach((doc) => {
      const bankAccount = { ...doc.data(), id: doc.id } as DBModel.BankAccount;
      bankAccounts.push(new BankAccountModel(bankAccount));
    });
    return bankAccounts;
  }
}

const bankAccountRepository = new BankAccountRepository(db);
export default bankAccountRepository;
