import { useParams } from "react-router-dom";
import { Button } from "antd";
import useProject from "@/hooks/useProject";
import useEstimateList from "@/hooks/useEstimateList";
import useModal from "@/hooks/useModal";
import useLatestRework from "@/features/inspection/hooks/useLatestRework";
import WorkRatingModal from "@/features/inspection/WorkRatingModal";
import ConstructionCollapse from "@/features/inspection/ConstructionCollapse";
import RequestReworkModal from "@/features/inspection/RequestReworkModal";
import "./ProjectIdInspection.scss";

/**案件検収ページ */
export default function ProjectIdInspection(): JSX.Element {
  const requestReworkModalHook = useModal();
  const workRatingModalHook = useModal();
  const { projectId } = useParams<{ projectId: string }>();
  if (!projectId) {
    throw new Error(`projectId is required.`);
  }
  const { project } = useProject(projectId);
  const { estimates } = useEstimateList(projectId);
  const { latestRework } = useLatestRework(projectId);

  if (!project?.workerId || !estimates || estimates.isEmpty) {
    return <></>;
  }

  return (
    <div style={{ padding: "24px" }}>
      <h1>案件検収</h1>
      <div className="ProjectIdInspection__guide">
        工事前後の写真を確認し、問題がなければ作業の評価を行ってください。
      </div>
      <ConstructionCollapse projectId={projectId} estimates={estimates} />
      <div className="ProjectIdInspection__actions">
        {/* 最新の再施工が別の案件で行われている場合は非表示 */}
        {!latestRework?.isOtherProject(projectId) && (
          <Button type="primary" danger onClick={requestReworkModalHook.handleOpen}>
            再施工を依頼する
          </Button>
        )}
        <Button type="primary" onClick={workRatingModalHook.handleOpen}>
          作業の評価へ
        </Button>
      </div>

      <RequestReworkModal
        modalHook={requestReworkModalHook}
        projectId={projectId}
        workerId={project.workerId}
        estimates={estimates}
      />
      <WorkRatingModal modalHook={workRatingModalHook} projectId={projectId} workerId={project.workerId} />
    </div>
  );
}
