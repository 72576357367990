import { auth } from "@/lib/firebase";
import messageRepository from "@/repositories/MessageRepository";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import MessageModel from "common/models/MessageModel";
import { WriteBatch } from "firebase/firestore";

type Params = {
  projectId: string;
  chatRoomId: string;
  messageId?: string;
  body?: string;
  fileStoragePaths?: string[];
  writeBatch?: WriteBatch;
};
/** メッセージを作成する */
export async function createMessage({ projectId, chatRoomId, messageId, body, fileStoragePaths, writeBatch }: Params) {
  const message = MessageModel.ofOwner({
    senderUid: auth.currentUser?.uid || "",
    sentDate: serverTimestamp() as Timestamp,
    body: body || null,
    fileStoragePaths: fileStoragePaths || null,
  });

  await messageRepository.create({ id: messageId, projectId, chatRoomId, data: message.toJSON(), writeBatch });
}
