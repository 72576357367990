import {
  WriteBatch,
  CollectionReference,
  DocumentReference,
  DocumentData,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

type BaseProps = {
  writeBatch?: WriteBatch;
};
export type CreateBaseParams<T> = {
  data: Omit<T, "id">;
  uid?: string;
} & BaseProps;
export type UpdateBaseParams<T> = {
  id: string;
  data: DeepPartial<T>;
  uid?: string;
} & BaseProps;
export type DeleteBaseParams = {
  id: string;
} & BaseProps;

/**作成日時と作成者、更新日時と更新者をセットする */
export function setCreateInfo({ data, uid }: { data: any; uid?: string }) {
  data.createdAt = serverTimestamp();
  data.updatedAt = serverTimestamp();
  if (uid) {
    data.createdUid = uid;
    data.updatedUid = uid;
  }
  return data;
}

/**更新日時と更新者をセットする */
export function setUpdateInfo({ data, uid }: { data: any; uid?: string }) {
  data.updatedAt = serverTimestamp();
  if (uid) data.updatedUid = uid;
  return data;
}

export async function createDocument(
  collectionRef: CollectionReference<DocumentData>,
  data: DocumentData,
  batch?: WriteBatch
): Promise<string> {
  if (batch) {
    const newDocRef = doc(collectionRef);
    batch.set(newDocRef, data);
    return newDocRef.id;
  } else {
    const ref = await addDoc(collectionRef, data);
    return ref.id;
  }
}

export async function setDocument(docRef: DocumentReference<DocumentData>, data: DocumentData, batch?: WriteBatch) {
  if (batch) {
    batch.set(docRef, data, { merge: true });
  } else {
    await setDoc(docRef, data, { merge: true });
  }
}

export async function updateDocument(docRef: DocumentReference<DocumentData>, data: DocumentData, batch?: WriteBatch) {
  if (batch) {
    batch.update(docRef, data);
  } else {
    await updateDoc(docRef, data);
  }
}

export async function deleteDocument(docRef: DocumentReference<DocumentData>, batch?: WriteBatch) {
  if (batch) {
    batch.delete(docRef);
  } else {
    await deleteDoc(docRef);
  }
}
