import React, { useState, ForwardedRef } from "react";
import { Upload as AntdUpload } from "antd";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { UploadProps as AntdUploadProps } from "antd/es/upload";
import FormItem from "./FormItem";
import { ControllerRenderProps } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";

type UploadResponse = string | null;

type CustomUploadFile = UploadFile<UploadResponse> & Partial<{ storagePath: string }>;

type UploadProps = {
  name: string;
  label?: string;
  rules?: any;
  onUpload: (file: File) => Promise<UploadResponse>;
  onRemove?: (file: CustomUploadFile) => Promise<void>;
  initFileStoragePath: string | null;
  initDisplayFileName?: string; // 初期ファイルがあった場合の表示名
  setIsUploadingFile: (isUploadingFile: boolean) => void;
} & AntdUploadProps<CustomUploadFile>;

type ButtonUploadProps = {
  field: ControllerRenderProps<string[]>;
} & UploadProps;

const CustomSingleUpload: React.FC<ButtonUploadProps> = React.forwardRef(
  (
    { field, onUpload, onRemove, initFileStoragePath, initDisplayFileName, setIsUploadingFile, maxCount = 1 },
    ref: ForwardedRef<any>
  ) => {
    const [fileList, setFileList] = useState<CustomUploadFile[]>(
      initFileStoragePath
        ? [
            {
              uid: initFileStoragePath,
              name: initDisplayFileName || "",
              storagePath: initFileStoragePath,
            },
          ]
        : []
    );

    async function customRequest({ file, onSuccess, onError }: RcCustomRequestOptions) {
      setIsUploadingFile(true);
      try {
        if (file instanceof File) {
          const response = await onUpload(file);
          onSuccess?.(response);
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (e: any) {
        onError?.(e);
      }
    }

    const handleChange = ({ fileList: newFileList }: UploadChangeParam<CustomUploadFile>) => {
      if (newFileList.length > maxCount) {
        throw new Error("Invalid file count");
      }
      setFileList(newFileList);

      if (newFileList.length === 0) {
        field.onChange(null);
        return;
      }

      const selectedFile = newFileList[0];
      const selectedFileStoragePath = selectedFile.response || selectedFile.storagePath || null;
      field.onChange(selectedFileStoragePath);

      // ファイルのアップロードが完了したか確認
      if (selectedFile.status == "done" || !selectedFile.status) {
        setIsUploadingFile(false);
      }
    };

    return (
      <AntdUpload
        ref={ref}
        accept="image/*, application/pdf"
        customRequest={customRequest}
        onChange={handleChange}
        listType="picture-card"
        showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
        fileList={fileList}
        onRemove={onRemove}
        maxCount={maxCount}
      >
        {fileList.length >= maxCount ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </AntdUpload>
    );
  }
);

const SingleUpload: React.FC<UploadProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} label={label} rules={rules} component={CustomSingleUpload} {...props} />;
};

export default SingleUpload;
