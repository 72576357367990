import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Timestamp } from "firebase/firestore";

export default class CustomDateTime {
  private readonly _value: Date;

  constructor(value?: Date | Timestamp) {
    if (value instanceof Timestamp) {
      this._value = value.toDate();
    } else if (value instanceof Date) {
      this._value = value;
    } else {
      this._value = new Date();
    }
  }

  static fromISOString(ISOString: string): CustomDateTime {
    return new CustomDateTime(new Date(ISOString));
  }

  static fromDate(date: Date): CustomDateTime {
    return new CustomDateTime(date);
  }

  static fromFirestoreTimestamp(timestamp: Timestamp): CustomDateTime {
    return new CustomDateTime(timestamp.toDate());
  }

  get value(): Date {
    return new Date(this._value);
  }

  /**e.g. 2021年01月01日(金) */
  formattedDay(formatStr: string = "yyyy年MM月dd日(eee)"): string {
    return format(this._value, formatStr, { locale: ja });
  }

  /**e.g. 2021年01月01日(金) 10:00 */
  formatted(formatStr: string = "yyyy年MM月dd日(eee) HH:mm"): string {
    return format(this._value, formatStr, { locale: ja });
  }
}
