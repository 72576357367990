import React, { ForwardedRef } from "react";
import { Checkbox as AntdCheckbox } from "antd";
import { CheckboxGroupProps as AntdCheckboxGroupProps } from "antd/es/checkbox";
import { ControllerRenderProps } from "react-hook-form";
import FormItem from "./FormItem";

type CheckboxGroupProps = {
  name: string;
  label?: string;
  rules?: any;
} & AntdCheckboxGroupProps;

type MyCheckboxGroupProps = {
  field: ControllerRenderProps<string[]>;
} & CheckboxGroupProps;

const MyCheckboxGroup = React.forwardRef(
  ({ field, ...props }: MyCheckboxGroupProps, ref: ForwardedRef<HTMLDivElement>) => (
    <AntdCheckbox.Group {...props} ref={ref}>
      <>
        {props.options!.map((option: any) => (
          <AntdCheckbox key={option.value} value={option.value}>
            {option.label}
          </AntdCheckbox>
        ))}
      </>
    </AntdCheckbox.Group>
  )
);

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} label={label} rules={rules} component={MyCheckboxGroup} {...props} />;
};

export default CheckboxGroup;
