import { DBModel } from "../@types/global";
import CustomDateTime from "./shared/CustomDateTime";
import Big from "big.js";
import { formatCurrency } from "../utils/textUtil";

/**請求書の明細行 */
export default class InvoiceDetailLineModel {
  public readonly id: string;
  public readonly description: string; // 摘要
  public readonly note: string | null; // 備考
  public readonly projectId: string; // 案件ID
  public readonly quantity: number; // 数量
  public readonly salesDate: CustomDateTime; // 取引日
  public readonly unit: string; // 単位
  public readonly unitPrice: number; // 単価
  public readonly workerId: string; // 職人ID

  constructor(data: Partial<DBModel.InvoiceDetailLine>) {
    const defaultValues = {
      id: "",
      description: "",
      note: null,
      projectId: "",
      quantity: 0,
      salesDate: undefined,
      unit: "",
      unitPrice: 0,
      workerId: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.description = mergedData.description;
    this.note = mergedData.note;
    this.projectId = mergedData.projectId;
    this.quantity = mergedData.quantity;
    this.salesDate = new CustomDateTime(mergedData.salesDate);
    this.unit = mergedData.unit;
    this.unitPrice = mergedData.unitPrice;
    this.workerId = mergedData.workerId;
  }

  get unitPriceFormatted(): string {
    return formatCurrency(this.unitPrice);
  }

  /**金額（税抜）※小数点切り捨て */
  get amount(): number | null {
    return Math.floor(Number(Big(this.quantity).times(this.unitPrice)));
  }

  get amountFormatted(): string {
    return formatCurrency(this.amount);
  }

  toJSON(): DBModel.InvoiceDetailLine {
    return {
      description: this.description,
      note: this.note,
      projectId: this.projectId,
      quantity: this.quantity,
      salesDate: this.salesDate.value,
      unit: this.unit,
      unitPrice: this.unitPrice,
      workerId: this.workerId,
    };
  }
}
