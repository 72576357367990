import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { message } from "antd";
import ProjectForm, { FormData } from "@/features/project/ProjectForm";
import useProject from "@/hooks/useProject";
import { updateProject } from "@/features/project/api/updateProject";
import { useQuery } from "@tanstack/react-query";
import * as keyInfoService from "@/services/keyInfoService";
import { fetchResident } from "@/features/project/api/fetchResident";
import { useLoading } from "@/context/LoadingContext";
import { useEffect } from "react";

/**案件編集ページ */
export default function ProjectIdEdit(): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  if (!projectId) {
    throw new Error(`projectId is required.`);
  }
  const { project } = useProject(projectId);
  const { data: keyInfo, isFetching: isFetchingKeyInfo } = useQuery({
    queryKey: ["keyInfo"],
    queryFn: () => keyInfoService.fetchKeyInfo(projectId),
    retry: 0,
    enabled: !!projectId,
    initialData: null,
  });
  const { data: resident, isFetching: isFetchingResident } = useQuery({
    queryKey: ["resident"],
    queryFn: () => fetchResident(projectId),
    retry: 0,
    enabled: !!projectId && !!project?.hasResident,
    initialData: null,
  });
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (!project || isFetchingKeyInfo || isFetchingResident) {
      startLoading("Loading...");
    } else {
      stopLoading();
    }
  }, [project, isFetchingKeyInfo, isFetchingResident]);

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await updateProject(projectId, data);
      message.success("更新しました。");
      navigate(`/projects/${projectId}`);
    } catch (err: any) {
      console.log(err.message);
      message.error("登録に失敗しました。");
    }
  };

  if (!project || isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "24px" }}>
      <h1>案件編集</h1>
      <ProjectForm
        projectId={projectId}
        onSubmit={handleFormSubmit}
        project={project}
        keyInfo={keyInfo}
        resident={resident}
      />
    </div>
  );
}
