import { DBModel } from "../@types/global";

export default class ReworkDetailModel {
  public readonly id: string;
  public readonly imageStoragePaths: string[] | null; // 再施工写真のstorageパス
  public readonly estimateId: string; // 見積ID

  constructor(data: Partial<DBModel.ReworkDetail>) {
    const defaultValues = {
      id: "",
      imageStoragePaths: null,
      estimateId: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = defaultValues.id;
    this.imageStoragePaths = mergedData.imageStoragePaths;
    this.estimateId = mergedData.estimateId;
  }

  toJSON(): DBModel.ReworkDetail {
    return {
      imageStoragePaths: this.imageStoragePaths,
      estimateId: this.estimateId,
    };
  }
}
