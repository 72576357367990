import React, { ForwardedRef } from "react";
import { DatePicker } from "antd";
import type { RangePickerProps as AntdRangePickerProps } from "antd/es/date-picker";
import FormItem from "./FormItem";
import { ControllerRenderProps } from "react-hook-form";

const AntdRangePicker = DatePicker.RangePicker;

type RangePickerProps = {
  name: string;
  label?: string;
  rules?: any;
} & AntdRangePickerProps;

type MyRangePickerProps = {
  field: ControllerRenderProps;
} & RangePickerProps;

const MyRangePicker = React.forwardRef(({ field, ...props }: MyRangePickerProps, ref: ForwardedRef<any>) => (
  <AntdRangePicker
    ref={ref}
    {...props}
    onChange={(dates, dateStrings) => {
      field.onChange(dates);
      props.onChange && props.onChange(dates, dateStrings);
    }}
  />
));

const RangePicker: React.FC<RangePickerProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} label={label} rules={rules} component={MyRangePicker} {...props} />;
};

export default RangePicker;
