import React, { ForwardedRef } from "react";
import { Input } from "antd";
import { TextAreaProps as AntdTextAreaProps } from "antd/es/input";
import FormItem from "./FormItem";
import { TextAreaRef } from "antd/lib/input/TextArea";

type TextareaProps = {
  name: string;
  label?: string;
  rules?: any;
} & AntdTextAreaProps;

const MyTextArea = React.forwardRef((props: TextareaProps, ref: ForwardedRef<TextAreaRef>) => (
  <Input.TextArea ref={ref} {...props} />
));

const Textarea: React.FC<TextareaProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} rules={rules} label={label} component={MyTextArea} {...props} />;
};

export default Textarea;
