import { Button, Select, Table, Input, DatePicker } from "antd";
import { ColumnsType } from "antd/es/table";
import { useInvoiceList } from "./hooks/useInvoiceList";
import InvoiceModel from "common/models/InvoiceModel";
import InvoiceDetailLineList from "./InvoiceDetailLineList";
import InvoiceEditModal from "./InvoiceIdEditModal";
import { useState } from "react";
import useModal from "@/hooks/useModal";
import { SelectProps } from "antd/lib";
import dayjs from "dayjs";
import "./InvoiceList.scss";

/**請求一覧 */
const InvoiceList: React.FC = () => {
  const {
    isLoading,
    filteredInvoiceList,
    selectedStatus,
    setSelectedStatus,
    selectedInvoiceTo,
    setSelectedInvoiceTo,
    selectedSalesYearMonth,
    setSelectedSalesYearMonth,
  } = useInvoiceList();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel | null>(null);
  const modalHook = useModal();

  const columns: ColumnsType<InvoiceModel> = [
    {
      title: "ステータス",
      dataIndex: "status",
      width: 40,
      render: (_, invoice) => <div>{invoice.statusLabel}</div>,
    },
    {
      title: "請求先",
      dataIndex: "invoiceTo",
      width: 80,
      render: (_, invoice) => <div>{invoice.invoiceTo}</div>,
    },
    {
      title: "請求先住所",
      dataIndex: "invoiceAddress",
      width: 100,
      render: (_, invoice) => <div>{invoice.invoiceAddress.formatted}</div>,
    },
    {
      title: "取引発生年月",
      dataIndex: "salesYearMonth",
      width: 40,
      render: (_, invoice) => <div>{invoice.salesYearMonth}</div>,
    },
    {
      title: "請求書番号",
      dataIndex: "invoiceNumber",
      width: 50,
      render: (_, invoice) => <div>{invoice.invoiceNumber}</div>,
    },
    {
      title: "担当者",
      dataIndex: "assignee",
      width: 40,
      render: (_, invoice) => <div>{invoice.assignee}</div>,
    },
    {
      title: "確認者",
      dataIndex: "checker",
      width: 40,
      render: (_, invoice) => <div>{invoice.checker}</div>,
    },
    {
      title: "管理用メモ",
      dataIndex: "adminNote",
      width: 100,
      render: (_, invoice) => <div>{invoice.adminNote}</div>,
    },
    {
      title: "編集",
      dataIndex: "edit",
      width: 30,
      render: (_, invoice) => (
        <Button
          type="primary"
          ghost
          onClick={() => {
            setSelectedInvoice(invoice);
            modalHook.handleOpen();
          }}
        >
          編集
        </Button>
      ),
    },
  ];

  const options: SelectProps["options"] = [
    { label: "未選択", value: "" },
    { label: "請求済み", value: "invoiced" },
    { label: "未請求", value: "uninvoiced" },
  ];

  return (
    <>
      <div className="InvoiceList__filterContainer">
        <Select
          placeholder="ステータス"
          optionLabelProp="label"
          defaultValue={selectedStatus}
          options={options}
          onChange={(v: string) => setSelectedStatus(v)}
          className="InvoiceList__filterContainer__form"
        />
        <DatePicker
          placeholder="取引発生年月"
          picker="month"
          defaultValue={dayjs(selectedSalesYearMonth)}
          onChange={(value: dayjs.Dayjs) => {
            if (value) {
              setSelectedSalesYearMonth(value.format("YYYY-MM"));
            }
          }}
          className="InvoiceList__filterContainer__form"
        />
        <Input
          placeholder="請求先"
          value={selectedInvoiceTo}
          allowClear
          onChange={(e) => setSelectedInvoiceTo(e.target.value || "")}
          className="InvoiceList__filterContainer__form"
        />
      </div>

      <Table
        size="small"
        loading={isLoading}
        pagination={false}
        columns={columns}
        dataSource={filteredInvoiceList}
        scroll={{ y: 540 }}
        rowKey={(record) => record.id}
        expandable={{
          columnTitle: "明細",
          columnWidth: 20,
          expandedRowClassName: () => "InvoiceList__expandedRow",
          expandedRowRender: (record) => <InvoiceDetailLineList invoiceId={record.id} />,
        }}
      />

      {selectedInvoice && (
        <InvoiceEditModal
          invoice={selectedInvoice}
          open={modalHook.open}
          handleClose={() => {
            modalHook.handleClose();
            setSelectedInvoice(null);
          }}
        />
      )}
    </>
  );
};

export default InvoiceList;
