import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import { Textarea } from "@/components/FormItems";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { upsertAdminProjectSetting } from "./api/upsertAdminProjectSetting";
import AdminProjectSettingModel from "common/models/AdminProjectSettingModel";
import { ModalHook } from "@/hooks/useModal";

type Props = {
  adminProjectSetting: AdminProjectSettingModel;
  modalHook: ModalHook;
  refetchAdminProjectSetting: () => void;
};
export type FormData = {
  adminProjectSetting: DBModel.AdminProjectSetting;
};
/**案件管理用設定モーダル */
const AdminProjectSettingModal: React.FC<Props> = ({ adminProjectSetting, modalHook, refetchAdminProjectSetting }) => {
  const [defaultValues, setDefaultValues] = useState({
    adminProjectSetting: adminProjectSetting.toJSON(),
  });
  const methods = useForm<FormData>({ defaultValues });
  const { watch, handleSubmit } = methods;
  const values = watch();
  const [isValueChanged, setIsValueChanged] = useState(false);

  // フォームの変更を検知して、更新ボタンの活性化を制御する
  useEffect(() => {
    const isChanged = JSON.stringify(defaultValues) !== JSON.stringify(values);
    setIsValueChanged(isChanged);
  }, [values]);

  const handleOnSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await upsertAdminProjectSetting(adminProjectSetting.id, data);
      setDefaultValues(data);
      modalHook.handleClose();
      message.success("保存しました");
      refetchAdminProjectSetting();
    } catch (error: any) {
      message.error("保存に失敗しました");
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          centered
          open={modalHook.open}
          onCancel={() => {
            modalHook.handleClose();
            message.warning("管理用メモは保存されていません");
          }}
          title={"管理用メモ"}
          okText="保存する"
          onOk={handleSubmit(handleOnSubmit)}
          okButtonProps={{ disabled: !isValueChanged }}
          width={800}
        >
          <Textarea name="adminProjectSetting.note" rows={5} />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default AdminProjectSettingModal;
