import React from "react";
import { FloatButton } from "antd";
import useModal from "@/hooks/useModal";
import useAdminProjectSetting from "./hooks/useAdminProjectSetting";
import AdminProjectSettingModal from "./AdminProjectSettingModal";

type Props = {
  projectId: string;
};
/**管理用メモ */
const ProjectDetailAdminNote: React.FC<Props> = ({ projectId }) => {
  const modalHook = useModal();
  const { adminProjectSetting, refetchAdminProjectSetting } = useAdminProjectSetting(projectId);

  return (
    <>
      <FloatButton
        type="primary"
        description={
          <div style={{ fontSize: "14px" }}>
            管理用
            <br />
            メモ
          </div>
        }
        badge={{ dot: adminProjectSetting?.hasWrittenNote, style: { width: "12px", height: "12px" } }}
        onClick={modalHook.handleOpen}
        style={{ width: "70px", height: "70px" }}
      />

      {adminProjectSetting && (
        <AdminProjectSettingModal
          adminProjectSetting={adminProjectSetting}
          modalHook={modalHook}
          refetchAdminProjectSetting={refetchAdminProjectSetting}
        />
      )}
    </>
  );
};

export default ProjectDetailAdminNote;
