import projectRepository from "@/repositories/ProjectRepository";
import Status from "common/models/project/Status";

/**職人を募集します */
export async function recruit(projectId: string, workableStartDate: Date, workableEndDate: Date) {
  const updateProject = {
    status: Status.Recruiting.value,
    workableStartDate,
    workableEndDate,
  };

  await projectRepository.update({ id: projectId, data: updateProject });
}
