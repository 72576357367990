import { createContext, useContext } from "react";
import MessageModel from "common/models/MessageModel";
import classname from "classnames";
import ChatRoomModel from "common/models/ChatRoomModel";
import MessageFile from "./MessageFile";
import { useAuthUserState } from "@/context/AuthUserContext";
import "./Message.scss";

type MessageContextProps = {
  message: MessageModel;
  chatRoom: ChatRoomModel;
};
const MessageContext = createContext<MessageContextProps>({
  message: new MessageModel({}),
  chatRoom: new ChatRoomModel({}),
});

type Props = {
  message: MessageModel;
  chatRoom: ChatRoomModel;
  children: React.ReactNode;
};

const Message = ({ message, chatRoom, children }: Props) => {
  const { authUser } = useAuthUserState();
  if (!message) return null;

  return (
    <div
      className={classname("Message", {
        "Message--依頼者": message.isSameUser(authUser.id),
        "Message--職人": !message.isSameUser(authUser.id),
      })}
    >
      <MessageContext.Provider value={{ message, chatRoom }}>{children}</MessageContext.Provider>
    </div>
  );
};

const MessageMeta = () => {
  const { message, chatRoom } = useContext(MessageContext);
  const { authUser } = useAuthUserState();

  return (
    <div className="Message__meta">
      {message.isWorkerRead(authUser.id, chatRoom.workerLastReadDate?.value) && <div>既読</div>}
      <div>{message.sentDate.formatted("MM/dd HH:mm")}</div>
    </div>
  );
};

const Body = () => {
  const { message } = useContext(MessageContext);

  if (message.hasFilePath) {
    return null;
  }

  return (
    <div className="Message__item">
      <div className="Message__box">
        <div className="Message__body">{message.body}</div>
      </div>
      <MessageMeta />
    </div>
  );
};

const Files = () => {
  const { message } = useContext(MessageContext);
  if (!message.hasFilePath) {
    return null;
  }
  return (
    <div className="Message__item">
      {message.fileStoragePaths!.map((path, index) => (
        <div key={index}>
          <MessageFile refPath={path} />
        </div>
      ))}
      <MessageMeta />
    </div>
  );
};

Message.Body = Body;
Message.Files = Files;

export default Message;
