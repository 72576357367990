import { Card, Row, Col } from "antd";
import ProjectModel from "common/models/ProjectModel";
import { StatusTag } from "@/components/StatusTag";
import Button from "@/components/Button";
import useModal from "@/hooks/useModal";
import CarouselModal from "@/components/CarouselModal";
import WithdrawalQuestionnaireResultModal from "./WithdrawalQuestionnaireResultModal";

type Props = {
  project: ProjectModel;
};
/**基本情報 */
export function ProjectDetailBasic({ project }: Props): JSX.Element {
  const propertyPhotoModalHook = useModal();
  const withdrawalQuestionnaireResultModalHook = useModal();

  return (
    <>
      <Card title="基本情報" size="small" type="inner">
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>案件名</Col>
          <Col span={14}>{project.projectName}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>住所</Col>
          <Col span={14}>{project.addressFormatted}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>工事写真</Col>
          <Col span={14}>
            {project.propertyPhotoStoragePaths && project.propertyPhotoStoragePaths.length > 0 ? (
              <Button type="primary" ghost size="small" onClick={propertyPhotoModalHook.handleOpen}>
                詳細
              </Button>
            ) : (
              "なし"
            )}
          </Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>ステータス</Col>
          <Col span={4}>
            <StatusTag status={project.status} />
          </Col>
          <Col span={10}>
            {project.isWithdrawn && (
              <Button type="primary" ghost size="small" onClick={withdrawalQuestionnaireResultModalHook.handleOpen}>
                取り下げアンケート結果
              </Button>
            )}
          </Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>オートロックの有無</Col>
          <Col span={14}>{project.hasAutoLockText}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>エレベーターの有無</Col>
          <Col span={14}>{project.hasElevatorText}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>入居状況</Col>
          <Col span={14}>{project.hasResidentText}</Col>
        </Row>
        {project.isElectricityConnected !== null && (
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>電気の通電状況</Col>
            <Col span={14}>
              {project.isElectricityConnectedText}
              {!project.isElectricityConnected && <>（{project.hasRequestElectricityConnectionText}）</>}
            </Col>
          </Row>
        )}
        {project.isWaterConnected !== null && (
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>水道の開通状況</Col>
            <Col span={14}>
              {project.isWaterConnectedText}
              {!project.isWaterConnected && <>（{project.hasRequestWaterConnectionText}）</>}
            </Col>
          </Row>
        )}
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>駐車場の場所</Col>
          <Col span={14}>{project.freeParkingLocation}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>見積の職種</Col>
          <Col span={14}>{project.jobType?.label}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>案件担当者</Col>
          <Col span={14}>{project.projectManager}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>依頼主から職人への要望</Col>
          <Col span={14}>{project.ownerRequest}</Col>
        </Row>
      </Card>

      <CarouselModal
        title={"工事写真"}
        storagePaths={project.propertyPhotoStoragePaths || []}
        modalHook={propertyPhotoModalHook}
      />
      <WithdrawalQuestionnaireResultModal projectId={project.id} modalHook={withdrawalQuestionnaireResultModalHook} />
    </>
  );
}
