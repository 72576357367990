import JobType from "./JobType";

export default class JobTypes {
  private _value: string[];

  constructor(value: string[]) {
    this._value = value;
  }

  get value(): string[] {
    return this._value;
  }

  toString() {
    const strArray = JobType.values.filter((type) => this.value.includes(type.value)).map((type) => type.label);

    const str = strArray.join(" / ");

    return str;
  }
}
