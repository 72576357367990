import { DBModel } from "../@types/global";
import CompanyAddress from "./shared/CompanyAddress";

export default class OwnerModel {
  public readonly id: string;
  public readonly companyAddress: CompanyAddress; // 会社の住所
  public readonly companyId: string; // 原状回復アカウントの会社ID
  public readonly companyName: string; // 会社名
  public readonly companyPhoneNumber: string; // 会社の電話番号
  public readonly firstName: string; // 名
  public readonly lastName: string; // 姓
  public readonly userId: string; // 原状回復アカウントのユーザーID

  constructor(data: Partial<DBModel.Owner>) {
    const defaultValues = {
      id: "",
      companyAddress: {
        prefecture: "",
        city: "",
        streetNumberAndBuilding: "",
      },
      companyId: "",
      companyName: "",
      companyPhoneNumber: "",
      firstName: "",
      lastName: "",
      userId: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.companyAddress = new CompanyAddress(mergedData.companyAddress);
    this.companyId = mergedData.companyId;
    this.companyName = mergedData.companyName;
    this.companyPhoneNumber = mergedData.companyPhoneNumber;
    this.firstName = mergedData.firstName;
    this.lastName = mergedData.lastName;
    this.userId = mergedData.userId;
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }

  toJSON(): DBModel.Owner {
    return {
      companyAddress: this.companyAddress.value,
      companyId: this.companyId,
      companyName: this.companyName,
      companyPhoneNumber: this.companyPhoneNumber,
      firstName: this.firstName,
      lastName: this.lastName,
      userId: this.userId,
    };
  }
}
