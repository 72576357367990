import { Modal, message, Row, Col, Table, Checkbox } from "antd";
import { Textarea } from "@/components/FormItems";
import { ModalHook } from "@/hooks/useModal";
import { requestRework } from "@/features/inspection/api/requestRework";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import useModal from "@/hooks/useModal";
import ReworkAssuranceModal from "./ReworkAssuranceModal";
import EstimateListModel from "common/models/EstimateListModel";
import EstimateModel from "common/models/EstimateModel";
import { ColumnsType } from "antd/es/table";

type Props = {
  modalHook: ModalHook;
  projectId: string;
  workerId: string;
  estimates: EstimateListModel;
};
export type FormData = {
  reworkReason: string; // 再施工の理由
  reworkTargetEstimateIds: string[]; // 再施工対象の見積ID
};
/**再施工依頼モーダル */
const RequestReworkModal = ({ modalHook, projectId, workerId, estimates }: Props): JSX.Element => {
  const reworkAssuranceModalHook = useModal();
  const methods = useForm<FormData>({
    defaultValues: {
      reworkReason: "",
      reworkTargetEstimateIds: [],
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const values = watch();
  const isInvalid = !values.reworkReason || !values.reworkTargetEstimateIds.length;

  /**再施工を依頼する */
  const handleRequestRework: SubmitHandler<FormData> = async (data) => {
    try {
      await requestRework(projectId, workerId, data);
      message.success("再施工を依頼しました。");
      modalHook.handleClose();
      reworkAssuranceModalHook.handleOpen();
    } catch (error: any) {
      console.log(error.message);
      message.error("再施工依頼に失敗しました。");
    }
  };

  const columns: ColumnsType<EstimateModel> = [
    {
      title: <div style={{ textAlign: "center" }}>依頼する</div>,
      dataIndex: "reworkTargetEstimateIds",
      width: "15%",
      render: (_, estimate) => (
        <div style={{ textAlign: "center" }}>
          <Checkbox
            onChange={(e) => {
              const value = !!e.target.checked
                ? [...values.reworkTargetEstimateIds, estimate.id]
                : values.reworkTargetEstimateIds.filter((id: string) => id !== estimate.id);
              setValue("reworkTargetEstimateIds", value);
            }}
          />
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>項目</div>,
      dataIndex: "itemName",
      width: "85%",
      render: (_, estimate) => <div>{estimate.itemName}</div>,
    },
  ];

  return (
    <>
      <Modal
        title="再施工依頼"
        centered
        width={600}
        open={modalHook.open}
        okText={"再施工を依頼する"}
        okButtonProps={{ danger: true, disabled: isInvalid }}
        onOk={handleSubmit(handleRequestRework)}
        onCancel={modalHook.handleClose}
      >
        <FormProvider {...methods}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <div className="FormItem FormItem--required">
                <label className="FormItem__label">再施工を依頼する項目を少なくとも1つ選択してください</label>
              </div>
              <Table pagination={false} columns={columns} dataSource={estimates.value} rowKey="id" size="small" />
            </Col>
          </Row>
          <Row gutter={[16, 12]} style={{ marginTop: "20px" }}>
            <Col span={24}>
              <div className="FormItem FormItem--required">
                <label className="FormItem__label">再施工の理由をできるだけ詳しく入力してください</label>
              </div>
              <Textarea name="reworkReason" rows={5} />
              <div>※入力された内容はチャットにて職人に送信されます</div>
            </Col>
          </Row>
        </FormProvider>
      </Modal>

      <ReworkAssuranceModal modalHook={reworkAssuranceModalHook} projectId={projectId} />
    </>
  );
};

export default RequestReworkModal;
