import { useEffect, useState } from "react";
import ChatRoomModel from "common/models/ChatRoomModel";
import chatRoomRepository from "@/repositories/ChatRoomRepository";
import * as workerService from "@/services/workerService";
import PublicWorkerDetailModel from "common/models/PublicWorkerDetailModel";

type ChatRoomHookResult = {
  chatRoom: ChatRoomModel;
};

const useChatRoom = (projectId: string, chatRoomId: string): ChatRoomHookResult => {
  const [chatRoom, setChatRoom] = useState<ChatRoomModel>(new ChatRoomModel({}));

  useEffect(() => {
    const getWorker = async (chatRoom: ChatRoomModel): Promise<PublicWorkerDetailModel> => {
      const workerId = chatRoom.id;
      const workers = await workerService.fetchWorkerPublicDetails([workerId]);
      return workers[0];
    };

    const unsubscribe = chatRoomRepository.subscribeDocumentByChatRoomId(projectId, chatRoomId, async (chatRoom) => {
      const worker = await getWorker(chatRoom);
      // チャットルームにチャット相手の名前を追加
      const newChatRoomModel = chatRoom.withAdditionalParams({
        partnerName: worker.displayName,
      });
      setChatRoom(newChatRoomModel);
    });

    return () => {
      unsubscribe();
    };
  }, [projectId, chatRoomId]);

  return { chatRoom };
};

export default useChatRoom;
