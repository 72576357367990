import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Modal, message, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ModalHook } from "@/hooks/useModal";
import usePublicWorkerDetail from "@/hooks/usePublicWorkerDetail";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import { completeInspection } from "@/features/inspection/api/completeInspection";
import * as workerService from "@/services/workerService";
import { useQuery } from "@tanstack/react-query";
import RatingButtons from "./RatingButtons";
import "./WorkRatingModal.scss";

type Props = {
  modalHook: ModalHook;
  projectId: string;
  workerId: string;
};
/**作業の評価モーダル */
const WorkRatingModal = ({ modalHook, projectId, workerId }: Props): JSX.Element => {
  const navigate = useNavigate();
  const [workRating, setWorkRating] = useState<DBModel.WorkRating>({
    quality: 5,
    speed: 5,
    communication: 5,
  });
  const textAreaRef = useRef<TextAreaRef>(null);
  const { worker } = usePublicWorkerDetail(workerId);

  const { data: profileImgUrl } = useQuery({
    queryKey: ["profileImgUrl"],
    queryFn: () => workerService.fetchProfileImgUrl(worker!.profileImageStoragePath!),
    retry: 0,
    enabled: !!worker && !!worker.profileImageStoragePath,
    initialData: "",
  });

  if (!worker) {
    return <></>;
  }

  const handleSetWorkRating = (type: string, value: number) => {
    setWorkRating((prevWorkRating: DBModel.WorkRating) => ({
      ...prevWorkRating,
      [type]: value,
    }));
  };

  /**評価を完了するボタン押下時 */
  const handleComplateInspection = async (): Promise<void> => {
    try {
      await completeInspection({
        projectId,
        worker,
        workRating,
        comment: textAreaRef.current?.resizableTextArea?.textArea.value || "",
      });

      message.success("評価が完了しました。");
      navigate(`/projects/${projectId}`);
    } catch (error: any) {
      console.log(error.message);
      message.error("評価に失敗しました");
    }
  };

  return (
    <Modal
      title="作業の評価"
      centered
      width={600}
      open={modalHook.open}
      okText={"評価を完了する"}
      onOk={handleComplateInspection}
      onCancel={modalHook.handleClose}
      className="WorkRatingModal"
    >
      <div className="WorkRatingModal__worker">
        <Avatar src={profileImgUrl} icon={<UserOutlined />} className="WorkRatingModal__worker__avatar" />
        <div className="WorkRatingModal__worker__name">{worker.displayName}</div>
      </div>
      <div className="WorkRatingModal__rating">
        <div className="WorkRatingModal__rating__text">工事の完成度はいかがでしたか？</div>
        <RatingButtons rating={workRating.quality} type="quality" setRating={handleSetWorkRating} />
        <div className="WorkRatingModal__rating__text">納期は遵守していましたか？</div>
        <RatingButtons rating={workRating.speed} type="speed" setRating={handleSetWorkRating} />
        <div className="WorkRatingModal__rating__text">コミュニケーションはスムーズでしたか？</div>
        <RatingButtons rating={workRating.communication} type="communication" setRating={handleSetWorkRating} />

        <TextArea rows={4} placeholder="ここにコメントを入力してください" ref={textAreaRef} />
      </div>
    </Modal>
  );
};

export default WorkRatingModal;
