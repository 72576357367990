import { Card, Divider } from "antd";
import CommentModel from "common/models/CommentModel";

type Props = {
  comments: CommentModel[];
};
/**コメント */
export function ProjectDetailComment({ comments }: Props): JSX.Element {
  return (
    <Card title="コメント" bordered={false} style={{ marginTop: "1rem" }}>
      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
        {comments.map((comment) => {
          return (
            <div key={comment.id}>
              <div>{comment.sentDate.formatted()}</div>
              <div>{`${comment.displayName}(${comment.senderType.label})`}</div>
              <p>{comment.body}</p>
              <Divider />
            </div>
          );
        })}
      </div>
    </Card>
  );
}
