import { Firestore, doc } from "firebase/firestore";
import { DBModel } from "common/@types/global";
import { db, auth } from "@/lib/firebase";
import { setDocument, CreateBaseParams, setCreateInfo } from "@/common/utils/firestoreUtil";

const collectionName = "reworkDetails";

type CreateParams = {
  projectId: string;
  reworkId: string;
  id: string;
} & CreateBaseParams<DBModel.ReworkDetail>;

export class ReworkDetailRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getDocRef(projectId: string, reworkId: string, id: string) {
    return doc(this.firestore, `projects/${projectId}/reworks/${reworkId}/${collectionName}/${id}`);
  }

  async create({ projectId, reworkId, id, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams) {
    if (!projectId) {
      throw new Error("projectId is required");
    }
    if (!reworkId) {
      throw new Error("reworkId is required");
    }
    if (!id) {
      throw new Error("id is required");
    }

    const docRef = this.getDocRef(projectId, reworkId, id);
    const newReworkDetail: DBModel.ReworkDetail = setCreateInfo({ data, uid });
    return setDocument(docRef, newReworkDetail, writeBatch);
  }
}

const reworkDetailRepository = new ReworkDetailRepository(db);
export default reworkDetailRepository;
