import workerRepository from "@/repositories/WorkerRepository";
import WorkerIdentityVerifyStatus from "common/models/worker/IdentityVerifyStatus";
import WorkerStatus from "common/models/worker/Status";

/**審査NGにする（退会処分） */
export async function deny(workerId: string): Promise<void> {
  const worker = {
    identityVerifyStatus: WorkerIdentityVerifyStatus.Deny.value,
    status: WorkerStatus.Ban.value,
  };
  await workerRepository.update({ id: workerId, data: worker });
}
