import { ssoClient } from "@/lib/axios";

function getCookie(name: string): string {
  let cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name + "=") === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return "";
}

/**SSOのステータスチェック */
export async function checkAuthStatus(): Promise<string> {
  const { data } = await ssoClient.post<{ customToken: string }>(
    "/checkAuthStatus",
    {
      targetFirebaseProject: "remodela-pro-app",
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("csrft")}`,
      },
    }
  );
  return data.customToken;
}
