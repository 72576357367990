export default class Status {
  private static _values = new Array<Status>();

  public static readonly Estimating = new Status(10, "見積中", "#6EB7DE");
  public static readonly Recruiting = new Status(20, "募集中", "#6E86DE");
  public static readonly AwaitingOrder = new Status(30, "発注待ち", "#eb7070");
  public static readonly AwaitingConstruction = new Status(40, "工事待ち", "#EB9770");
  public static readonly UnderConstruction = new Status(50, "工事中", "#E9D486");
  public static readonly AwaitingInspection = new Status(60, "検収待ち", "#7ED668");
  public static readonly Completed = new Status(70, "完了", "#55B171");

  public static readonly Nothing = new Status(0, "なし");

  public static readonly Deleted = new Status(-1, "削除", "#aaaaaa");
  public static readonly Withdrawn = new Status(-2, "取り下げ", "#555555");
  public static readonly Expired = new Status(-3, "期限切れ", "#ff0000");

  private constructor(
    public readonly value: number,
    public readonly label: string,
    public readonly color: string = "black"
  ) {
    if (value !== 0) {
      Status._values.push(this);
    }
  }

  static fromValue(value: number): Status {
    return this.values.find((v) => v.value === value) || Status.Nothing;
  }

  static get values(): Status[] {
    return this._values;
  }
}
