import { useState } from "react";
import { fetchConstructionImageUrls } from "../api/fetchConstructionImageUrls";
import { useMount } from "react-use";
import EstimateModel from "common/models/EstimateModel";

type HookResult = {
  isLoading: boolean;
  estimateWithConstructionImageUrls: EstimateModel;
};

export function useConstructionImageUrls(estimate: EstimateModel): HookResult {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [estimateWithConstructionImageUrls, setEstimateWithConstructionImageUrls] = useState<EstimateModel>(estimate);

  useMount(async () => {
    try {
      let promiseTask = [];
      if (estimate.beforeImageStoragePaths) {
        promiseTask.push(fetchConstructionImageUrls(estimate.beforeImageStoragePaths));
      }
      if (estimate.afterImageStoragePaths) {
        promiseTask.push(fetchConstructionImageUrls(estimate.afterImageStoragePaths));
      }
      const [beforeImageUrls, afterImageUrls] = await Promise.all(promiseTask);

      setEstimateWithConstructionImageUrls(estimate.withConstructionImageUrls({ beforeImageUrls, afterImageUrls }));
      setIsLoading(false);
    } catch (error) {
      console.error(`工事写真のURL取得に失敗しました(${estimate.id})`, error);
    }
  });

  return {
    isLoading,
    estimateWithConstructionImageUrls,
  };
}
