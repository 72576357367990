import { Card, Row, Col } from "antd";
import ProjectModel from "common/models/ProjectModel";

type Props = {
  project: ProjectModel;
};
/**日付関連 */
export function ProjectDetailDates({ project }: Props): JSX.Element {
  return (
    <Card title="日付関連" size="small" type="inner" style={{ marginTop: 16 }}>
      <Row style={{ padding: "0.2rem 0" }}>
        <Col span={10}>発注日</Col>
        <Col span={14}>{project.orderDate?.formatted("yyyy/MM/dd")}</Col>
      </Row>

      <Row style={{ padding: "0.2rem 0" }}>
        <Col span={10}>引渡日</Col>
        <Col span={14}>{project.handoverDate?.formatted("yyyy/MM/dd")}</Col>
      </Row>

      <Row style={{ padding: "0.2rem 0" }}>
        <Col span={10}>最終見積日</Col>
        <Col span={14}>{project.lastEstimatedDate?.formatted("yyyy/MM/dd")}</Col>
      </Row>

      <div style={{ margin: "0.5rem 0" }}>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>作業可能開始日（施主が入力）</Col>
          <Col span={14}>{project.workableStartDate?.formatted("yyyy/MM/dd")}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>作業可能終了日（施主が入力）</Col>
          <Col span={14}>{project.workableEndDate?.formatted("yyyy/MM/dd")}</Col>
        </Row>
      </div>

      <div style={{ marginBottom: "0.5rem" }}>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>作業開始日(職人が入力)</Col>
          <Col span={14}>{project.workStartDate?.formatted("yyyy/MM/dd")}</Col>
        </Row>
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>作業終了日(職人が入力)</Col>
          <Col span={14}>{project.workEndDate?.formatted("yyyy/MM/dd")}</Col>
        </Row>
      </div>
      <Row style={{ padding: "0.2rem 0" }}>
        <Col span={10}>検収依頼日</Col>
        <Col span={14}>{project.inspectedDate?.formatted("yyyy/MM/dd")}</Col>
      </Row>
      <Row style={{ padding: "0.2rem 0" }}>
        <Col span={10}>検収完了日</Col>
        <Col span={14}>{project.inspectedDate?.formatted("yyyy/MM/dd")}</Col>
      </Row>
    </Card>
  );
}
