import { Flex, Card } from "antd";
import EstimateTable from "@/features/estimate/EstimateTable";
import TotalAmountTable from "@/features/estimate/TotalAmountTable";
import EstimateListModel from "common/models/EstimateListModel";

type Props = {
  estimates: EstimateListModel | null;
};
/**見積 */
export function ProjectDetailEstimate({ estimates }: Props): JSX.Element {
  return (
    <Card title="見積内容" bordered={false}>
      {estimates !== null && (
        <Flex vertical gap={"1rem"}>
          <TotalAmountTable estimates={estimates} />
          <EstimateTable estimates={estimates} />
        </Flex>
      )}
    </Card>
  );
}
