import { useEffect } from "react";
import { message } from "antd";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "@/lib/firebase";
import { checkAuthStatus } from "@/repositories/authRepository";
import ownerRepository from "@/repositories/OwnerRepository";
import { getOwnerModelFromAuthUser } from "@/models/AuthUserModel";

export default function SignIn(): JSX.Element {
  useEffect(() => {
    checkAuthStatus()
      .then((customToken) => {
        // SSO中ならカスタムトークンでサインイン
        signInWithCustomToken(auth, customToken).then(async (userCredential) => {
          try {
            const owner = await getOwnerModelFromAuthUser(userCredential.user);
            await ownerRepository.upsert({ id: owner.id, data: owner.toJSON() });
          } catch (error: any) {
            console.error(error);
            message.error("依頼者情報が保存できませんでした。管理者に連絡してください。");
          }
        });
      })
      .catch(() => {
        // SSOしてないなら原状回復にページ遷移
        const encodedCurrentPath = encodeURIComponent(window.location.href);
        const remodelaUrl = process.env.REACT_APP_REMODELA_BASE_URL;

        window.location.href = `${remodelaUrl}/signin?redirect=${encodedCurrentPath}`;
      });
  }, []);

  return <div>Loading...</div>;
}
