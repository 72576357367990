export default class Address {
  private readonly _value: DBModel.Address;

  constructor(value: DBModel.Address) {
    this._value = value;
  }

  get value(): DBModel.Address {
    return this._value;
  }

  get postalCode(): string {
    return this._value.postalCode;
  }

  get formatted(): string {
    const { prefecture, city, streetNumber, extraAddress } = this._value;
    return `${prefecture}${city}${streetNumber || ""}\n${extraAddress || ""}`;
  }
}
