import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchImageUrls } from "./api/fetchImageUrls";
import Carousel from "../../components/Carousel";
import "./Gallery.scss";

type Props = {
  propertyPhotoStoragePaths: string[] | null;
  floorPlanStoragePath: string | null;
};

/**間取り図と工事箇所の写真のURLを取得します */
const fetchFloorPlanAndPropertyPhotoImgUrls = async (
  floorPlanStoragePath: string | null,
  propertyPhotoStoragePaths: string[] | null
): Promise<string[]> => {
  let storagePaths = [];
  if (propertyPhotoStoragePaths) {
    storagePaths.push(...propertyPhotoStoragePaths);
  }
  if (floorPlanStoragePath) {
    storagePaths.push(floorPlanStoragePath);
  }

  return await fetchImageUrls(storagePaths);
};

const Gallery: React.FC<Props> = ({ propertyPhotoStoragePaths, floorPlanStoragePath }) => {
  const { data: imageUrls } = useQuery({
    queryKey: ["imageUrls"],
    queryFn: async () => fetchFloorPlanAndPropertyPhotoImgUrls(floorPlanStoragePath, propertyPhotoStoragePaths),
    retry: 0,
    throwOnError: true,
    initialData: [],
  });

  return (
    <section className="Gallery">
      <div className="Gallery__container">
        {imageUrls.length > 0 ? (
          <div className="Gallery__container__carouselContainer">
            <Carousel imageUrls={imageUrls} />
          </div>
        ) : (
          <p className="Gallery__container__noImageText">
            間取り図、工事箇所の写真が
            <br />
            登録されていません。
          </p>
        )}
      </div>
    </section>
  );
};

export default Gallery;
