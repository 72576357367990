import { User } from "firebase/auth";
import OwnerModel from "common/models/OwnerModel";

type AuthUserProps = {
  id: string;
  owner: OwnerModel;
  isAdmin: boolean;
};
export async function getOwnerModelFromAuthUser(user: User): Promise<OwnerModel> {
  const tokenResult = await user.getIdTokenResult();
  const customClaims = tokenResult.claims as { [key: string]: string };

  return new OwnerModel({
    id: user.uid,
    companyId: customClaims["companyId"] || "",
    companyName: customClaims["company-companyName"] || "",
    companyAddress: {
      prefecture: customClaims["company-state"] || "",
      city: customClaims["company-address1"] || "",
      streetNumberAndBuilding: customClaims["company-address2"] || "",
    },
    companyPhoneNumber: customClaims["company-phoneNumber"] || "",
    userId: customClaims["userId"] || "",
    firstName: customClaims["user-staffFirstName"] || "",
    lastName: customClaims["user-staffLastName"] || "",
  });
}

export async function getAuthUser(user: User): Promise<AuthUserModel> {
  const id = user.uid;
  const owner = await getOwnerModelFromAuthUser(user);
  const tokenResult = await user.getIdTokenResult();

  const authUser: AuthUserProps = {
    id,
    owner,
    isAdmin: !!tokenResult.claims.isAdmin,
  };
  return new AuthUserModel(authUser);
}

// 認証ユーザーのモデル
export default class AuthUserModel {
  public readonly _id: string;
  public readonly owner: OwnerModel;
  public readonly isAdmin: boolean;

  constructor(authUser: AuthUserProps) {
    const defaultValues = {
      id: "",
      owner: new OwnerModel({}),
      isAdmin: false,
    };
    const mergedData = { ...defaultValues, ...authUser };

    this._id = mergedData.id;
    this.owner = mergedData.owner;
    this.isAdmin = mergedData.isAdmin;
  }

  static default() {
    return new AuthUserModel({
      id: "",
      owner: new OwnerModel({}),
      isAdmin: false,
    });
  }

  get id(): string {
    return this._id;
  }

  get isLoggedIn(): boolean {
    return this._id !== "";
  }
}
