import { Firestore, doc, collection, query, orderBy, onSnapshot, Unsubscribe, getDocs } from "firebase/firestore";
import EstimateModel from "common/models/EstimateModel";
import { DBModel } from "common/@types/global";
import {
  setCreateInfo,
  createDocument,
  deleteDocument,
  CreateBaseParams,
  DeleteBaseParams,
  setDocument,
} from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "estimates";

type CreateParams = {
  projectId: string;
  id?: string;
} & CreateBaseParams<DBModel.Estimate>;

type DeleteParams = {
  projectId: string;
} & DeleteBaseParams;

export class EstimateRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string, id: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findAll(projectId: string): Promise<EstimateModel[]> {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    q = query(q, orderBy("rowNumber", "asc"));
    const querySnapshot = await getDocs(q);
    const estimates: EstimateModel[] = [];
    querySnapshot.forEach((doc) => {
      const estimate = { ...doc.data(), id: doc.id } as DBModel.Estimate;
      estimates.push(new EstimateModel(estimate));
    });
    return estimates;
  }

  subscribeByProjectId(projectId: string, callback: (estimates: EstimateModel[]) => void): Unsubscribe {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    q = query(q, orderBy("rowNumber", "asc"));
    return onSnapshot(q, (querySnapshot) => {
      const estimates: EstimateModel[] = [];
      querySnapshot.forEach((doc) => {
        const estimate = { ...doc.data(), id: doc.id } as DBModel.Estimate;
        estimates.push(new EstimateModel(estimate));
      });
      callback(estimates);
    });
  }

  async create({ projectId, id, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams) {
    if (!id) {
      const colRef = this.getColRef(projectId);
      const newEstimate: DBModel.Estimate = setCreateInfo({ data, uid });
      return createDocument(colRef, newEstimate, writeBatch);
    } else {
      const docRef = this.getDocRef(projectId, id);
      const newEstimate: DBModel.Estimate = setCreateInfo({ data, uid });
      return setDocument(docRef, newEstimate, writeBatch);
    }
  }

  async delete({ projectId, id, writeBatch }: DeleteParams) {
    if (!projectId) {
      throw new Error("ProjectId is required");
    }
    if (!id) {
      throw new Error("id is required");
    }

    const docRef = this.getDocRef(projectId, id);
    await deleteDocument(docRef, writeBatch);
  }
}

const estimateRepository = new EstimateRepository(db);
export default estimateRepository;
