import { Button } from "antd";
import { LeftOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import MessageArea from "@/features/chat/MessageArea";
import Message from "@/features/chat/Message";
import MessageInput from "@/features/chat/MessageInput";
import useChatRoom from "@/hooks/useChatRoom";
import { useMount } from "react-use";
import { upsertOwnerLastReadDate } from "./api/upsertOwnerLastReadDate";
import ProjectModel from "common/models/ProjectModel";
import "./ChatDetail.scss";

type Props = {
  project: ProjectModel;
  chatRoomId: string;
  setChatRoomId: (chatRoomId: string | null) => void;
};

const ChatDetail: React.FC<Props> = ({ project, chatRoomId, setChatRoomId }) => {
  const { chatRoom } = useChatRoom(project.id, chatRoomId);

  // チャット詳細が表示されたら、依頼者の最終既読日時を更新する
  useMount(async () => {
    await upsertOwnerLastReadDate({ projectId: project.id, chatRoomId });
  });

  return (
    <div className="ChatDetail">
      <div className="ChatDetail__header">
        <Button className="ChatDetail__header__icon" type="link" onClick={() => setChatRoomId(null)}>
          <LeftOutlined />
        </Button>
        <div className="ChatDetail__header__title">{chatRoom.partnerName}</div>
      </div>
      <div className="ChatDetail__caution">
        <div className="ChatDetail__caution__text">
          <ExclamationCircleFilled className="ChatDetail__caution__icon" />
          職人マッチングを通さずに直接工事を発注することは利用規約に基づき固くお断りしております。
        </div>
      </div>
      <MessageArea
        projectId={project.id}
        chatRoomId={chatRoomId}
        renderMessage={(message) => (
          <Message message={message} chatRoom={chatRoom} key={message.id}>
            <Message.Body />
            <Message.Files />
          </Message>
        )}
      />
      {project.canChat ? (
        <MessageInput projectId={project.id} chatRoomId={chatRoomId} />
      ) : (
        <div className="ChatDetail__text">※案件が完了しているため、チャットを送信できません。</div>
      )}
    </div>
  );
};

export default ChatDetail;
