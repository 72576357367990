import React from "react";
import CommentModel from "common/models/CommentModel";
import Button from "@/components/Button";
import { Drawer, message } from "antd";
import { Input } from "@/components/FormItems";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Comment from "@/features/comment/Comment";
import ProjectModel from "common/models/ProjectModel";
import "./CommentDrawer.scss";

type Props = {
  project: ProjectModel;
  comments: CommentModel[];
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  onDelete?: (comment: CommentModel) => void;
};

export type FormData = {
  body: string;
};

const CommentDrawer: React.FC<Props> = ({ project, comments, open, onClose, onSubmit, onDelete }) => {
  const methods = useForm<FormData>();
  const { handleSubmit, setValue } = methods;
  const [loading, setLoading] = React.useState(false);

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    try {
      await onSubmit(data);
      setValue("body", "");
      message.success("コメントを投稿しました");
    } catch (error: any) {
      message.success(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer className="CommentDrawer" placement="bottom" onClose={onClose} open={open} height="95%" title="コメント">
      <FormProvider {...methods}>
        <div className="CommentDrawer__list">
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} onDelete={onDelete} canComment={project.canComment} />
          ))}
        </div>
        {project.canComment ? (
          <form onSubmit={handleSubmit(handleFormSubmit)} className="CommentDrawer__form">
            <div className="CommentDrawer__field">
              <Input name="body" />
              <Button type="primary" htmlType="submit">
                送信
              </Button>
            </div>
          </form>
        ) : (
          <div className="CommentDrawer__text">
            ※募集が終了しているため、コメントできません。マッチング後、職人とのやり取りはチャットで行うことができます。
          </div>
        )}
      </FormProvider>
    </Drawer>
  );
};

export default CommentDrawer;
