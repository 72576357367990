import { Firestore, collection, doc, getDoc, Unsubscribe, onSnapshot } from "firebase/firestore";
import AdminProjectSettingModel from "common/models/AdminProjectSettingModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "adminProjectSettings";

export class AdminProjectSettingRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef() {
    return collection(this.firestore, `admin/v1/${collectionName}`);
  }

  private getDocRef(id: string) {
    const colPath = this.getColRef().path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findById(id: string): Promise<AdminProjectSettingModel | null> {
    const docRef = this.getDocRef(id);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const adminProjectSetting = { ...snapshot.data(), id: snapshot.id } as DBModel.AdminProjectSetting;
    return new AdminProjectSettingModel(adminProjectSetting);
  }

  async upsert({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.AdminProjectSetting>) {
    if (!id) {
      throw new Error("id is required");
    }
    const docRef = this.getDocRef(id);

    // docが存在する場合は更新、存在しない場合は作成
    const snapshot = await getDoc(docRef);
    let upsertAdminProjectSetting: DBModel.AdminProjectSetting;
    if (snapshot.exists()) {
      upsertAdminProjectSetting = setUpdateInfo({ data, uid });
    } else {
      upsertAdminProjectSetting = setCreateInfo({ data, uid });
    }

    await setDocument(docRef, upsertAdminProjectSetting, writeBatch);
  }
}

const adminProjectSettingRepository = new AdminProjectSettingRepository(db);
export default adminProjectSettingRepository;
