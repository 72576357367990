import React from "react";
import CommentModel from "common/models/CommentModel";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useAuthUserState } from "@/context/AuthUserContext";
import "./Comment.scss";

type Props = {
  comment: CommentModel;
  onDelete?: (comment: CommentModel) => void;
  canComment?: boolean; // コメントできるステータスかどうか
};
const Comment: React.FC<Props> = ({ comment, onDelete, canComment }) => {
  const { authUser } = useAuthUserState();
  /**コメントを削除できるかどうか */
  const canDelete = onDelete && canComment && comment.isSameUser(authUser.id);

  return (
    <div className="Comment">
      <div className="Comment__name">{comment.displayName}</div>
      <div className="Comment__content">
        <div className="Comment__body">{comment.body}</div>
        <div className="Comment__footer">
          <div className="Comment__date">{comment.sentDate.formatted()}</div>
          {canDelete && <Button type="text" icon={<DeleteOutlined />} onClick={() => onDelete?.(comment)} />}
        </div>
      </div>
    </div>
  );
};

export default Comment;
