import { useNavigate } from "react-router-dom";
import WorkerModel from "common/models/WorkerModel";
import Button from "@/components/Button";
import { LeftOutlined } from "@ant-design/icons";
import useModal from "@/hooks/useModal";
import BankAccountsModal from "./BankAccountsModal";
import "./WorkerHeader.scss";

type Props = {
  worker: WorkerModel;
};

const WorkerHeader = ({ worker }: Props) => {
  const navigate = useNavigate();
  const modalHook = useModal();

  return (
    <>
      <header className="WorkerHeader">
        <div className="WorkerHeader__leftContainer">
          <Button type="link" onClick={() => navigate(`/admin/workers`)} className="WorkerHeader__leftContainer__icon">
            <LeftOutlined />
          </Button>
          <div className="WorkerHeader__leftContainer__title">
            <h1 className="WorkerHeader__leftContainer__title__workerName">
              {worker.companyName && <>{worker.companyName || ""}　</>}
              {worker.fullName}（{worker.businessTypeLabel}）
            </h1>
          </div>
        </div>
        <div className="WorkerHeader__rightContainer">
          <Button type="primary" onClick={modalHook.handleOpen}>
            口座情報を確認する
          </Button>
        </div>
      </header>

      <BankAccountsModal workerId={worker.id} open={modalHook.open} handleClose={modalHook.handleClose} />
    </>
  );
};

export default WorkerHeader;
