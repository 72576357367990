import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Row, Col, Dropdown, Card } from "antd";
import { Layout, Button, message } from "antd";
import { SERVICE_NAME } from "common/constant";
import { auth } from "@/lib/firebase";
import { signOut } from "firebase/auth";
import { useAuthUserState } from "@/context/AuthUserContext";
import "./AdminBaseLayout.scss";

const { Header, Content } = Layout;

type Props = {
  children: React.ReactNode;
};
/**ベースレイアウト（管理画面） */
export default function AdminBaseLayout({ children }: Props): JSX.Element {
  const { authUser } = useAuthUserState();
  const navigate = useNavigate();

  const toLoginPage = (e: any) => {
    e.preventDefault();
    navigate("admin/signin");
  };

  function logout() {
    signOut(auth);
    message.info("ログアウトしました。");
    navigate("admin/signin");
  }

  function AuthUserCard() {
    return (
      <Card bordered={false} style={{ width: 320 }}>
        <p>管理者ID: {authUser.id}</p>
        <Button type="primary" block onClick={logout}>
          ログアウト
        </Button>
      </Card>
    );
  }

  return (
    <div className="AdminBaseLayout">
      <Layout style={{ overflow: "unset" }}>
        <Header style={{ padding: 0, background: "#ddd" }} className="AdminBaseLayout__header">
          <Row align="middle" justify="space-between">
            <div></div>
            <h1 className="logo">{SERVICE_NAME} 管理画面</h1>
            <Col>
              {authUser.isLoggedIn ? (
                <Dropdown dropdownRender={AuthUserCard} trigger={["click"]}>
                  <UserOutlined className="user" />
                </Dropdown>
              ) : (
                <Button type="link" onClick={toLoginPage}>
                  ログイン
                </Button>
              )}
            </Col>
          </Row>
        </Header>

        <Content className={"AdminBaseLayout__content"}>
          <>{children}</>
        </Content>
      </Layout>
    </div>
  );
}
