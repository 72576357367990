import { useQuery } from "@tanstack/react-query";
import AmountReferenceTable from "./AmountReferenceTable";
import { fetchSearchEstimates } from "./api/fetchSearchEstimates";
import SearchEstimateListModel from "common/models/SearchEstimateListModel";
import Button from "@/components/Button";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import EstimateModel from "common/models/EstimateModel";
import { useState } from "react";
import { message } from "antd";

type Props = {
  estimate: EstimateModel;
  prefecture: string;
  close: () => void;
};
/**相場参照ポップオーバーのコンテンツ */
const AmountReferenceContent = ({ estimate, prefecture, close }: Props) => {
  const {
    data: searchEstimates,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["searchEstimates", estimate.id],
    queryFn: async () => {
      try {
        const searchEstimates = await fetchSearchEstimates(estimate.itemName, prefecture);
        return searchEstimates;
      } catch (error) {
        message.error("データの取得に失敗しました");
        console.error(error);
        return new SearchEstimateListModel([]);
      }
    },
    retry: 0,
    throwOnError: true,
    initialData: new SearchEstimateListModel([]),
  });
  // 前回取得時のitemNameを状態として保持する
  const [lastItemName, setLastItemName] = useState(estimate.itemName);
  const handleRefetch = async () => {
    await refetch();
    setLastItemName(estimate.itemName); // データの再取得後に、lastItemNameを更新
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <Button
          icon={<SyncOutlined />}
          onClick={() => handleRefetch()}
          disabled={!estimate.itemName || estimate.itemName === lastItemName}
        >
          再取得
        </Button>
        <Button type="text" shape="circle" icon={<CloseOutlined />} onClick={close} />
      </div>
      <AmountReferenceTable searchEstimates={searchEstimates} isFetching={isFetching} />
      <div style={{ marginTop: "12px" }}>
        ※ REMODELA株式会社が運営する「リモデラ事務」の取引データをもとに表示しています。
      </div>
    </div>
  );
};

export default AmountReferenceContent;
