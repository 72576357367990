import ProjectModel from "common/models/ProjectModel";
import { Descriptions } from "antd";
import { useQuery } from "@tanstack/react-query";
import * as keyInfoService from "@/services/keyInfoService";
import Button from "@/components/Button";
import CarouselModal from "@/components/CarouselModal";
import useModal from "@/hooks/useModal";
import WorkableDateRangeModal from "./WorkableDateRangeModal";
import { fetchResident } from "@/features/project/api/fetchResident";
import "./ProjectBasic.scss";

type Props = {
  project: ProjectModel;
};

const ProjectBasic = ({ project }: Props) => {
  const keyLocationModalHook = useModal();
  const returnKeyLocationModalHook = useModal();
  const workableDateRangeModalHook = useModal();
  const { data: keyInfo } = useQuery({
    queryKey: ["keyInfo"],
    queryFn: () => keyInfoService.fetchKeyInfo(project.id),
    retry: 0,
    throwOnError: true,
    enabled: !!project.id,
    initialData: null,
  });
  const { data: resident } = useQuery({
    queryKey: ["resident"],
    queryFn: () => fetchResident(project.id),
    retry: 0,
    throwOnError: true,
    enabled: !!project.id && !!project.hasResident,
    initialData: null,
  });

  return (
    <>
      <section className="ProjectBasic">
        <h2>基本情報</h2>
        <Descriptions
          colon={false}
          column={1}
          size="small"
          className="ProjectBasic__descriptions"
          labelStyle={{ width: "120px" }}
        >
          <Descriptions.Item label="住所">{project.addressFormatted}</Descriptions.Item>
          <Descriptions.Item label="駐車場の場所">{project.freeParkingLocation}</Descriptions.Item>
          <Descriptions.Item label="オートロック">{project.hasAutoLockText}</Descriptions.Item>
          {project.hasAutoLock && (
            <Descriptions.Item label="オートロック解除方法">{keyInfo?.autoLockNo || ""}</Descriptions.Item>
          )}
          <Descriptions.Item label="エレベーター">{project.hasElevatorText}</Descriptions.Item>
          <Descriptions.Item label="入居状況">{project.hasResidentText}</Descriptions.Item>
          {project.hasResident ? (
            <>
              {resident && (
                <>
                  <Descriptions.Item label="入居者の氏名">{resident.name}</Descriptions.Item>
                  <Descriptions.Item label="入居者の連絡先">{resident.phoneNumber}</Descriptions.Item>
                  <Descriptions.Item label="入居者の備考">{resident.note}</Descriptions.Item>
                </>
              )}
            </>
          ) : (
            <>
              {project.isElectricityConnected !== null && (
                <>
                  <Descriptions.Item label="電気の通電状況">
                    {project.isElectricityConnectedText}
                    {!project.isElectricityConnected && <>（{project.hasRequestElectricityConnectionText}）</>}
                  </Descriptions.Item>
                </>
              )}
              {project.isWaterConnected !== null && (
                <>
                  <Descriptions.Item label="水道の開通状況">
                    {project.isWaterConnectedText}
                    {!project.isWaterConnected && <>（{project.hasRequestWaterConnectionText}）</>}
                  </Descriptions.Item>
                </>
              )}
            </>
          )}
          {keyInfo && (
            <>
              <Descriptions.Item label="鍵の置き場所">
                {keyInfo.keyLocation && (
                  <span className="ProjectBasic__descriptions__keyLocation__text">{keyInfo.keyLocation}</span>
                )}
                {keyInfo.keyLocationImageStoragePaths && keyInfo.keyLocationImageStoragePaths.length > 0 && (
                  <Button ghost type="primary" size="small" onClick={keyLocationModalHook.handleOpen}>
                    写真
                  </Button>
                )}
              </Descriptions.Item>
              {keyInfo.returnKeyLocation && (
                <Descriptions.Item label="返却された鍵の置き場所">
                  <span className="ProjectBasic__descriptions__returnKeyLocation__text">
                    {keyInfo.returnKeyLocation}
                  </span>
                  {keyInfo.returnKeyLocationImageStoragePaths &&
                    keyInfo.returnKeyLocationImageStoragePaths.length > 0 && (
                      <Button ghost type="primary" size="small" onClick={returnKeyLocationModalHook.handleOpen}>
                        写真
                      </Button>
                    )}
                </Descriptions.Item>
              )}
            </>
          )}
          {project.workableDateRange && (
            <Descriptions.Item label="工事可能期間">
              <span className="ProjectBasic__descriptions__workableDateRange__text">{project.workableDateRange}</span>
              {project.canEditWorkableDateRange && (
                <Button ghost type="primary" size="small" onClick={workableDateRangeModalHook.handleOpen}>
                  編集
                </Button>
              )}
            </Descriptions.Item>
          )}
          {project.workDateRange && <Descriptions.Item label="工事期間">{project.workDateRange}</Descriptions.Item>}
          <Descriptions.Item label="見積日">{project.lastEstimatedDate?.formattedDay() || "なし"}</Descriptions.Item>
          <Descriptions.Item label="発注日">{project.orderDate?.formattedDay() || "なし"}</Descriptions.Item>
          <Descriptions.Item label="検収日">{project.inspectedDate?.formattedDay() || "なし"}</Descriptions.Item>
          <Descriptions.Item label="引渡日">{project.handoverDate?.formattedDay() || "なし"}</Descriptions.Item>
          <Descriptions.Item label="担当者">{project.projectManager}</Descriptions.Item>
          <Descriptions.Item label="職人への要望">{project.ownerRequest}</Descriptions.Item>
        </Descriptions>
      </section>

      <CarouselModal
        title={"鍵の置き場所"}
        storagePaths={keyInfo?.keyLocationImageStoragePaths || []}
        modalHook={keyLocationModalHook}
      />
      <CarouselModal
        title={"返却された鍵の置き場所"}
        storagePaths={keyInfo?.returnKeyLocationImageStoragePaths || []}
        modalHook={returnKeyLocationModalHook}
      />
      <WorkableDateRangeModal project={project} modalHook={workableDateRangeModalHook} />
    </>
  );
};
export default ProjectBasic;
