import { useState } from "react";
import InvoiceDetailLineModel from "common/models/InvoiceDetailLineModel";
import { useMount } from "react-use";
import { fetchInvoiceDetailLines } from "../api/fetchInvoiceDetailLines";

type HookResult = {
  isLoading: boolean;
  invoiceDetailLineList: InvoiceDetailLineModel[];
};
export function useInvoiceDetailLineList(invoiceId: string): HookResult {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceDetailLineList, setInvoiceDetailLineList] = useState<InvoiceDetailLineModel[]>([]);

  useMount(async () => {
    try {
      const invoiceDetailLineList = await fetchInvoiceDetailLines(invoiceId);
      setInvoiceDetailLineList(invoiceDetailLineList);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch InvoiceDetailLineList", error);
    }
  });

  return {
    isLoading,
    invoiceDetailLineList,
  };
}
