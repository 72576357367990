import { DBModel } from "../@types/global";

export default class ReworkModel {
  public readonly id: string;
  public readonly projectId: string; // 再施工を行う案件のID

  constructor(data: Partial<DBModel.Rework>) {
    const defaultValues = {
      id: "",
      projectId: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = defaultValues.id;
    this.projectId = mergedData.projectId;
  }

  /**別の案件での再施工である */
  isOtherProject(projectId: string): boolean {
    return this.projectId !== projectId;
  }

  toJSON(): DBModel.Rework {
    return {
      projectId: this.projectId,
    };
  }
}
