import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import SearchEstimateModel from "common/models/SearchEstimateModel";
import SearchEstimateListModel from "common/models/SearchEstimateListModel";
import "./AmountReferenceTable.scss";

type Props = {
  searchEstimates: SearchEstimateListModel;
  isFetching: boolean;
};
/**相場参照テーブル */
const AmountReferenceTable = ({ searchEstimates, isFetching }: Props) => {
  const itemColumns: ColumnsType<SearchEstimateModel> = [
    {
      title: "作成日",
      dataIndex: "estimateCreatedAt",
      width: 80,
      render: (_, searchEstimate, index) => <div>{searchEstimate.estimateCreatedAt.formatted("yyyy/MM/dd")}</div>,
    },
    {
      title: "都道府県",
      dataIndex: "prefecture",
      width: 60,
      render: (_, searchEstimate, index) => <div>{searchEstimate.prefecture}</div>,
    },
    {
      title: "大項目",
      dataIndex: "majorItem",
      width: 200,
      render: (_, searchEstimate, index) => <div>{searchEstimate.majorItem}</div>,
    },
    {
      title: "中項目",
      dataIndex: "minorItem",
      width: 200,
      render: (_, searchEstimate, index) => <div>{searchEstimate.minorItem}</div>,
    },
    {
      title: "単位",
      dataIndex: "unit",
      width: 50,
      render: (_, searchEstimate, index) => <div>{searchEstimate.unit}</div>,
    },
    {
      title: "単価",
      dataIndex: "unitPrice",
      width: 80,
      render: (_, searchEstimate, index) => <div style={{ textAlign: "end" }}>{searchEstimate.unitPriceFormatted}</div>,
    },
  ];

  return (
    <Table
      className="AmountReferenceTable"
      pagination={false}
      loading={isFetching}
      style={{ width: "1000px" }}
      scroll={{ y: 200 }}
      columns={itemColumns}
      dataSource={searchEstimates.value}
      rowKey="id"
    />
  );
};

export default AmountReferenceTable;
