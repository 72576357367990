export default class UserType {
  private static _values = new Array<UserType>();

  public static readonly Owner = new UserType("owner", "依頼者");
  public static readonly Worker = new UserType("worker", "職人");
  public static readonly Nothing = new UserType("", "なし");

  private constructor(public readonly value: "owner" | "worker" | "", public readonly label: string) {
    if (value !== "") {
      UserType._values.push(this);
    }
  }

  static fromValue(value: string): UserType {
    return this._values.find((v) => v.value === value) || UserType.Nothing;
  }
}
