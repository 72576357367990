import projectRepository from "@/repositories/ProjectRepository";
import Status from "common/models/project/Status";

/**案件を削除ステータスにします */
export async function softDeleteProject(projectId: string): Promise<void> {
  const updateProject = {
    status: Status.Deleted.value,
  };

  await projectRepository.update({ id: projectId, data: updateProject });
}
