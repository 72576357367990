import { Firestore, doc, collection, query, orderBy, Unsubscribe, onSnapshot } from "firebase/firestore";
import InvoiceModel from "common/models/InvoiceModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "invoices";

export class InvoiceRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef() {
    return collection(this.firestore, `admin/v1/${collectionName}`);
  }

  private getDocRef(id: string) {
    const colPath = this.getColRef().path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  subscribeDocs(callback: (invoices: InvoiceModel[]) => void): Unsubscribe {
    const colRef = this.getColRef();
    let q = query(colRef);
    q = query(q, orderBy("createdAt", "desc"));
    return onSnapshot(q, (querySnapshot) => {
      const invoices: InvoiceModel[] = [];
      querySnapshot.forEach((doc) => {
        const invoice = { ...doc.data(), id: doc.id } as DBModel.Invoice;
        invoices.push(new InvoiceModel(invoice));
      });
      callback(invoices);
    });
  }

  async update({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.Invoice>) {
    if (!id) {
      throw new Error("id is required");
    }
    const docRef = this.getDocRef(id);
    const updateInvoice: DBModel.Invoice = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateInvoice, writeBatch);
  }
}

const invoiceRepository = new InvoiceRepository(db);
export default invoiceRepository;
