import { DeepPartial } from "./../../web/src/common/utils/firestoreUtil";
import { DBModel } from "../@types/global";
import CustomDateTime from "./shared/CustomDateTime";

type AdditionalParams = {
  partnerName: string;
  latestMessagePreviewText?: string;
  latestMessageSentDate?: CustomDateTime;
};

export default class ChatRoomModel {
  public readonly id: string; // workerIdと同じid
  public readonly ownerLastReadDate: CustomDateTime | null; // 依頼者が最後にチャットを読んだ日時
  public readonly workerLastReadDate: CustomDateTime | null; // 職人が最後にチャットを読んだ日時

  public readonly partnerName: string; // チャット相手の名前
  public readonly latestMessagePreviewText: string | null; // 最新チャットメッセージのプレビューテキスト
  public readonly latestMessageSentDate: CustomDateTime | null; // 最新のチャットメッセージの送信日時

  constructor(data: Partial<DBModel.ChatRoom> & DeepPartial<AdditionalParams>) {
    const defaultValues = {
      id: "",
      ownerLastReadDate: null,
      workerLastReadDate: null,

      partnerName: "",
      partnerFirstName: "",
      latestMessagePreviewText: null,
      latestMessageSentDate: null,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.ownerLastReadDate = mergedData.ownerLastReadDate ? new CustomDateTime(mergedData.ownerLastReadDate) : null;
    this.workerLastReadDate = mergedData.workerLastReadDate ? new CustomDateTime(mergedData.workerLastReadDate) : null;

    this.partnerName = mergedData.partnerName;
    this.latestMessagePreviewText = mergedData.latestMessagePreviewText;
    this.latestMessageSentDate = mergedData.latestMessageSentDate;
  }

  withAdditionalParams(params: AdditionalParams): ChatRoomModel {
    return new ChatRoomModel({
      id: this.id,
      ...this.toJSON(),
      ...params,
    });
  }

  toJSON(): DBModel.ChatRoom {
    return {
      ownerLastReadDate: this.ownerLastReadDate?.value || null,
      workerLastReadDate: this.workerLastReadDate?.value || null,
    };
  }
}
