import React from "react";
import { Tag, TagProps } from "antd";
import Status from "common/models/project/Status";

type StatusTagProps = {
  status: Status;
} & TagProps;

const StatusTag: React.FC<StatusTagProps> = ({ ...props }) => {
  return (
    <Tag {...props} bordered={false} color={props.status.color}>
      {props.status.label}
    </Tag>
  );
};

export { StatusTag };
