import { FC } from "react";
import { Spin } from "antd";
import { useLoading } from "@/context/LoadingContext";

const GlobalSpinner: FC = () => {
  const { isLoading, text } = useLoading();

  if (!isLoading) return null;

  return <Spin tip={text} size="large" fullscreen />;
};

export default GlobalSpinner;
