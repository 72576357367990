import { useParams } from "react-router-dom";
import useProject from "@/hooks/useProject";
import Gallery from "@/features/project/Gallery";
import ProjectBasic from "@/features/project/ProjectBasic";
import Comments from "@/features/comment/Comments";
import Estimates from "@/features/estimate/Estimates";
import RelatedWorkers from "@/features/worker/RelatedWorkers";
import Chat from "@/features/chat/Chat";
import ProjectHeader from "@/features/project/ProjectHeader";
import useEstimateList from "@/hooks/useEstimateList";
import { useEffect } from "react";
import ExpiredModal from "@/features/project/ExpiredModal";
import useModal from "@/hooks/useModal";
import "./ProjectId.scss";

/**案件詳細ページ */
export default function ProjectId(): JSX.Element {
  const expiredModalHook = useModal();
  const { projectId } = useParams<{ projectId: string }>();
  if (!projectId) {
    throw new Error(`projectId is required.`);
  }
  const { project } = useProject(projectId);
  const { estimates } = useEstimateList(projectId);

  useEffect(() => {
    if (!project) {
      return;
    }
    if (project.isExpired) {
      expiredModalHook.handleOpen();
    }
  }, [project]);

  if (!project || !estimates) {
    return <></>;
  }

  return (
    <>
      <div className="ProjectId">
        <ProjectHeader project={project} estimates={estimates} />
        <div className="ProjectId__detail">
          <section className="ProjectId__detail__overView">
            <div className="ProjectId__detail__overView__gallery">
              <Gallery
                propertyPhotoStoragePaths={project.propertyPhotoStoragePaths}
                floorPlanStoragePath={project.floorPlanStoragePath}
              />
            </div>
            <div className="ProjectId__detail__overView__basicInfo">
              <ProjectBasic project={project} />
            </div>
          </section>
          <Estimates projectId={projectId} project={project} estimates={estimates} />
          {project.hasApplyWorker && <RelatedWorkers project={project} estimates={estimates} />}
          <Comments project={project} />
          <Chat project={project} />
        </div>
      </div>

      <ExpiredModal project={project} modalHook={expiredModalHook} />
    </>
  );
}
