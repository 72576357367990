import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import jaJP from "antd/es/locale/ja_JP";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/config/react-query";
import { AuthUserProvider } from "@/context/AuthUserContext";
import { LoadingProvider } from "@/context/LoadingContext";
import GlobalSpinner from "@/components/GlobalSpinner";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider
      locale={jaJP}
      theme={{
        token: {
          colorPrimary: "#10692A",
          colorLink: "#10692A",
          colorLinkHover: "#10692A",
        },
      }}
    >
      <LoadingProvider>
        <GlobalSpinner />
        <AuthUserProvider>
          <App />
        </AuthUserProvider>
      </LoadingProvider>
    </ConfigProvider>
  </QueryClientProvider>
);
