import projectRepository from "@/repositories/ProjectRepository";
import { writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { FormData } from "../EstimateDrawer";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import * as estimateService from "@/services/estimateService";
import EstimateModel from "common/models/EstimateModel";
import EstimateListModel from "common/models/EstimateListModel";

type Params = {
  projectId: string;
  data: FormData;
};

/**見積を保存します */
export async function saveEstimates({ projectId, data }: Params) {
  const batch = writeBatch(db);

  const updateProject = {
    ...data.project,
    lastEstimatedDate: serverTimestamp() as Timestamp,
  };
  await projectRepository.update({ id: projectId, data: updateProject, writeBatch: batch });

  const estimates = data.estimates.map((estimate) => new EstimateModel(estimate));

  await estimateService.deleteAndCreateEstimates({
    estimates: new EstimateListModel(estimates),
    projectId,
    writeBatch: batch,
  });

  await batch.commit();
}
