import { Card, Row, Col, Modal } from "antd";
import { WorkerWithApplyInfo } from "@/features/admin/project/hooks/useProjectDetail";
import useModal from "@/hooks/useModal";
import Button from "@/components/Button";
import ProposedEstimateTable from "@/features/worker/ProposedEstimateTable";
import { useEffect, useState } from "react";
import EstimateListModel from "common/models/EstimateListModel";

type Props = {
  orderedWorkerWithApplyInfo?: WorkerWithApplyInfo;
  applyingWorkerWithApplyInfo?: WorkerWithApplyInfo;
  estimates: EstimateListModel | null;
};
/**受注した職人 or 応募中（応募しているが見送られていない）の職人 */
export function ProjectDetailWorker({
  orderedWorkerWithApplyInfo,
  applyingWorkerWithApplyInfo,
  estimates,
}: Props): JSX.Element {
  const title = orderedWorkerWithApplyInfo ? "受注した職人" : "応募中の職人";
  const workerWithApplyInfo = orderedWorkerWithApplyInfo || applyingWorkerWithApplyInfo;
  const modalHook = useModal();
  // 変更されている見積のID
  const [changedEstimateIds, setChangedEstimateIds] = useState<string[]>([]);

  useEffect(() => {
    if (!workerWithApplyInfo?.proposedEstimates || !estimates) {
      return;
    }
    const changedEstimateIds = workerWithApplyInfo.proposedEstimates.changedEstimateIds(estimates);
    setChangedEstimateIds(changedEstimateIds);
  }, [workerWithApplyInfo, estimates]);

  return (
    <>
      <Card title={title} bordered={false} style={{ marginTop: "1rem" }}>
        {workerWithApplyInfo && (
          <>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>表示名</Col>
              <Col span={14}>{workerWithApplyInfo.worker.displayName}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>氏名</Col>
              <Col span={14}>{workerWithApplyInfo.worker.fullName}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>電話番号</Col>
              <Col span={14}>{workerWithApplyInfo.worker.phoneNumber}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>住所</Col>
              <Col span={14}>{workerWithApplyInfo.worker.address.formatted}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>評価</Col>
              <Col span={14}>{workerWithApplyInfo.worker.rating}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>工事期間</Col>
              <Col span={14}>{workerWithApplyInfo.apply.workDateRange}</Col>
            </Row>
            <Row style={{ padding: "0.2rem 0" }}>
              <Col span={10}>提案内容</Col>
              <Col span={14}>
                {!workerWithApplyInfo.proposedEstimates.isEmpty ? (
                  <Button type="primary" ghost size="small" onClick={modalHook.handleOpen}>
                    詳細
                  </Button>
                ) : (
                  "なし"
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>

      {workerWithApplyInfo && !workerWithApplyInfo.proposedEstimates.isEmpty && (
        <Modal
          centered
          open={modalHook.open}
          onCancel={modalHook.handleClose}
          title={"提案内容"}
          width={700}
          footer={null}
        >
          <div style={{ marginTop: "20px" }}>
            <ProposedEstimateTable
              proposedEstimates={workerWithApplyInfo.proposedEstimates}
              changedEstimateIds={changedEstimateIds}
            />
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 4px 0 0" }}>
              <span style={{ marginRight: "8px" }}>合計金額 (税別)</span>
              {workerWithApplyInfo.proposedEstimates.totalAmountFormatted}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
