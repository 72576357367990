import { UserOutlined } from "@ant-design/icons";
import { Row, Col, Dropdown, Card } from "antd";
import { Layout, Button } from "antd";
import { SERVICE_NAME } from "common/constant";
import { useAuthUserState } from "@/context/AuthUserContext";
import "./BaseLayout.scss";

const { Header, Footer, Content } = Layout;

type Props = {
  children: JSX.Element;
};

export default function BaseLayout({ children }: Props): JSX.Element {
  const { authUser } = useAuthUserState();

  const toRemodelaSigninPage = (e: any): void => {
    e.preventDefault();

    window.location.href = `${process.env.REACT_APP_REMODELA_BASE_URL}/signin`;
  };

  const AuthUserCard = (): JSX.Element => {
    return (
      <Card bordered={false} style={{ width: 320 }}>
        <p>ID: {authUser.owner.id}</p>
        <p>名前: {authUser.owner.fullName}</p>
      </Card>
    );
  };

  return (
    <div className="BaseLayout">
      <Layout style={{ overflow: "unset" }}>
        <Header style={{ padding: 0, background: "#ddd" }} className="BaseLayout__header">
          <Row align="middle" justify="end">
            <h1 className="logo">{SERVICE_NAME}</h1>
            <Col>
              {authUser.isLoggedIn ? (
                <Dropdown dropdownRender={AuthUserCard} trigger={["click"]}>
                  <UserOutlined className="user" />
                </Dropdown>
              ) : (
                <Button type="link" onClick={toRemodelaSigninPage}>
                  ログイン
                </Button>
              )}
            </Col>
          </Row>
        </Header>

        <Content className="BaseLayout__content">
          <>{children}</>
        </Content>

        <Footer>
          <p>© {SERVICE_NAME} ALL Rights Reserved.</p>
        </Footer>
      </Layout>
    </div>
  );
}
