import { DBModel } from "../@types/global";

export default class AdminWorkerSettingModel {
  public readonly id: string; // workerIdと同じ
  public readonly antiSocialCheckResult: string; // 反社チェック結果
  public readonly note: string; // 管理者用メモ

  constructor(data: Partial<DBModel.AdminWorkerSetting>) {
    const defaultValues = {
      id: "",
      antiSocialCheckResult: "none",
      note: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.antiSocialCheckResult = mergedData.antiSocialCheckResult;
    this.note = mergedData.note;
  }

  toJSON(): DBModel.AdminWorkerSetting {
    return {
      antiSocialCheckResult: this.antiSocialCheckResult,
      note: this.note,
    };
  }
}
