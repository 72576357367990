import { Card, Flex } from "antd";
import { useProjectDetail } from "./hooks/useProjectDetail";
import { ProjectDetailBasic } from "./ProjectDetailBasic";
import { ProjectDetailKeyInfo } from "./ProjectDetailKeyInfo";
import { ProjectDetailResident } from "./ProjectDetailResident";
import { ProjectDetailDates } from "./ProjectDetailDates";
import { ProjectDetailAnalytics } from "./ProjectDetailAnalytics";
import { ProjectDetailWorker } from "./ProjectDetailWorker";
import { ProjectDetailCanceledWorkers } from "./ProjectDetailCanceledWorkers";
import { ProjectDetailComment } from "./ProjectDetailComment";
import { ProjectDetailChat } from "./ProjectDetailChat";
import { ProjectDetailEstimate } from "./ProjectDetailEstimate";
import { ProjectDetailOwner } from "./ProjectDetailOwner";
import ProjectDetailAdminNote from "./ProjectDetailAdminNote";

type Props = {
  projectId: string;
};
/**案件詳細 */
export function ProjectDetail({ projectId }: Props): JSX.Element {
  const {
    project,
    owner,
    keyInfo,
    resident,
    orderedWorkerWithApplyInfo,
    applyingWorkerWithApplyInfo,
    canceledWorkers,
    comments,
    estimates,
    chatRoomWithMessagesList,
    analyticsWithWorkers,
  } = useProjectDetail(projectId);

  if (!project) {
    return <>loading...</>;
  }

  return (
    <>
      <Flex align="center" vertical style={{ marginBottom: "2rem" }}>
        <h1>{project.projectName}</h1>
        <div>{project.addressFormatted}</div>
      </Flex>

      <Flex justify="center" align="start">
        {/* 案件情報 */}
        <div style={{ width: "50%", margin: "1rem" }}>
          <Card title="案件情報" bordered={false}>
            <ProjectDetailBasic project={project} />
            <ProjectDetailOwner owner={owner} />
            <ProjectDetailKeyInfo keyInfo={keyInfo} />
            <ProjectDetailResident resident={resident} />
            <ProjectDetailDates project={project} />
          </Card>
        </div>

        {/* 閲覧情報, 職人, コメント, チャット */}
        <div style={{ width: "50%", margin: "1rem" }}>
          <ProjectDetailAnalytics analyticsWithWorkers={analyticsWithWorkers} />
          <ProjectDetailWorker
            orderedWorkerWithApplyInfo={orderedWorkerWithApplyInfo}
            applyingWorkerWithApplyInfo={applyingWorkerWithApplyInfo}
            estimates={estimates}
          />
          <ProjectDetailCanceledWorkers canceledWorkers={canceledWorkers} />
          <ProjectDetailComment comments={comments} />
          <ProjectDetailChat chatRoomWithMessagesList={chatRoomWithMessagesList} />
        </div>
      </Flex>

      <Flex justify="center" align="start">
        {/* 見積*/}
        <div style={{ width: "100%", margin: "1rem" }}>
          <ProjectDetailEstimate estimates={estimates} />
        </div>
      </Flex>

      <ProjectDetailAdminNote projectId={projectId} />
    </>
  );
}
