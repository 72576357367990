import React, { useState } from "react";
import { Modal, message, Table } from "antd";
import BankAccountModel from "common/models/BankAccountModel";
import bankAccountRepository from "@/repositories/BankAccountRepository";
import { useMount } from "react-use";
import { ColumnsType } from "antd/es/table";

type Props = {
  workerId: string;
  open: boolean;
  handleClose: () => void;
};
/**口座情報モーダル */
const BankAccountsModal: React.FC<Props> = ({ workerId, open, handleClose }) => {
  const [bankAccounts, setBankAccounts] = useState<BankAccountModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useMount(async () => {
    try {
      setIsLoading(true);
      const bankAccounts = await bankAccountRepository.findAll(workerId);
      setBankAccounts(bankAccounts);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      message.error("口座情報の取得に失敗しました");
    }
  });

  const columns: ColumnsType<BankAccountModel> = [
    {
      title: "金融機関名",
      dataIndex: "bankName",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.bankName}</div>,
    },
    {
      title: "金融機関コード",
      dataIndex: "bankCode",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.bankCode}</div>,
    },
    {
      title: "支店名",
      dataIndex: "branchName",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.branchName}</div>,
    },
    {
      title: "支店コード",
      dataIndex: "branchCode",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.branchCode}</div>,
    },
    {
      title: "口座種別",
      dataIndex: "bankType",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.bankType}</div>,
    },
    {
      title: "口座番号",
      dataIndex: "bankAccountNum",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.bankAccountNum}</div>,
    },
    {
      title: "口座名義",
      dataIndex: "bankAccountName",
      width: 100,
      render: (_, bankAccount) => <div>{bankAccount.bankAccountName}</div>,
    },
  ];

  return (
    <>
      <Modal centered open={open} onCancel={handleClose} title={"口座情報"} width={1000} footer={null}>
        <Table
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={bankAccounts}
          rowKey={(record) => record.id}
        />
      </Modal>
    </>
  );
};

export default BankAccountsModal;
