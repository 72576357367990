import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { ModalHook } from "@/hooks/useModal";
import Button from "@/components/Button";

type Props = {
  modalHook: ModalHook;
  projectId: string;
};
export type FormData = {
  reworkReason: string;
};
/**再施工保証モーダル */
const ReworkAssuranceModal = ({ modalHook, projectId }: Props): JSX.Element => {
  const navigate = useNavigate();
  const notionReworkAssuranceUrl = `${process.env.REACT_APP_NOTION_BASE_URL}/b1cf6fa10d2047c1a9140594d26f1b68`;

  const handleClose = () => {
    modalHook.handleClose();
    navigate(`/projects/${projectId}`);
  };

  return (
    <>
      <Modal
        title="リモデラ再施工保証のご案内"
        centered
        width={600}
        open={modalHook.open}
        onOk={handleClose}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleClose}
      >
        <div style={{ textAlign: "center", margin: "20px" }}>
          <p>
            再施工を依頼してから48時間以内に職人から同意を得られない場合、
            <br />
            REMODELA社までご相談ください。
          </p>
          <p>
            ※リモデラ再施工保証の詳細は
            <Button
              type="link"
              size="small"
              style={{ padding: 0 }}
              onClick={() => {
                window.open(notionReworkAssuranceUrl, "_blank", "noopener,noreferrer");
              }}
            >
              こちら
            </Button>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ReworkAssuranceModal;
