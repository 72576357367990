import React, { ForwardedRef } from "react";
import { Radio as AntdRadio } from "antd";
import { RadioGroupProps as AntdRadioGroupProps } from "antd/es/radio";
import { ControllerRenderProps } from "react-hook-form";
import FormItem from "./FormItem";

type RadioGroupProps = {
  name: string;
  label?: string;
  defaultValue?: string | number;
  rules?: any;
} & AntdRadioGroupProps;

type MyRadioGroupProps = {
  field: ControllerRenderProps<string[]>;
} & RadioGroupProps;

const MyRadio = React.forwardRef(({ field, ...props }: MyRadioGroupProps, ref: ForwardedRef<HTMLDivElement>) => (
  <AntdRadio.Group
    {...props}
    ref={ref}
    onChange={(e) => {
      if (props.onChange) {
        props.onChange(e);
      }
      field.onChange(e.target.value);
    }}
  >
    <AntdRadio value={true}>あり</AntdRadio>
    <AntdRadio value={false}>なし</AntdRadio>
  </AntdRadio.Group>
));

const Radio: React.FC<RadioGroupProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem name={name} label={label} defaultValue={defaultValue} rules={rules} component={MyRadio} {...props} />
  );
};

export default Radio;
