import { Firestore, doc, collection, getDoc, query, Query, where, orderBy, getDocs } from "firebase/firestore";
import CommentModel from "common/models/CommentModel";
import { DBModel } from "common/@types/global";
import {
  createDocument,
  setDocument,
  CreateBaseParams,
  UpdateBaseParams,
  setCreateInfo,
  setUpdateInfo,
} from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "comments";

type CreateParams = {
  projectId: string;
} & CreateBaseParams<DBModel.Comment>;

type UpsertParams = {
  projectId: string;
} & UpdateBaseParams<DBModel.Comment>;

export class CommentRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string, id: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findByProjectId(projectId: string): Promise<CommentModel[]> {
    const colRef = this.getColRef(projectId);
    let q = query(colRef);
    q = query(q, where("deleted", "==", false));
    q = query(q, orderBy("createdAt", "asc"));
    return this.findBy(q);
  }

  private async findBy(q: Query): Promise<CommentModel[]> {
    const querySnapshot = await getDocs(q);
    const comments: CommentModel[] = [];
    querySnapshot.forEach((doc) => {
      const comment = { ...doc.data(), id: doc.id } as DBModel.Comment;
      comments.push(new CommentModel(comment));
    });
    return comments;
  }

  async create({ projectId, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams): Promise<string> {
    if (!projectId) {
      throw new Error("ProjectId is required");
    }

    const colRef = this.getColRef(projectId);
    const newComment: DBModel.Comment = setCreateInfo({ data, uid });
    return createDocument(colRef, newComment, writeBatch);
  }

  async upsert({ projectId, id, data, uid = auth.currentUser?.uid, writeBatch }: UpsertParams) {
    if (!projectId) {
      throw new Error("ProjectId is required");
    }
    if (!id) {
      throw new Error("Comment id  is required");
    }

    const docRef = this.getDocRef(projectId, id);

    // docが存在する場合は更新、存在しない場合は作成
    const snapshot = await getDoc(docRef);
    let upsertComment: DBModel.Comment;
    if (snapshot.exists()) {
      upsertComment = setUpdateInfo({ data, uid });
    } else {
      upsertComment = setCreateInfo({ data, uid });
    }
    await setDocument(docRef, upsertComment, writeBatch);
  }
}

const commentRepository = new CommentRepository(db);
export default commentRepository;
