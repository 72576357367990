import {
  Firestore,
  doc,
  collection,
  query,
  orderBy,
  Unsubscribe,
  onSnapshot,
  limit,
  Query,
  getDocs,
} from "firebase/firestore";
import MessageModel from "common/models/MessageModel";
import { DBModel } from "common/@types/global";
import { setDocument, setCreateInfo, CreateBaseParams, createDocument } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "messages";

type SubscribeParams = {
  projectId: string;
  chatRoomId: string;
  uid: string;
  callback: (messages: MessageModel[]) => void;
  onGetNewMessage: (message: MessageModel) => void;
  onSendNewMessage: (message: MessageModel) => void;
};

type CreateParams = {
  projectId: string;
  chatRoomId: string;
  id?: string;
} & CreateBaseParams<DBModel.Message>;

export class MessageRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string, chatRoomId: string) {
    return collection(this.firestore, `projects/${projectId}/chatRooms/${chatRoomId}/${collectionName}`);
  }

  private getDocRef(projectId: string, chatRoomId: string, id: string) {
    const colPath = this.getColRef(projectId, chatRoomId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async getUniqueId(projectId: string, chatRoomId: string): Promise<string> {
    const docRef = doc(this.getColRef(projectId, chatRoomId));
    return docRef.id;
  }

  async findBy(q: Query): Promise<MessageModel[]> {
    const querySnapshot = await getDocs(q);
    const messages: MessageModel[] = [];
    querySnapshot.forEach((doc) => {
      const message = { ...doc.data(), id: doc.id } as DBModel.Message;
      messages.push(new MessageModel(message));
    });
    return messages;
  }

  subscribe({ projectId, chatRoomId, uid, callback, onGetNewMessage, onSendNewMessage }: SubscribeParams): Unsubscribe {
    const colRef = this.getColRef(projectId, chatRoomId);
    const q = query(colRef, orderBy("sentDate", "asc"));
    let initialLoadCompleted = false;

    return onSnapshot(q, (querySnapshot) => {
      const messages: MessageModel[] = [];
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data(), id: doc.id } as DBModel.Message;
        messages.push(new MessageModel(data));
      });
      callback(messages);

      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const message = { ...change.doc.data(), id: change.doc.id } as DBModel.Message;
          const newMessage = new MessageModel(message);

          if (initialLoadCompleted) {
            const isMyMessage = uid === newMessage.senderUid;
            if (isMyMessage) {
              onSendNewMessage(newMessage);
            } else {
              onGetNewMessage(newMessage);
            }
          }
        }
      });

      if (!initialLoadCompleted) {
        initialLoadCompleted = true;
      }
    });
  }

  /**最新のメッセージを取得する */
  async findLatest(projectId: string, chatRoomId: string): Promise<MessageModel> {
    const colRef = this.getColRef(projectId, chatRoomId);
    let q = query(colRef);
    q = query(q, orderBy("sentDate", "desc"));
    q = query(q, limit(1));
    const messages = await this.findBy(q);
    return messages[0];
  }

  async findAllByProjectIdChatRoomId(projectId: string, chatRoomId: string): Promise<MessageModel[]> {
    const colRef = this.getColRef(projectId, chatRoomId);
    const q = query(colRef, orderBy("createdAt", "asc"));
    return await this.findBy(q);
  }

  /**messageドキュメントを作成する（idを指定しない場合、idは自動生成される） */
  async create({ projectId, chatRoomId, id, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams) {
    if (!projectId) {
      throw new Error("ProjectId is required");
    }
    if (!chatRoomId) {
      throw new Error("ChatRoomId is required");
    }

    if (!id) {
      const colRef = this.getColRef(projectId, chatRoomId);
      const newMessage: DBModel.Message = setCreateInfo({ data, uid });
      return createDocument(colRef, newMessage, writeBatch);
    } else {
      const docRef = this.getDocRef(projectId, chatRoomId, id);
      const newMessage: DBModel.Message = setCreateInfo({ data, uid });
      return setDocument(docRef, newMessage, writeBatch);
    }
  }
}

const messageRepository = new MessageRepository(db);
export default messageRepository;
