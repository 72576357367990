import { useState } from "react";
import { useMount } from "react-use";
import PublicWorkerDetailModel from "common/models/PublicWorkerDetailModel";
import * as workerService from "@/services/workerService";

type HookResult = {
  worker: PublicWorkerDetailModel | null;
};

const usePublicWorkerDetail = (workerId: string): HookResult => {
  const [worker, setWorker] = useState<PublicWorkerDetailModel | null>(null);

  useMount(async () => {
    const workers = await workerService.fetchWorkerPublicDetails([workerId]);
    const worker = workers[0];
    setWorker(worker);
  });

  return {
    worker,
  };
};

export default usePublicWorkerDetail;
