import chatRoomRepository from "@/repositories/ChatRoomRepository";
import { serverTimestamp, Timestamp } from "firebase/firestore";

type Params = {
  projectId: string;
  chatRoomId: string;
};

/** 依頼者の最終既読日時を更新する */
export async function upsertOwnerLastReadDate({ projectId, chatRoomId }: Params) {
  const updateChatRoom = {
    ownerLastReadDate: serverTimestamp() as Timestamp,
  };

  await chatRoomRepository.update({ id: chatRoomId, projectId, data: updateChatRoom });
}
