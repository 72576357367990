import { useState } from "react";
import ReworkModel from "common/models/ReworkModel";
import reworkRepository from "@/repositories/ReworkRepository";
import { useMount } from "react-use";

type HookResult = {
  latestRework?: ReworkModel | null;
};

const useLatestRework = (projectId: string): HookResult => {
  const [latestRework, setLatestRework] = useState<ReworkModel | null>();

  useMount(async () => {
    const latestRework = await reworkRepository.findLatest(projectId);
    setLatestRework(latestRework);
  });

  return { latestRework };
};
export default useLatestRework;
