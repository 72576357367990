import ProjectModel from "common/models/ProjectModel";
import { Modal, message } from "antd";
import useModal from "@/hooks/useModal";
import { softDeleteProject } from "./api/softDeleteProject";

type Props = {
  project: ProjectModel;
  modalHook: ReturnType<typeof useModal>;
};

/**案件削除モーダル */
const DeleteProjectModal = ({ project, modalHook }: Props) => {
  /**案件を削除ステータスにします */
  const handleDeleteProject = async () => {
    try {
      await softDeleteProject(project.id);
      message.success("案件を削除しました。");
      modalHook.handleClose();
    } catch (error: any) {
      console.log(error.message);
      message.error("案件の削除に失敗しました。");
    }
  };

  return (
    <>
      <Modal
        width={600}
        centered
        title="案件削除"
        open={modalHook.open}
        onCancel={modalHook.handleClose}
        okText={"削除"}
        okButtonProps={{ danger: true }}
        onOk={handleDeleteProject}
      >
        <div style={{ marginTop: "20px", marginBottom: "40px", textAlign: "center" }}>
          <p style={{ marginBottom: "20px" }}>こちらの案件を削除しますか？</p>
          <div>
            <h3 style={{ marginBottom: "8px" }}>{project.projectName}</h3>
            <div>{project.address.formatted}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteProjectModal;
