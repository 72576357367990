import React from "react";
import { Button as AntdButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";

type ButtonProps = {} & AntdButtonProps;

const Button: React.FC<ButtonProps> = ({ ...props }) => {
  return <AntdButton {...props} />;
};

export default Button;
