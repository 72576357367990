import { auth } from "@/lib/firebase";
import commentRepository from "@/repositories/CommentRepository";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import CommentModel from "common/models/CommentModel";

type Params = {
  projectId: string;
  body: string;
};

/**コメントを作成します */
export async function createComment({ projectId, body }: Params): Promise<void> {
  const newComment = CommentModel.ofOwner({
    body,
    displayName: "依頼者",
    senderUid: auth.currentUser?.uid || "",
    sentDate: serverTimestamp() as Timestamp,
  });
  await commentRepository.create({ projectId, data: newComment.toJSON() });
}
