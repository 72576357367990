import React, { useState } from "react";
import { Input as AntdInput, InputNumber as AntdInputNumber } from "antd";
import { InputProps as AntdInputProps } from "antd/es/input";
import { ControllerRenderProps } from "react-hook-form";
import FormItem from "./FormItem";

type InputProps = {
  name: string;
  label?: string;
  defaultValue?: string | number;
  rules?: any;
} & AntdInputProps;

const Input: React.FC<InputProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem name={name} label={label} defaultValue={defaultValue} rules={rules} component={AntdInput} {...props} />
  );
};

const InputPassword: React.FC<InputProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem
      name={name}
      label={label}
      defaultValue={defaultValue}
      rules={rules}
      component={AntdInput.Password}
      {...props}
    />
  );
};

const InputNumber: React.FC<InputProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem
      name={name}
      label={label}
      defaultValue={defaultValue}
      rules={rules}
      component={AntdInputNumber}
      {...props}
    />
  );
};

type InputPriceProps = {
  field: ControllerRenderProps;
} & AntdInputProps;

// focusが外れたときにカンマを追加する、field.valueは数値型で保持する
const AntInputPrice = ({ field, ...props }: InputPriceProps) => {
  const [displayValue, setDisplayValue] = useState(field.value ? Number(field.value).toLocaleString() : "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, "");
    if (!isNaN(Number(newValue))) {
      // 料金なので数値型にしてセット
      field.onChange(Number(newValue));
      setDisplayValue(e.target.value); // カンマなしの値で表示用ステートを更新
    }
  };

  const handleBlur = () => {
    setDisplayValue(field.value ? Number(field.value).toLocaleString() : ""); // カンマを追加
  };

  return (
    <AntdInput
      {...field}
      {...props}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      suffix="円"
      // prefix="¥"
      className="InputPrice"
    />
  );
};

const InputPrice: React.FC<InputProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem
      name={name}
      label={label}
      defaultValue={defaultValue}
      rules={rules}
      component={AntInputPrice}
      {...props}
    />
  );
};

export { Input, InputPassword, InputNumber, InputPrice };
