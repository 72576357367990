import imageCompression from "browser-image-compression";
import { pdfjs } from "react-pdf";
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**画像ファイルを圧縮する */
export async function compressImageFile(file: File) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const compressedImageFile = await imageCompression(file, options);
  return compressedImageFile;
}

/**PDFのblobからpngのblobに変換する */
export async function convertPdfBlobToPngBlob(pdfBlob: Blob): Promise<Blob[]> {
  // blobをUint8Arrayに変換
  const arrayBuffer = await new Response(pdfBlob).arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  // PDFを取得
  const pdf: PDFDocumentProxy = await getDocument({ data: uint8Array }).promise;

  const convertedBlobs: Blob[] = [];
  // PDFのページごとにpngのblobを生成
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const pngBlob = await createPngBlobs(page);
    convertedBlobs.push(pngBlob);
  }

  return convertedBlobs;
}

/**PDFのページからpngのblobを生成 */
async function createPngBlobs(page: PDFPageProxy): Promise<Blob> {
  const viewport = page.getViewport({ scale: 2 }); // 2倍の解像度で取得（1倍は画質が荒い + 解像度高いとパフォーマンスに影響する）
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  await page.render({ canvasContext: context!, viewport }).promise; // canvasに描画

  const blob = await new Promise<Blob>((resolve: any) => canvas.toBlob(resolve, "image/webp")); // 実行速度と画質のバランスを考慮してwebpのblobに変換
  return blob;
}
