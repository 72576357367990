import ProjectModel from "common/models/ProjectModel";
import { Modal, Row, Col } from "antd";
import useModal from "@/hooks/useModal";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Textarea, CheckboxGroup } from "@/components/FormItems";
import { message } from "antd";
import Button from "@/components/Button";
import WithdrawalQuestionnaireModel from "common/models/WithdrawalQuestionnaireModel";
import { withdrawProject } from "./api/withdrawProject";

type Props = {
  project: ProjectModel;
  modalHook: ReturnType<typeof useModal>;
};
export type FormData = {
  withdrawalQuestionnaire: DBModel.WithdrawalQuestionnaire;
};

/**取り下げアンケートモーダル */
const WithdrawalQuestionnaireModal = ({ project, modalHook }: Props) => {
  const methods = useForm<FormData>({
    defaultValues: {
      withdrawalQuestionnaire: new WithdrawalQuestionnaireModel({}).toJSON(),
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const values = watch();

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await withdrawProject(project.id, data);
      message.success("案件を取り下げました。");
      modalHook.handleClose();
    } catch (err: any) {
      console.log(err.message);
      message.error("案件の取り下げに失敗しました。");
    }
  };

  return (
    <>
      <Modal
        width={600}
        centered
        title="取り下げ理由アンケート"
        open={modalHook.open}
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={handleSubmit(handleFormSubmit)}>
            送信
          </Button>,
        ]}
      >
        <FormProvider {...methods}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <CheckboxGroup
                name="withdrawalQuestionnaire.reasons"
                label="取り下げの理由（複数回答可）"
                style={{ display: "flex", flexDirection: "column" }}
                options={[
                  { label: "他で依頼先が見つかった", value: "他で依頼先が見つかった" },
                  { label: "工事がなくなった", value: "工事がなくなった" },
                  { label: "応募がこなかった", value: "応募がこなかった" },
                  { label: "依頼したい職人がいなかった", value: "依頼したい職人がいなかった" },
                  { label: "使い方がよくわからない", value: "使い方がよくわからない" },
                  { label: "案件登録誤り", value: "案件登録誤り" },
                  { label: "その他（自由記入）", value: "その他（自由記入）" },
                ]}
                rules={{ required: "取り下げの理由を少なくとも1つ選択してください" }}
                onChange={(values: any) => {
                  setValue("withdrawalQuestionnaire.reasons", values);
                  setValue("withdrawalQuestionnaire.otherReason", values.includes("その他（自由記入）") ? "" : null);
                }}
              />
            </Col>
            {!!values.withdrawalQuestionnaire.reasons &&
              values.withdrawalQuestionnaire.reasons.includes("その他（自由記入）") && (
                <Col span={24} style={{ marginTop: "8px" }}>
                  <Textarea name="withdrawalQuestionnaire.otherReason" />
                </Col>
              )}
            <Col span={24}>
              <Textarea
                name="withdrawalQuestionnaire.opinion"
                label="その他サービスに関して改善点や追加してほしい内容等ございましたらご意見をお寄せください（自由記入）"
              />
            </Col>
          </Row>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </FormProvider>
      </Modal>
    </>
  );
};

export default WithdrawalQuestionnaireModal;
