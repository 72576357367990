import { Firestore, doc, getDoc } from "firebase/firestore";
import ResidentModel from "common/models/ResidentModel";
import { DBModel } from "common/@types/global";
import { setDocument, CreateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";

const collectionName = "residents";

type UpsertParams = {
  projectId: string;
} & CreateBaseParams<DBModel.Resident>;

export class ResidentRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getDocRef(projectId: string) {
    return doc(this.firestore, `projects/${projectId}/${collectionName}/detail`);
  }

  async findByProjectId(projectId: string): Promise<ResidentModel | null> {
    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const data = { ...snapshot.data(), id: snapshot.id } as DBModel.Resident;
    return new ResidentModel(data);
  }

  async upsert({ projectId, data, uid = auth.currentUser?.uid, writeBatch }: UpsertParams) {
    if (!projectId) {
      throw new Error("projectId is required");
    }

    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
      const newResident: DBModel.Resident = setCreateInfo({ data, uid });
      await setDocument(docRef, newResident, writeBatch);
      return;
    }

    const updateResident: DBModel.Resident = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateResident, writeBatch);
  }
}

const residentRepository = new ResidentRepository(db);
export default residentRepository;
