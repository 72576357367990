import { Card, Divider } from "antd";
import { ChatRoomWithMessages } from "./hooks/useProjectDetail";

type Props = {
  chatRoomWithMessagesList: ChatRoomWithMessages[];
};
/**チャット */
export function ProjectDetailChat({ chatRoomWithMessagesList }: Props): JSX.Element {
  return (
    <Card title="チャット" bordered={false} style={{ marginTop: "1rem" }}>
      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
        {chatRoomWithMessagesList.map((chatRoomWithMessages, index) => {
          return (
            <div key={chatRoomWithMessages.chatRoom.id}>
              <h4>{`チャットルーム${index + 1}`}</h4>
              {chatRoomWithMessages.messages.map((message) => {
                return (
                  <div key={message.id}>
                    <div>{message.sentDate.formatted()}</div>
                    {message.senderType === "worker" && <div>{`${chatRoomWithMessages.worker.fullName}(職人)`}</div>}
                    {message.senderType === "owner" && <div>依頼者</div>}
                    <p>{message.bodyText}</p>
                    <Divider />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Card>
  );
}
