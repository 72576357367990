import React, { ForwardedRef } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import type { DatePickerProps as AntdDatePickerProps } from "antd/es/date-picker";
import FormItem from "./FormItem";
import dayjs from "dayjs";
import { ControllerRenderProps } from "react-hook-form";

type DatePickerProps = {
  name: string;
  label?: string;
  rules?: any;
} & AntdDatePickerProps;

type MyDatePickerProps = {
  field: ControllerRenderProps;
} & DatePickerProps;

const MyDatePicker = React.forwardRef(({ field, ...props }: MyDatePickerProps, ref: ForwardedRef<any>) => {
  return (
    <AntdDatePicker
      ref={ref}
      {...props}
      value={field.value ? dayjs(field.value) : undefined}
      onChange={(date) => {
        field.onChange(date ? dayjs(date).startOf("day").toDate() : null);
      }}
    />
  );
});

const DatePicker: React.FC<DatePickerProps> = ({ name, label, rules, ...props }) => {
  return <FormItem name={name} label={label} rules={rules} component={MyDatePicker} {...props} />;
};

export default DatePicker;
