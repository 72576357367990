import { storage } from "@/lib/firebase";
import {
  ref,
  uploadBytes,
  getBlob,
  deleteObject,
  getMetadata,
  FullMetadata,
  uploadBytesResumable,
  UploadTask,
  listAll,
} from "firebase/storage";
import { compressImageFile } from "@/utils/fileUtil";

/**指定したstorageパスからBlobを取得する */
export async function fetchBlobs(storagePaths: string[]): Promise<Blob[]> {
  const tasks = [];
  for (const storagePath of storagePaths) {
    const imgRef = ref(storage, storagePath);
    tasks.push(getBlob(imgRef));
  }
  const blobs = await Promise.all(tasks);
  return blobs;
}

/**指定したstorageディレクトリパス内の全ファイルのBlobを取得する */
export async function fetchBlobsByDirPath(storageDirPaths: string): Promise<Blob[]> {
  const directoryRef = ref(storage, storageDirPaths);
  const { items: storageRefs } = await listAll(directoryRef);

  const tasks = storageRefs.map((storageRef) => getBlob(storageRef));
  const blobs = await Promise.all(tasks);
  return blobs;
}

export async function uploadFile(storagePath: string, file: File): Promise<string> {
  if (file.type.includes("image")) {
    file = await compressImageFile(file);
  }
  const imgRef = ref(storage, storagePath);
  await uploadBytes(imgRef, file);
  return storagePath;
}

export async function uploadFileResumable(path: string, file: File, callback: any): Promise<UploadTask> {
  if (file.type.includes("image")) {
    file = await compressImageFile(file);
  }
  const imgRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(imgRef, file);

  uploadTask.on("state_changed", (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 90; // createMessageを考慮し、90%までにする
    callback(progress);
  });
  return uploadTask;
}

/**ファイルを削除する */
export async function deleteFile(path: string): Promise<void> {
  const fileRef = ref(storage, path);
  await deleteObject(fileRef);
}

/**ディレクトリ内のファイルをすべて削除する */
export async function deleteDirectory(DirectoryPath: string): Promise<void> {
  const directoryRef = ref(storage, DirectoryPath);
  const fileList = await listAll(directoryRef);
  for (const fileRef of fileList.items) {
    await deleteObject(fileRef);
  }
}

export async function fetchBlobAndMetaData(path: string): Promise<{ blob: Blob; metaData: FullMetadata }> {
  const storageRef = ref(storage, path);
  const [blob, metaData] = await Promise.all([getBlob(storageRef), getMetadata(storageRef)]);
  return { blob, metaData };
}
