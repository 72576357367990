import projectRepository from "@/repositories/ProjectRepository";
import ProjectModel from "common/models/ProjectModel";
import Status from "common/models/project/Status";

type UpdateProject = {
  workableStartDate: Date;
  workableEndDate: Date;
  status?: number;
};
/**工事可能期間を更新します */
export async function updateWorkableDateRange(project: ProjectModel, workableStartDate: Date, workableEndDate: Date) {
  const updateProject: UpdateProject = {
    workableStartDate,
    workableEndDate,
  };

  // 期限切れの場合はステータスを変更
  if (project.isExpired) {
    updateProject.status = project.hasApplyingWorker
      ? Status.AwaitingOrder.value // 応募中の職人がいる状態で期限切れになっていた場合は発注待ちに変更
      : Status.Recruiting.value;
  }
  await projectRepository.update({ id: project.id, data: updateProject });
}
