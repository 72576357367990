import { DBModel } from "../@types/global";
import CustomDateTime from "./shared/CustomDateTime";

/**応募 */
export default class ApplyModel {
  public readonly id: string; // workerIdと同じ
  public readonly workEndDate: CustomDateTime; // 工事終了日
  public readonly workerId: string; // 職人のID
  public readonly workStartDate: CustomDateTime; // 工事開始日

  public readonly createdAt: CustomDateTime; // 作成日時

  constructor(data: Partial<DBModel.Apply>) {
    const defaultValues = {
      id: "",
      workEndDate: undefined,
      workerId: "",
      workStartDate: undefined,

      createdAt: undefined,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.workEndDate = new CustomDateTime(mergedData.workEndDate);
    this.workerId = mergedData.workerId;
    this.workStartDate = new CustomDateTime(mergedData.workStartDate);

    this.createdAt = new CustomDateTime(mergedData.createdAt);
  }

  /**工事期間 */
  get workDateRange() {
    if (!this.workStartDate || !this.workEndDate) {
      return "";
    }
    return this.workStartDate?.formatted("yyyy/MM/dd") + " ~ " + this.workEndDate?.formatted("yyyy/MM/dd");
  }

  toJSON(): DBModel.Apply {
    return {
      workEndDate: this.workEndDate!.value,
      workerId: this.workerId,
      workStartDate: this.workStartDate!.value,
    };
  }
}
