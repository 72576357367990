import React from "react";
import { Tag } from "antd";
import JobType from "common/models/shared/JobType";
import "./JobTypeTag.scss";

type Props = {
  jobType: string | null;
};

const JobTypeTag: React.FC<Props> = ({ jobType }) => {
  return <Tag className="JobTypeTag">{JobType.fromValue(jobType || "").label}</Tag>;
};

export { JobTypeTag };
