import {
  Firestore,
  doc,
  collection,
  getDoc,
  onSnapshot,
  Unsubscribe,
  where,
  query,
  getDocs,
  orderBy,
} from "firebase/firestore";
import ProjectModel from "common/models/ProjectModel";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";
import ProjectStatus from "common/models/project/Status";

const collectionName = "projects";

export class ProjectRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getDocRef(id: string) {
    return doc(this.firestore, `${collectionName}/${id}`);
  }

  subscribeByProjectId(id: string, callback: (project: ProjectModel) => void): Unsubscribe {
    const docRef = this.getDocRef(id);
    return onSnapshot(docRef, (snapshot) => {
      const project = { ...snapshot.data(), id: snapshot.id } as DBModel.Project;
      callback(new ProjectModel(project));
    });
  }

  async findById(id: string): Promise<ProjectModel> {
    const docRef = this.getDocRef(id);
    const snapshot = await getDoc(docRef);
    const project = { ...snapshot.data(), id: snapshot.id } as DBModel.Project;
    return new ProjectModel(project);
  }

  async findAll(ownerId: string): Promise<ProjectModel[]> {
    const colRef = collection(this.firestore, collectionName);
    let q = query(colRef);
    q = query(q, where("ownerId", "==", ownerId));
    q = query(q, where("status", "!=", ProjectStatus.Deleted.value));
    q = query(q, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const projects: ProjectModel[] = [];
    querySnapshot.forEach((doc) => {
      const project = { ...doc.data(), id: doc.id } as DBModel.Project;
      projects.push(new ProjectModel(project));
    });
    return projects;
  }

  async findAllByAdmin(): Promise<ProjectModel[]> {
    const colRef = collection(this.firestore, collectionName);
    let q = query(colRef);
    q = query(q, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const projects: ProjectModel[] = [];
    querySnapshot.forEach((doc) => {
      const project = { ...doc.data(), id: doc.id } as DBModel.Project;
      projects.push(new ProjectModel(project));
    });
    return projects;
  }

  async update({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.Project>) {
    if (!id) {
      throw new Error("id is required");
    }
    const docRef = this.getDocRef(id);
    const updateProject: DBModel.Project = setUpdateInfo({ data, uid });
    await setDocument(docRef, updateProject, writeBatch);
  }
}

const projectRepository = new ProjectRepository(db);
export default projectRepository;
