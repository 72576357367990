import { Collapse } from "antd";
import { CollapseProps } from "antd/es/collapse";
import { ArrowRightOutlined } from "@ant-design/icons";
import ConstructionImages from "@/features/inspection/ConstructionImages";
import EstimateListModel from "common/models/EstimateListModel";
import { useConstructionImageUrls } from "@/features/inspection/hooks/useConstructionImageUrls";
import { useReworkImageUrlList } from "@/features/inspection/hooks/useReworkImageUrlList";
import "./ConstructionCollapse.scss";

type Props = {
  projectId: string;
  estimates: EstimateListModel;
};

const ConstructionCollapse = ({ projectId, estimates }: Props) => {
  const { reworkImageUrlList, isLoading: isLoadingReworkImageUrlList } = useReworkImageUrlList(projectId);

  const collapseItems: CollapseProps["items"] = estimates.value.map((estimate, index) => {
    const { estimateWithConstructionImageUrls, isLoading: isLoadingConstructionImageUrls } =
      useConstructionImageUrls(estimate);
    const estimateIdWithReworkImageUrls = reworkImageUrlList.find(
      (estimateIdWithReworkImageUrls) => estimateIdWithReworkImageUrls.estimateId === estimate.id
    );

    return {
      label: <div className="ConstructionCollapse__item__label">{estimateWithConstructionImageUrls.itemName}</div>,
      key: index,
      children: (
        <div className="ConstructionCollapse__item">
          <div className="ConstructionCollapse__item__before">
            <div className="ConstructionCollapse__item__before__title">工事前</div>
            <ConstructionImages
              imageUrls={estimateWithConstructionImageUrls.beforeImageUrls}
              isLoading={isLoadingConstructionImageUrls}
            />
          </div>
          <ArrowRightOutlined className="ConstructionCollapse__item__arrow" />
          <div className="ConstructionCollapse__item__after">
            <div className="ConstructionCollapse__item__after__title">工事後</div>
            <ConstructionImages
              imageUrls={estimateWithConstructionImageUrls.afterImageUrls}
              isLoading={isLoadingConstructionImageUrls}
            />
          </div>
          {/* 最新の再施工写真がある場合は表示 */}
          {estimateIdWithReworkImageUrls && (
            <>
              <ArrowRightOutlined className="ConstructionCollapse__item__arrow" />
              <div className="ConstructionCollapse__item__rework">
                <div className="ConstructionCollapse__item__rework__title">再施工後</div>
                <ConstructionImages
                  imageUrls={estimateIdWithReworkImageUrls.reworkImageUrls}
                  isLoading={isLoadingReworkImageUrlList}
                />
              </div>
            </>
          )}
        </div>
      ),
    };
  });

  return <Collapse defaultActiveKey={estimates.value.map((_, index) => index)} items={collapseItems} />;
};

export default ConstructionCollapse;
