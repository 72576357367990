import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import Button from "@/components/Button";
import { ProjectDetail } from "@/features/admin/project/ProjectDetail";

/**案件ページ（管理画面） */
export default function AdminProject(): JSX.Element {
  const { projectId } = useParams();
  const navigate = useNavigate();

  if (!projectId) {
    return <>loading...</>;
  }

  return (
    <div style={{ padding: "24px" }}>
      <Button type="link" onClick={() => navigate(`/admin/projects`)}>
        <LeftOutlined />
        案件一覧へ
      </Button>
      <ProjectDetail projectId={projectId} />
    </div>
  );
}
