import { useState, useEffect } from "react";
import EstimateListModel from "common/models/EstimateListModel";
import estimateRepository from "@/repositories/EstimateRepository";

type HookResult = {
  estimates: EstimateListModel | null;
};

const useEstimateList = (projectId: string): HookResult => {
  const [estimates, setEstimates] = useState<EstimateListModel | null>(null);

  useEffect(() => {
    const unsubscribe = estimateRepository.subscribeByProjectId(projectId, (estimates) => {
      setEstimates(new EstimateListModel(estimates));
    });

    return () => {
      unsubscribe();
    };
  }, [projectId]);

  return { estimates };
};
export default useEstimateList;
