import { useEffect, useState } from "react";
import { fetchWorkersByAdmin } from "../api/fetchWorkers";
import WorkerModel from "common/models/WorkerModel";

export function useWorkers() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [workers, setWorkers] = useState<WorkerModel[]>([]);
  const [filteredWorkers, setfilteredWorkers] = useState<WorkerModel[]>([]);

  // 検索条件
  const [inputNameOrCompanyName, setInputNameOrCompanyName] = useState<string>("");
  const [selectedJobType, setSelectedJobType] = useState<string>("");
  const [selctedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selctedIdentityVerifyStatuses, setSelectedIdentityVerifyStatuses] = useState<string[]>([]);
  const [selctedPrefecture, setSelectedPrefecture] = useState<string>("");

  useEffect(() => {
    const fetchWorkers = async (): Promise<void> => {
      try {
        const workers = await fetchWorkersByAdmin();
        setWorkers(workers);
        setfilteredWorkers(workers);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch workers", error);
      }
    };

    fetchWorkers();
  }, []);

  useEffect(() => {
    const filtered = workers.filter((worker) => {
      return (
        (worker.fullName.includes(inputNameOrCompanyName) || worker.companyName?.includes(inputNameOrCompanyName)) &&
        worker.jobTypes.toString().includes(selectedJobType) &&
        (selctedStatuses.length === 0 || selctedStatuses.includes(worker.status.value)) &&
        (selctedIdentityVerifyStatuses.length === 0 ||
          selctedIdentityVerifyStatuses.includes(worker.identityVerifyStatus.value)) &&
        (selctedPrefecture === "" || worker.address.value.prefecture === selctedPrefecture)
      );
    });

    setfilteredWorkers(filtered);
  }, [
    workers,
    inputNameOrCompanyName,
    selectedJobType,
    selctedStatuses,
    selctedIdentityVerifyStatuses,
    selctedPrefecture,
  ]);

  return {
    isLoading,
    filteredWorkers,
    setInputNameOrCompanyName,
    setSelectedJobType,
    setSelectedStatuses,
    setSelectedIdentityVerifyStatuses,
    setSelectedPrefecture,
  };
}
