import { useRef } from "react";
import useMessages from "@/hooks/useMessages";
import MessageModel from "common/models/MessageModel";
import { auth } from "@/lib/firebase";
import "./MessageArea.scss";

type Props = {
  renderMessage: (message: MessageModel) => React.ReactNode;
  projectId: string;
  chatRoomId: string;
};

const MessageArea = ({ renderMessage, projectId, chatRoomId }: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const uid = auth.currentUser?.uid || "";
  const { messages } = useMessages(projectId, chatRoomId, uid, scrollToBottom);

  function scrollToBottom() {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  }

  return (
    <div className="MessageArea">
      {messages.map((message) => {
        return renderMessage(message);
      })}
      <div ref={messagesEndRef} className="MessageArea__bottom" />
    </div>
  );
};

export default MessageArea;
