export default class WorkerStatus {
  private static _values = new Array<WorkerStatus>();

  public static readonly Pending = new WorkerStatus("pending", "登録承認待ち");
  public static readonly Active = new WorkerStatus("active", "承認済");
  public static readonly Left = new WorkerStatus("left", "退会"); // 退会は管理画面からは操作しない（職人が自ら行う）
  public static readonly Ban = new WorkerStatus("ban", "ログイン不可");
  public static readonly Nothing = new WorkerStatus("", "なし");

  private constructor(public readonly value: string, public readonly label: string) {
    if (value !== "") {
      WorkerStatus._values.push(this);
    }
  }

  static fromValue(value: string): WorkerStatus {
    return this.values.find((v) => v.value === value) || WorkerStatus.Nothing;
  }

  static get values(): WorkerStatus[] {
    return this._values;
  }
}
