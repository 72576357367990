import ProjectModel from "common/models/ProjectModel";
import { Modal } from "antd";
import useModal from "@/hooks/useModal";
import Button from "@/components/Button";
import WorkableDateRangeModal from "./WorkableDateRangeModal";
import WithdrawModal from "./WithdrawProjectModal";

type Props = {
  project: ProjectModel;
  modalHook: ReturnType<typeof useModal>;
};

/**期限切れモーダル */
const ExpiredModal = ({ project, modalHook }: Props) => {
  const workableDateRangeModalHook = useModal();
  const withdrawModalHook = useModal();

  return (
    <>
      <Modal
        width={600}
        centered
        open={modalHook.open}
        onCancel={modalHook.handleClose}
        title="案件の期限が切れました"
        footer={null}
      >
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <p>工事可能期間を過ぎたため、案件の公開は停止されています。</p>
          <p>募集を再開する場合は、工事可能期間を更新してください。</p>
          <div style={{ marginTop: "40px", display: "flex", justifyContent: "space-around" }}>
            <Button
              type="primary"
              onClick={() => {
                modalHook.handleClose();
                workableDateRangeModalHook.handleOpen();
              }}
            >
              工事可能期間を更新する
            </Button>
            <Button
              type="primary"
              danger
              ghost
              onClick={() => {
                modalHook.handleClose();
                withdrawModalHook.handleOpen();
              }}
            >
              取り下げる
            </Button>
          </div>
        </div>
      </Modal>

      <WorkableDateRangeModal project={project} modalHook={workableDateRangeModalHook} />
      <WithdrawModal project={project} modalHook={withdrawModalHook} />
    </>
  );
};

export default ExpiredModal;
