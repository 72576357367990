import { useEffect, useState } from "react";
import AdminProjectSettingModel from "common/models/AdminProjectSettingModel";
import adminprojectSettingRepository from "@/repositories/AdminProjectSettingRepository";

type HookResult = {
  adminProjectSetting: AdminProjectSettingModel | null;
  refetchAdminProjectSetting: () => void;
};

const useAdminProjectSetting = (projectId: string): HookResult => {
  const [adminProjectSetting, setAdminProjectSetting] = useState<AdminProjectSettingModel | null>(null);

  const fetchAdminProjectSetting = async (projectId: string) => {
    const adminProjectSetting = await adminprojectSettingRepository.findById(projectId);
    if (adminProjectSetting) {
      setAdminProjectSetting(adminProjectSetting);
    } else {
      setAdminProjectSetting(new AdminProjectSettingModel({ id: projectId }));
    }
  };

  const refetchAdminProjectSetting = async () => {
    await fetchAdminProjectSetting(projectId);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }
    fetchAdminProjectSetting(projectId);
  }, [projectId]);

  return { adminProjectSetting, refetchAdminProjectSetting };
};

export default useAdminProjectSetting;
