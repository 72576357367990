import { DBModel } from "../@types/global";

export default class KeyInfoModel {
  public readonly id: string;
  public readonly autoLockNo: string | null; // オートロック番号、解除方法
  public readonly keyLocation: string | null; // 鍵の場所
  public readonly keyLocationImageStoragePaths: string[] | null; // 鍵の場所の画像のstorageパス
  public readonly returnKeyLocation: string | null; // 返却した鍵の場所
  public readonly returnKeyLocationImageStoragePaths: string[] | null; // 返却した鍵の場所の画像のstorageパス

  constructor(data: Partial<DBModel.KeyInfo>) {
    const defaultValues = {
      id: "detail", // 固定
      autoLockNo: null,
      keyLocation: null,
      keyLocationImageStoragePaths: null,
      returnKeyLocation: null,
      returnKeyLocationImageStoragePaths: null,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = defaultValues.id;
    this.autoLockNo = mergedData.autoLockNo;
    this.keyLocation = mergedData.keyLocation;
    this.keyLocationImageStoragePaths = mergedData.keyLocationImageStoragePaths || null;
    this.returnKeyLocation = mergedData.returnKeyLocation;
    this.returnKeyLocationImageStoragePaths = mergedData.returnKeyLocationImageStoragePaths || null;
  }

  get isAllNull(): boolean {
    return (
      this.autoLockNo === null &&
      this.keyLocation === null &&
      this.keyLocationImageStoragePaths === null &&
      this.returnKeyLocation === null &&
      this.returnKeyLocationImageStoragePaths === null
    );
  }

  toJSON(): DBModel.KeyInfo {
    return {
      autoLockNo: this.autoLockNo,
      keyLocation: this.keyLocation,
      keyLocationImageStoragePaths:
        this.keyLocationImageStoragePaths && this.keyLocationImageStoragePaths.length > 0
          ? this.keyLocationImageStoragePaths
          : null,
      returnKeyLocation: this.returnKeyLocation,
      returnKeyLocationImageStoragePaths:
        this.returnKeyLocationImageStoragePaths && this.returnKeyLocationImageStoragePaths.length > 0
          ? this.returnKeyLocationImageStoragePaths
          : null,
    };
  }
}
