import estimateRepository from "@/repositories/EstimateRepository";
import { getDocs, WriteBatch } from "firebase/firestore";
import { auth } from "@/lib/firebase";
import EstimateListModel from "common/models/EstimateListModel";

type DeleteAndCreateEstimatesParams = {
  estimates: EstimateListModel;
  projectId: string;
  uid?: string;
  writeBatch?: WriteBatch;
};

/**コレクション内のドキュメントを全て削除し、新規でドキュメントを作成する */
export async function deleteAndCreateEstimates({
  estimates,
  projectId,
  uid = auth.currentUser?.uid,
  writeBatch,
}: DeleteAndCreateEstimatesParams) {
  const colRef = estimateRepository.getColRef(projectId);
  const snapshots = await getDocs(colRef);
  /**estimatesコレクション内のすべてのドキュメントId */
  const existingDocIds = snapshots.docs.map((doc) => doc.id);

  // 既にあるドキュメントを全て削除
  existingDocIds.forEach((id) => {
    estimateRepository.delete({ projectId, id, writeBatch });
  });

  for (const estimate of estimates.value) {
    // 元々保存されていたドキュメントだった場合、同じドキュメントIdで作成する
    if (estimate.id && existingDocIds.includes(estimate.id)) {
      await estimateRepository.create({ projectId, id: estimate.id, data: estimate.toJSON(), uid, writeBatch });
    } else {
      await estimateRepository.create({ projectId, data: estimate.toJSON(), uid, writeBatch });
    }
  }
}
