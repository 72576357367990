import SearchEstimateListModel from "common/models/SearchEstimateListModel";
import SearchEstimateModel from "common/models/SearchEstimateModel";
import { remopadClient } from "@/lib/axios";

/**項目名を使い、リモデラ事務から参照する見積を取得する */
export async function fetchSearchEstimates(itemName: string, prefecture: string): Promise<SearchEstimateListModel> {
  const { data } = await remopadClient.get("/onRequestGetSearchEstimates", {
    params: {
      prefecture,
      itemName,
    },
  });

  const searchEstimates = data.map((d: RemopadBQModel.SearchEstimate) => new SearchEstimateModel(d));
  return new SearchEstimateListModel(searchEstimates);
}
