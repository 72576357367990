import { DBModel } from "../@types/global";
import { formatCurrency } from "../utils/textUtil";
import Big from "big.js";
import { DeepPartial } from "./../../web/src/common/utils/firestoreUtil";

type ConstructionImageUrlsParams = {
  beforeImageUrls?: string[];
  afterImageUrls?: string[];
};

export default class EstimateModel {
  public readonly id: string;
  public readonly afterImageStoragePaths: string[] | null; // after写真
  public readonly beforeImageStoragePaths: string[] | null; // before写真
  public readonly itemName: string; // 項目名
  public readonly quantity: number; // 数量
  public readonly remarks: string; // 備考
  public readonly rowNumber: number; // 行番号
  public readonly unit: string; // 単位
  public readonly unitPrice: number | null; // 単価（税抜）

  public readonly afterImageUrls: string[] | null; // after写真URL
  public readonly beforeImageUrls: string[] | null; // before写真URL

  constructor(data: Partial<DBModel.Estimate> & DeepPartial<ConstructionImageUrlsParams>) {
    const defaultValues = {
      id: "",
      afterImageStorageDirPath: null,
      beforeImageStorageDirPath: null,
      itemName: "",
      quantity: 0,
      remarks: "",
      rowNumber: 1,
      unit: "",
      unitPrice: 0,

      afterImageUrls: null,
      beforeImageUrls: null,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.afterImageStoragePaths = mergedData.afterImageStoragePaths || null;
    this.beforeImageStoragePaths = mergedData.beforeImageStoragePaths || null;
    this.itemName = mergedData.itemName;
    this.quantity = mergedData.quantity;
    this.remarks = mergedData.remarks;
    this.rowNumber = mergedData.rowNumber;
    this.unit = mergedData.unit;
    this.unitPrice = mergedData.unitPrice;

    this.afterImageUrls = mergedData.afterImageUrls || null;
    this.beforeImageUrls = mergedData.beforeImageUrls || null;
  }

  get unitPriceFormatted(): string {
    return formatCurrency(this.unitPrice);
  }

  /**金額（税抜）※小数点切り捨て */
  get amount(): number | null {
    if (this.quantity === null || this.unitPrice === null) {
      return null;
    }
    return Math.floor(Number(Big(this.quantity || 0).times(this.unitPrice || 0)));
  }

  get amountFormatted(): string {
    return formatCurrency(this.amount);
  }

  withConstructionImageUrls(params: ConstructionImageUrlsParams): EstimateModel {
    return new EstimateModel({
      id: this.id,
      ...this.toJSON(),
      ...params,
    });
  }

  toJSON(): DBModel.Estimate {
    return {
      afterImageStoragePaths: this.afterImageStoragePaths || null,
      beforeImageStoragePaths: this.beforeImageStoragePaths || null,
      itemName: this.itemName,
      quantity: this.quantity,
      remarks: this.remarks,
      rowNumber: this.rowNumber,
      unit: this.unit,
      unitPrice: this.unitPrice,
    };
  }
}
