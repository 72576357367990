import { Card, Row, Col } from "antd";
import KeyInfoModel from "common/models/KeyInfoModel";

type Props = {
  keyInfo?: KeyInfoModel | null;
};
/**鍵情報 */
export function ProjectDetailKeyInfo({ keyInfo }: Props): JSX.Element {
  return (
    <>
      {keyInfo && (
        <Card title="鍵情報" size="small" type="inner" style={{ marginTop: 16 }}>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>オートロック解錠方法</Col>
            <Col span={14}>{keyInfo.autoLockNo}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>鍵の場所(施主設置)</Col>
            <Col span={14}>{keyInfo.keyLocation}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>鍵の場所(職人返却)</Col>
            <Col span={14}>{keyInfo.returnKeyLocation}</Col>
          </Row>
        </Card>
      )}
    </>
  );
}
