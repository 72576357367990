import workerRepository from "@/repositories/WorkerRepository";
import WorkerIdentityVerifyStatus from "common/models/worker/IdentityVerifyStatus";

/**本人確認を承認する */
export async function verify(workerId: string): Promise<void> {
  const worker = {
    identityVerifyStatus: WorkerIdentityVerifyStatus.Verified.value,
  };
  await workerRepository.update({ id: workerId, data: worker });
}
