import { Firestore, collection, getDoc, doc } from "firebase/firestore";
import ApplyModel from "common/models/ApplyModel";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";

const collectionName = "applies";

export class ApplyRepository {
  private firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string, id: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/${id}`);
  }

  async findById(projectId: string, id: string): Promise<ApplyModel> {
    const docRef = this.getDocRef(projectId, id);
    const snapshot = await getDoc(docRef);
    const apply = { ...snapshot.data(), id: snapshot.id } as DBModel.Apply;
    return new ApplyModel(apply);
  }
}

const applyRepository = new ApplyRepository(db);
export default applyRepository;
