import InvoiceList from "@/features/admin/invoice/InvoiceList";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

/**請求一覧ページ（管理画面） */
export default function AdminInvoices(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div style={{ padding: "24px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <h1>請求一覧</h1>
        <Button onClick={() => navigate("/admin/projects")}>案件一覧へ</Button>
        <Button onClick={() => navigate("/admin/workers")}>職人一覧へ</Button>
      </div>
      <InvoiceList />
    </div>
  );
}
