import { useState, useEffect } from "react";
import { Drawer } from "antd";
import ChatDetail from "./ChatDetail";
import ChatRoomList from "./ChatRoomList";
import ProjectModel from "common/models/ProjectModel";
import "./ChatDrawer.scss";

type Props = {
  project: ProjectModel;
  open: boolean;
  onClose: () => void;
  defaultOpenChatRoomId?: string | null; // デフォルトで開くチャットルームID
  openChatRoomId?: string; // 開くチャットルームID
};

const ChatDrawer: React.FC<Props> = ({ project, open, onClose, defaultOpenChatRoomId, openChatRoomId }) => {
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<string | null>(defaultOpenChatRoomId || null);
  const title = !selectedChatRoomId ? "チャット一覧" : "チャット詳細";

  useEffect(() => {
    if (!openChatRoomId) {
      return;
    }
    // チャットルームIDが指定されたら、チャット詳細を表示する
    setSelectedChatRoomId(openChatRoomId);
  }, [open]);

  const setChatRoomId = (chatRoomId: string | null) => {
    setSelectedChatRoomId(chatRoomId);
  };

  return (
    <Drawer
      placement="right"
      mask={false}
      onClose={onClose}
      open={open}
      width="35%"
      title={title}
      className="ChatDrawer"
    >
      {open && (
        <>
          {!selectedChatRoomId ? (
            <ChatRoomList projectId={project.id} setChatRoomId={setChatRoomId} />
          ) : (
            <ChatDetail project={project} chatRoomId={selectedChatRoomId} setChatRoomId={setChatRoomId} />
          )}
        </>
      )}
    </Drawer>
  );
};

export default ChatDrawer;
