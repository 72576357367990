// 認証ユーザーのコンテキスト
import React, { createContext, useContext, useEffect, useState } from "react";
import AuthUserModel, { getAuthUser } from "@/models/AuthUserModel";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "@/lib/firebase";
import { redirect } from "react-router-dom";

type AuthContextType = {
  authUser: AuthUserModel;
  loading: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

function AuthUserProvider(props: React.PropsWithChildren<{}>) {
  const [authUser, setAuthUser] = useState<AuthUserModel>(AuthUserModel.default());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        const authUser = await getAuthUser(user);
        setAuthUser(authUser);
        setLoading(false);
      } else {
        setAuthUser(AuthUserModel.default());
        setLoading(false);
        redirect("/signin");
      }
    });
    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={{ authUser, loading }}>{props.children}</AuthContext.Provider>;
}

// カスタムフック
function useAuthUserState() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthUserProvider, useAuthUserState };
