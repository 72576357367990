import { Card, Row, Col } from "antd";
import { AnalyticsWithWorkers } from "./hooks/useProjectDetail";
import { useState, useEffect } from "react";
import WorkerModel from "common/models/WorkerModel";
import Button from "@/components/Button";

type Props = {
  analyticsWithWorkers?: AnalyticsWithWorkers | null;
};
/**職人の案件閲覧情報 */
export function ProjectDetailAnalytics({ analyticsWithWorkers }: Props): JSX.Element {
  const defaultDisplayCount = 10; // 閲覧した職人の初期表示数
  const [displayWorkers, setDisplayWorkers] = useState<WorkerModel[]>([]);
  const [isDisplayAll, setIsDisplayAll] = useState<boolean>(false);

  useEffect(() => {
    if (!analyticsWithWorkers?.workers) {
      return;
    }
    setDisplayWorkers(analyticsWithWorkers.workers.slice(0, defaultDisplayCount));
  }, [analyticsWithWorkers?.workers]);

  const handleDisplay = () => {
    if (!analyticsWithWorkers?.workers) {
      return;
    }
    setIsDisplayAll(!isDisplayAll);
    setDisplayWorkers(
      isDisplayAll ? analyticsWithWorkers.workers.slice(0, defaultDisplayCount) : analyticsWithWorkers.workers
    );
  };

  return (
    <Card title="職人の案件閲覧情報" bordered={false}>
      {analyticsWithWorkers && (
        <>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>閲覧回数（未登録ユーザー含む）</Col>
            <Col span={14}>{analyticsWithWorkers.analytics.detailPageProUserViewCount} 回</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>閲覧人数（未登録ユーザー含む）</Col>
            <Col span={14}>{analyticsWithWorkers.analytics.detailPageViewProUserCount} 人</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>閲覧した職人</Col>
            <Col span={14}>
              {displayWorkers.map((worker) => (
                <div key={worker.id}>
                  {worker.displayName}（{worker.fullName}）
                </div>
              ))}
              {analyticsWithWorkers.workers.length > defaultDisplayCount && (
                <Button type="primary" ghost onClick={handleDisplay} size="small" style={{ marginTop: "8px" }}>
                  {isDisplayAll ? "折りたたむ" : "全て表示する"}
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
}
