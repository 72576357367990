import { useEffect, useState } from "react";
import AnalyticsModel from "common/models/AnalyticsModel";
import analyticsRepository from "@/repositories/AnalyticsRepository";

type HookResult = {
  analytics: AnalyticsModel | null;
};
const useAnalytics = (projectId: string): HookResult => {
  const [analytics, setAnalytics] = useState<AnalyticsModel | null>(null);

  useEffect(() => {
    const unsubscribe = analyticsRepository.subscribeByProjectId(projectId, (analytics) => {
      setAnalytics(analytics);
    });

    return () => {
      unsubscribe();
    };
  }, [projectId]);

  return { analytics };
};

export default useAnalytics;
