import { Firestore, collection, query, getDocs, orderBy } from "firebase/firestore";
import InvoiceDetailLineModel from "common/models/InvoiceDetailLineModel";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";

const collectionName = "invoiceDetailLines";

export class InvoiceDetailLineRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  private getColRef(invoiceId: string) {
    return collection(this.firestore, `admin/v1/invoices/${invoiceId}/${collectionName}`);
  }

  async findAll(invoiceId: string): Promise<InvoiceDetailLineModel[]> {
    const colRef = this.getColRef(invoiceId);
    let q = query(colRef);
    q = query(q, orderBy("salesDate", "asc"));
    const querySnapshot = await getDocs(q);
    const invoiceDetailLines: InvoiceDetailLineModel[] = [];
    querySnapshot.forEach((doc) => {
      const invoiceDetailLine = { ...doc.data(), id: doc.id } as DBModel.InvoiceDetailLine;
      invoiceDetailLines.push(new InvoiceDetailLineModel(invoiceDetailLine));
    });
    return invoiceDetailLines;
  }
}

const invoiceDetailLineRepository = new InvoiceDetailLineRepository(db);
export default invoiceDetailLineRepository;
