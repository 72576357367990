import ProjectModel from "common/models/ProjectModel";
import { updateWorkableDateRange } from "./api/updateWorkableDateRange";
import { message, Modal } from "antd";
import useModal from "@/hooks/useModal";
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import "dayjs/locale/ja";
import { recruit } from "./api/recruit";
import locale from "antd/es/date-picker/locale/ja_JP";
import { DatePicker } from "antd";

type Props = {
  project: ProjectModel;
  modalHook: ReturnType<typeof useModal>;
};

/**工事可能期間選択モーダル */
const WorkableDateRangeModal = ({ project, modalHook }: Props) => {
  const { RangePicker } = DatePicker;
  const [workableStartDate, setWorkableStartDate] = useState<Date | undefined>(project.workableStartDate?.value);
  const [workableEndDate, setWorkableEndDate] = useState<Date | undefined>(project.workableEndDate?.value);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);

  useEffect(() => {
    if (project.workableStartDate && project.workableEndDate) {
      setWorkableStartDate(project.workableStartDate.value);
      setWorkableEndDate(project.workableEndDate.value);
    }
  }, [project.workableStartDate, project.workableEndDate]);

  /**今日より前の日付である */
  const isBeforeToday = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf("day");
  };

  /**募集する */
  const handleRecruit = async () => {
    if (!workableStartDate || !workableEndDate) {
      message.error("工事可能期間を入力してください。");
      return;
    }

    try {
      await recruit(project.id, workableStartDate, workableEndDate);
      message.success("職人の募集を開始しました。");
      modalHook.handleClose();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  /**更新する */
  const handleUpdate = async () => {
    if (!workableStartDate || !workableEndDate) {
      message.error("工事可能期間を入力してください。");
      return;
    }

    try {
      await updateWorkableDateRange(project, workableStartDate, workableEndDate);
      message.success("工事可能期間を更新しました。");
      modalHook.handleClose();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Modal
        centered
        open={modalHook.open}
        onCancel={modalHook.handleClose}
        okText={project.canRecruit ? "募集開始" : "更新する"}
        onOk={project.canRecruit ? handleRecruit : handleUpdate}
        okButtonProps={{
          disabled: !workableStartDate || !workableEndDate || isBeforeToday(dayjs(workableEndDate)),
        }}
        title="工事可能期間"
      >
        <p style={{ textAlign: "center" }}>工事が可能な期間を入力してください。</p>
        <div style={{ display: "flex", justifyContent: "center", margin: "0 auto 36px" }}>
          <RangePicker
            placeholder={["開始日", "終了日"]}
            locale={locale}
            defaultValue={[
              workableStartDate ? dayjs(workableStartDate) : undefined,
              workableEndDate ? dayjs(workableEndDate) : undefined,
            ]}
            disabledDate={isSelectingEndDate ? isBeforeToday : undefined}
            onFocus={(_, info) => {
              if (info.range === "end") {
                setIsSelectingEndDate(true);
              } else {
                setIsSelectingEndDate(false);
              }
            }}
            onBlur={(_, info) => {
              if (info.range === "end") {
                setIsSelectingEndDate(false);
              }
            }}
            onChange={(values: RangeValueType<dayjs.Dayjs>) => {
              if (values) {
                setWorkableStartDate(values[0]?.startOf("day").toDate());
                setWorkableEndDate(values[1]?.startOf("day").toDate());
              } else {
                setWorkableStartDate(undefined);
                setWorkableEndDate(undefined);
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default WorkableDateRangeModal;
