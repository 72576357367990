import * as fileRepository from "@/repositories/fileRepository";

type Params = {
  projectId: string;
  chatRoomId: string;
  messageId: string;
  file: File;
  callback: (progress: number) => void;
};

/**ファイルをストレージにアップロード */
export const uploadFileToStorage = async ({ projectId, chatRoomId, messageId, file, callback }: Params) => {
  const path = `projects/${projectId}/chatRooms/${chatRoomId}/messages/${messageId}/images/${file.name}`;
  return await fileRepository.uploadFileResumable(path, file, callback);
};
