import SearchEstimateModel from "./SearchEstimateModel";

export default class SearchEstimateListModel {
  private _value: SearchEstimateModel[];

  constructor(data: SearchEstimateModel[]) {
    this._value = data;
  }

  get value(): SearchEstimateModel[] {
    return this._value;
  }

  get length(): number {
    return this.value.length;
  }
}
