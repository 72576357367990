import { useRef, useState } from "react";
import { Input, Form, Button, message, Progress } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd/es/form";
import { uploadFileToStorage } from "./api/uploadFileToStorage";
import messageRepository from "@/repositories/MessageRepository";
import * as messageService from "@/services/messageService";
import "./MessageInput.scss";

type Props = {
  projectId: string;
  chatRoomId: string;
};

const MessageInput = ({ projectId, chatRoomId }: Props) => {
  const formRef = useRef<FormInstance>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(100);

  /**メッセージ送信 */
  const sendMessage = async (body: string): Promise<void> => {
    await messageService.createMessage({ projectId, chatRoomId, body });
  };

  /**ファイル送信 */
  const sendFile = async (selectedFile: File): Promise<void> => {
    try {
      const messageId = await messageRepository.getUniqueId(projectId, chatRoomId);
      const uploadTask = await uploadFileToStorage({
        projectId,
        chatRoomId,
        messageId,
        file: selectedFile,
        callback: setUploadProgress,
      });
      const result = await uploadTask;
      const path = result.ref.fullPath;
      await messageService.createMessage({ projectId, chatRoomId, messageId, body: "", fileStoragePaths: [path] });
      setUploadProgress(100);
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return (
    <div className="MessageInput">
      <Form
        ref={formRef}
        onFinish={(values: { body: string }) => {
          if (file) {
            sendFile(file).then(() => setFile(undefined));
          } else {
            sendMessage(values.body).then(() => formRef.current?.resetFields());
          }
        }}
      >
        <div className={"MessageInput__picture"}>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/*, application/pdf"
            onChange={async (event: any) => {
              const selectedFile = event.target.files[0];
              setFile(selectedFile);
            }}
          />
          {uploadProgress !== 100 ? (
            <Progress type="circle" percent={uploadProgress} size={25} strokeColor="green" showInfo={false} />
          ) : (
            <PictureOutlined
              className="MessageInput__picture__icon"
              onClick={() => {
                if (!fileInputRef.current) {
                  return;
                }
                fileInputRef.current.click();
              }}
            />
          )}
        </div>
        {file ? (
          <div className="MessageInput__picture__selecting">画像選択中</div>
        ) : (
          <Form.Item name="body" className="MessageInput__input">
            <Input />
          </Form.Item>
        )}
        <Form.Item className="MessageInput__button">
          <Button type="primary" htmlType="submit">
            送信
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default MessageInput;
