import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";
import EstimateListModel from "common/models/EstimateListModel";
import { useEffect, useState } from "react";
import ProposedEstimateTable from "./ProposedEstimateTable";
import "./ProposedEstimates.scss";

type Props = {
  estimates: EstimateListModel;
  proposedEstimates: ProposedEstimateListModel;
};
/**提案された見積内容 */
const ProposedEstimates: React.FC<Props> = ({ estimates, proposedEstimates }) => {
  // 変更されている見積のID
  const [changedEstimateIds, setChangedEstimateIds] = useState<string[]>([]);

  useEffect(() => {
    const changedEstimateIds = proposedEstimates.changedEstimateIds(estimates);
    setChangedEstimateIds(changedEstimateIds);
  }, [proposedEstimates, estimates]);

  return (
    <>
      {!proposedEstimates.isEmpty && (
        <>
          <div className="ProposedEstimates">
            <div className="ProposedEstimates__titleContainer">
              <div className="ProposedEstimates__titleContainer__title">見積内容</div>
              {changedEstimateIds.length > 0 && (
                <div className="ProposedEstimates__titleContainer__changedText">※金額変更の提案があります</div>
              )}
            </div>
            <ProposedEstimateTable proposedEstimates={proposedEstimates} changedEstimateIds={changedEstimateIds} />
          </div>
          <div className="ProposedEstimates__totalAmount">
            <span className="ProposedEstimates__totalAmount__label">合計金額 (税別)</span>
            {proposedEstimates.totalAmountFormatted}
          </div>
        </>
      )}
    </>
  );
};

export default ProposedEstimates;
