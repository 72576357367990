import EstimateListModel from "common/models/EstimateListModel";
import projectRepository from "@/repositories/ProjectRepository";
import Status from "common/models/project/Status";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";
import { writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import * as estimateService from "@/services/estimateService";
import EstimateModel from "common/models/EstimateModel";
import ApplyModel from "common/models/ApplyModel";

/**応募者に仕事を発注します */
export async function order(projectId: string, apply: ApplyModel, proposedEstimates: ProposedEstimateListModel | null) {
  const batch = writeBatch(db);

  const updateProject = {
    orderDate: serverTimestamp() as Timestamp,
    status: Status.AwaitingConstruction.value,
    workerId: apply.workerId,
    workStartDate: apply.workStartDate.value,
    workEndDate: apply.workEndDate.value,
  };
  await projectRepository.update({ id: projectId, data: updateProject, writeBatch: batch });

  // 見積を提案されていれば、提案された内容の見積に更新する
  if (proposedEstimates && !proposedEstimates.isEmpty) {
    const updateEstimates = proposedEstimates.value.map(
      (proposedEstimate) => new EstimateModel({ id: proposedEstimate.id, ...proposedEstimate.toJSON() })
    );
    await estimateService.deleteAndCreateEstimates({
      estimates: new EstimateListModel(updateEstimates),
      projectId,
      writeBatch: batch,
    });
  }

  batch.commit();
}
