import { DBModel } from "../@types/global";

/**銀行口座 */
export default class BankAccountModel {
  public readonly id: string;
  public readonly bankAccountName: string; // 口座名義
  public readonly bankAccountNum: string; // 口座番号
  public readonly bankCode: string; // 金融機関コード
  public readonly bankName: string; // 金融機関名
  public readonly bankType: string; // 口座種別
  public readonly branchCode: string; // 支店コード
  public readonly branchName: string; // 支店名
  public readonly uid: string; // ユーザーのID

  constructor(data: Partial<DBModel.BankAccount>) {
    const defaultValues = {
      id: "",
      bankAccountName: "",
      bankAccountNum: "",
      bankCode: "",
      bankName: "",
      bankType: "",
      branchCode: "",
      branchName: "",
      uid: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.bankAccountName = mergedData.bankAccountName;
    this.bankAccountNum = mergedData.bankAccountNum;
    this.bankCode = mergedData.bankCode;
    this.bankName = mergedData.bankName;
    this.bankType = mergedData.bankType;
    this.branchCode = mergedData.branchCode;
    this.branchName = mergedData.branchName;
    this.uid = mergedData.uid;
  }

  toJSON(): DBModel.BankAccount {
    return {
      bankAccountName: this.bankAccountName,
      bankAccountNum: this.bankAccountNum,
      bankCode: this.bankCode,
      bankName: this.bankName,
      bankType: this.bankType,
      branchCode: this.branchCode,
      branchName: this.branchName,
      uid: this.uid,
    };
  }
}
