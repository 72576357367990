import { useState } from "react";
import { useMount } from "react-use";
import CommentModel from "common/models/CommentModel";
import commentRepository from "@/repositories/CommentRepository";
import * as commentService from "@/services/commentService";

type HookResult = {
  comments: CommentModel[];
  refresh: () => Promise<void>;
  softDelete: (comment: CommentModel) => Promise<void>;
};

const useProject = (projectId: string): HookResult => {
  const [comments, setComments] = useState<CommentModel[]>([]);

  useMount(async () => {
    refresh();
  });

  // 再取得
  const refresh = async () => {
    const comments = await commentService.fetchCommentsByProjectId(projectId);
    setComments(comments);
  };

  const softDelete = async (comment: CommentModel) => {
    await commentRepository.upsert({ id: comment.id, projectId, data: comment.toJSON() });
    refresh();
  };

  return {
    comments,
    refresh,
    softDelete,
  };
};

export default useProject;
