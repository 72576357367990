import { Firestore, collection, doc, getDoc } from "firebase/firestore";
import { DBModel } from "common/@types/global";
import { setDocument, CreateBaseParams, setCreateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";
import WithdrawalQuestionnaireModel from "common/models/WithdrawalQuestionnaireModel";

const collectionName = "withdrawalQuestionnaires";

type CreateParams = {
  projectId: string;
} & CreateBaseParams<DBModel.WithdrawalQuestionnaire>;

export class WithdrawalQuestionnaireRepository {
  private firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  private getColRef(projectId: string) {
    return collection(this.firestore, `projects/${projectId}/${collectionName}`);
  }

  private getDocRef(projectId: string) {
    const colPath = this.getColRef(projectId).path;
    return doc(this.firestore, `${colPath}/detail`);
  }

  async findByProjectId(projectId: string): Promise<WithdrawalQuestionnaireModel | null> {
    const docRef = this.getDocRef(projectId);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      return null;
    }
    const withdrawalQuestionnaire = { ...snapshot.data(), id: snapshot.id } as DBModel.WithdrawalQuestionnaire;
    return new WithdrawalQuestionnaireModel(withdrawalQuestionnaire);
  }

  async create({ projectId, data, uid = auth.currentUser?.uid, writeBatch }: CreateParams) {
    if (!projectId) {
      throw new Error("projectId is required");
    }
    const docRef = this.getDocRef(projectId);
    const newWithdrawalQuestionnaire: DBModel.WithdrawalQuestionnaire = setCreateInfo({ data, uid });
    await setDocument(docRef, newWithdrawalQuestionnaire, writeBatch);
  }
}

const withdrawalQuestionnaireRepository = new WithdrawalQuestionnaireRepository(db);
export default withdrawalQuestionnaireRepository;
