import { DBModel } from "../@types/global";

export default class AdminProjectSettingModel {
  public readonly id: string; // projectIdと同じ
  public readonly note: string; // 管理用メモ

  constructor(data: Partial<DBModel.AdminProjectSetting>) {
    const defaultValues = {
      id: "",
      note: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.note = mergedData.note;
  }

  /**管理用メモに記載があるかどうか */
  get hasWrittenNote(): boolean {
    return !!this.note;
  }

  toJSON(): DBModel.AdminProjectSetting {
    return {
      note: this.note,
    };
  }
}
