import { Firestore, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { DBModel } from "common/@types/global";
import { setDocument, UpdateBaseParams, setCreateInfo, setUpdateInfo } from "@/common/utils/firestoreUtil";
import { db, auth } from "@/lib/firebase";
import OwnerModel from "common/models/OwnerModel";

const collectionName = "owners";

export class OwnerRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  async upsert({ id, data, uid = auth.currentUser?.uid, writeBatch }: UpdateBaseParams<DBModel.Owner>) {
    if (!id) {
      throw new Error("id is required");
    }

    const docRef = doc(this.firestore, `${collectionName}/${id}`);
    // docが存在する場合は更新、存在しない場合は作成
    const snapshot = await getDoc(docRef);
    let upsertOwner: DBModel.Owner;
    if (snapshot.exists()) {
      upsertOwner = setUpdateInfo({ data, uid });
    } else {
      upsertOwner = setCreateInfo({ data, uid });
    }
    await setDocument(docRef, upsertOwner, writeBatch);
  }

  async findById(ownerId: string): Promise<OwnerModel> {
    const docRef = doc(this.firestore, `${collectionName}/${ownerId}`);
    const snapshot = await getDoc(docRef);

    const owner = { ...snapshot.data(), id: snapshot.id } as DBModel.Owner;
    return new OwnerModel(owner);
  }
}

const ownerRepository = new OwnerRepository(db);
export default ownerRepository;
