import React from "react";
import { Select as AntdSelect } from "antd";
import { SelectProps as AntdSelectProps } from "antd/es/select";
import FormItem from "./FormItem";

type SelectProps = {
  name: string;
  label?: string;
  rules?: any;
} & AntdSelectProps;

const Select: React.FC<SelectProps> = ({ name, label, defaultValue, rules, ...props }) => {
  return (
    <FormItem name={name} label={label} defaultValue={defaultValue} rules={rules} component={AntdSelect} {...props} />
  );
};

export default Select;
